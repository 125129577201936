import React, { useState, useEffect, useRef } from "react";
import "./contextmenu.css";
import "../../index.scss";

export function ContextMenu({ id, children, isDraft = false, closeContextMenu = false }) {
  const [open, setOpen] = useState(false);
  const menuRef = useRef(null);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if(closeContextMenu){
      setOpen(false)
    }
  },[closeContextMenu])

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  return (
    <div className="context-menu" ref={menuRef}>
      <div className="button" id={isDraft ? id : ""} onClick={() => setOpen((prev) => !prev)}>
        ...
      </div>
      {open && (
        <div className="menu">
          {(Array.isArray(children) ? children : [children])
            .filter(({ props }) => props.text)
            .map(({ props }) => (
              <div key={props.text} className="option" onClick={props.onClick}>
                <div style={{ width: "10%" }} className="col-flex justify_center align_center">
                  {props.icon}
                </div>
                <div style={{ width: "50%" }}>{props.text}</div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
}

export function ContextMenuOption({ onClick, icon, text }) {
  return { onClick, icon, text };
}
