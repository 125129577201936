import React, { useEffect,useState } from 'react'
import "./statistics.css";
import { Link, useNavigate } from 'react-router-dom'
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale, //x axis
  LinearScale, //y axis
  PointElement,
  Tooltip,
  Title,
  Filler,
  ArcElement,
  BarElement
} from 'chart.js';
import { useContext } from 'react'
import { StatsContext } from '../../providers/StatsProvider'
import "../../index.scss";
import "./website.css";
import FurtherInfo from '../widgets/tooltip';
import LineGraph from '../widgets/statistics_widgets/lineGraph';
import LineChart from '../widgets/statistics_widgets/lineChart';
import { UserContext } from '../../providers/UserProvider';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { firestore } from '../../firebase';
import phoneFrame from "../../graphics/phone_frame.png";
import LoaderDivWithOverlay, { hideLoader, showLoader } from '../widgets/loaderdiv';


export default function Website(){
  // eslint-disable-next-line no-unused-vars
  const [statisticsState,setStatisticState] = useState(2)
  // eslint-disable-next-line no-unused-vars
  const [activeStatisticsFirstRow,setActiveStatisticsFirstRow] = useState({})
  // eslint-disable-next-line no-unused-vars
  const [activeStatisticsSecondRow,setActiveStatisticsSecondRow] = useState({})
  // eslint-disable-next-line no-unused-vars
  const [activeStatisticsThirdRow,setActiveStatisticsThirdRow] = useState({})
  // eslint-disable-next-line no-unused-vars
  const [dataToday,setDataToday] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [dataWeek,setDataWeek] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [dataMonth,setDataMonth] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [dataYesterday,setDataYesterday] = useState([])

  useEffect(() => {
      if(statisticsState === 0){
        setTimeframe('today');
        setActiveStatisticsFirstRow(dataToday[0])
        setActiveStatisticsSecondRow(dataToday[1])
        setActiveStatisticsThirdRow(dataToday[2])
      }
      else if(statisticsState === 1){
        setTimeframe('yesterday')
        setActiveStatisticsFirstRow(dataYesterday[0])
        setActiveStatisticsSecondRow(dataYesterday[1])
        setActiveStatisticsThirdRow(dataYesterday[2])
      }
      else if(statisticsState === 2){
        setTimeframe('week');        
        setActiveStatisticsFirstRow(dataWeek[0])
        setActiveStatisticsSecondRow(dataWeek[1])
        setActiveStatisticsThirdRow(dataWeek[2])
      }
      else{
        setTimeframe('last_30_days');
        setActiveStatisticsFirstRow(dataMonth[0])
        setActiveStatisticsSecondRow(dataMonth[1])
        setActiveStatisticsThirdRow(dataMonth[2])
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[statisticsState])

  // const TestData = [
  //   1, 2, 3, 1, 2, 3, 4, 5, 5, 3, 2, 3, 2, 3, 24, 5, 2, 7, 3, 74, 2, 5, 2, 1, 2, 3, 1, 2, 3, 4, 5, 5, 3, 2, 3, 2, 3, 24, 5, 2, 7, 3, 74, 2, 5, 2, 
  // ]

  // const TestData2 = [
  //   1, 2,
  // ]

  // const TestData3 = [
  //   54, 35, 25, 18, 90, 29, 10,
  // ]

  // const TestData4 = [
  //   54, 35, 25,
  // ]

  // const TestData5 = [
  //   54, 35, 25, 28, 98,
  // ]
    
  const {
    getProfileViews,
    getEventViews,
    getEventShares,
    getEventBookmarks,
  } = useContext(StatsContext);

  //Use setTimeframe to set timeframe for all statistics
  const [timeframe, setTimeframe] = useState('today');

  const [profileViews, profileViewsSum, profileViewsLabels] = getProfileViews({ timeframe });
  const [eventViews, eventViewsSum, eventViewsLabels] = getEventViews({ timeframe });
  const [eventShares, eventSharesSum, eventSharesLabels] = getEventShares({ timeframe });
  const [eventBookmarks, eventBookmarksSum, eventBookmarksLabels] = getEventBookmarks({ timeframe });
    

  ChartJS.register(
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Tooltip,
    Title,
    ArcElement,
    BarElement,
    Filler)


  const navigate = useNavigate()

  const { organizerId } = useContext(UserContext);
  const [website, setWebsite] = useState(null);
  useEffect(() => { onSnapshot(query(
    collection(firestore, 'subsites'),
    where('organizer', '==', organizerId)
  ), e => e.docs.empty || setWebsite(e.docs[0])) }, [organizerId]);
  useEffect(() => {
    if(website !== null) showLoader()
  },[website])
  return(
    <div className="content">
      <div id="breadcrumb-title-text" onClick={() => navigate(-1) }>Webseite</div>
      <div className="PageHeaderBox">
        <div>
          <div className="PageHeaderBoxTitle">Webseite</div>
          <div className="PageHeaderBoxSubtitle">Hier kannst du checken wie deine Webseite aussieht und performed</div>
        </div>
        <div className='row-flex'>
            {website && <a href={`https://${website.id}.elgio.app`} target="_blank" rel="noopener noreferrer" style={{marginRight: "20px"}}>
                <div id="newEventButton" style={{backgroundColor: "var(--bg4)"}}>Zur Webseite</div>
            </a>}
            <Link to="website-bearbeiten">
                {website != null 
                  ? <div id="newEventButton">Bearbeiten</div>
                  : <div id="newEventButton">Aktivieren</div>
                }
            </Link>
        </div>
      </div>
      <div className='WebsiteMainbox'>
        <div className='websiteStats'>
          <div className="StatisticBoxWebsite">
            <div>
              <div className="StatisticHeader">Websiteaufrufe</div>
              <div className="StatisticNumber">{profileViewsSum}</div>
            </div>
            <LineGraph data={profileViews} labels={profileViewsLabels} colors={["#0d6efd", "#0d6efd", "#0d6efd"]}/>
          </div>
          <div className="StatisticBoxWebsite">
            <div>
              <div className="StatisticHeader">Eventklicks</div>
              <div className="StatisticNumber">{eventViewsSum}</div>
            </div>
            <LineGraph data={eventViews} labels={eventViewsLabels} colors={["#4ab022", "#4ab022", "#4ab022"]}/> 
          </div>
          <div className="StatisticBoxWebsite">
            <div>
              <div className="StatisticHeader">Unique Aufrufe</div>
              <div className="StatisticNumber">{eventSharesSum}</div>
            </div>
            <LineGraph data={eventShares} labels={eventSharesLabels} colors={["#8b44fd", "#8b44fd", "#8b44fd"]}/>
          </div>
          <div className="StatisticBoxWebsite">
            <div>
              <div className="StatisticHeader">Herkunft</div>
              <div className="StatisticNumber">{eventBookmarksSum}</div>
            </div>
            <LineChart data={eventBookmarks} labels={eventBookmarksLabels} colors={["#fdfd44"]}/>
          </div>
        </div>
        <div className="WebsiteVorschau">
          <div className='websiteVorschauBox'>
            <div className="profilheaders">Vorschau</div>
            <FurtherInfo 
              explanation="Hier siehst du eine interaktive mobile Vorschau deiner Website. Manchmal dauert es bis zu 2min bis Änderungen hier sichtbar sind."
            />
          </div>
          <div className="previewContainer">
              <img src={phoneFrame} alt="phone_frame_for_preview" className='phoneFramePreview'/>
              <div className="iframeContainer">
                <LoaderDivWithOverlay id={"website_iframe"}/>
                  {website !== null
                      ? <iframe onLoad={() => hideLoader("website_iframe")} title='Vorschau_Subsite' src={`https://${website?.id}.elgio.app?ignore_cache=true`} style={{ transformOrigin: '0% 0%', border: 'none', margin: 0, padding: 0, height: '100%', width: '100%' }} />
                      : <div className='placeholderVorschauWebsite'>Aktiviere deine Website um eine Vorschau zu sehen</div>
                  }
              </div>
          </div>
        </div>
      </div>
    </div>
  )
}