import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../../index.scss";
import "./discount-codes.css";
import { UserContext } from "../../../providers/UserProvider";
import { firestore } from "../../../firebase";
import {
  addDoc,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import FirebaseStorageImage from "../../functions/FirebaseStorageImage";
import OrganizerName from "../../widgets/OrganizerName";
import { nameIcon, valueIcon, usageIcon, listCheckIcon, chevronLeftIcon} from "../../../icons";
import  { change_color, rechange_color } from "../login_signup/account_erstellen_functions";


function DiscountCodes() {
  const navigate = useNavigate();
  const [couponName, setCouponName] = useState("");
  const [rabattbetragE, setRabattBetragE] = useState("");
  const [rabattbetragP, setRabattBetragP] = useState("");
  const [nutzungsLimit, setNutzungsLimit] = useState("");
  const [unbegrenzt, setUnbegrenzt] = useState(false);
  const [begrenzt, setBegrenzt] = useState(false);
  const [events,setEvents] = useState([])
  const [eventsChecked,setEventsChecked] = useState([])
  const {  organizerId } = useContext(UserContext);
  const [checkAllEvents,setCheckAllEvents] = useState(false)
  const [rememberForFuture,setRememberForFuture] = useState(false)
  useEffect(() => {
    getDocs(
      query(
        collection(firestore, "events"),
        where("organizer", "array-contains", organizerId),
        where("ticket","==","standard")
      )
    ).then((data) => {
      setEvents(data.docs);
      // hasDiscountCodes bei EventErstellung zu FireBase hinzufügen !!
      setEventsChecked(new Array(data.docs.length).fill(false))
    });
    setRememberForFuture(false)
  }, [organizerId]);

  function updateEventChecked(index){
    setEventsChecked(existingItems => {
      return [
        ...existingItems.slice(0, index),
        !existingItems[index],
        ...existingItems.slice(index + 1),
      ]
    })
  }

  function animateCheckbox(){
    if(rememberForFuture){
      document.getElementsByClassName('forFutureEvents')[0].className="forFutureEvents animate__animated animate__fadeOutUp"
      setTimeout(() => { setRememberForFuture(false)},900)
    }
    else if(!rememberForFuture){
      document.getElementsByClassName('forFutureEvents')[0].className="forFutureEvents animate__animated animate__fadeInDown"
      setRememberForFuture(true)
    }
  }

  const submit = async () => {
    //Checken, ob alle erforderlichen Felder ausgefüllt wurden
    if(!couponName || !eventsChecked || !organizerId || !(rabattbetragE || rabattbetragP))
      return;

    //Event IDs determinieren, die gechecked sind
    let checkedEventIds = [];
    if(checkAllEvents) {
      checkedEventIds = events.map(e => e.id);
    } else {
      for(const i in events) {
        if(eventsChecked[i]) checkedEventIds.push(events[i].id);
      }
    }
    if(checkedEventIds.length === 0) return;

    //Checken, ob dieser Veranstalter schon ein Discountcode mit diesem Namen erstellt hat
    const snapshot = await getDocs(
      query(
        collection(firestore, "coupons"),
        where("code", "==", couponName),
        where("organizer", "==", organizerId),
      ),
    );
    if(snapshot.docs.length > 0) {
      alert("Ein Coupon mit diesem Namen existiert bereits");
      return;
    }

    //Dokument erstellen
    await addDoc(collection(firestore, 'coupons'), rabattbetragE ? {
      apply_to_future_events: rememberForFuture??false,
      code: couponName,
      discount_absolute: parseInt(rabattbetragE) * 100, //In der Datenbank wird der Betrag in Cent als Integer gespeichert
      events: checkedEventIds,
      organizer: organizerId,
      ...(begrenzt ? {
        total_uses: parseInt(nutzungsLimit),
        available_uses: parseInt(nutzungsLimit),
      } : {}),
    } : {
      apply_to_future_events: rememberForFuture??false,
      code: couponName,
      discount_percent: parseInt(rabattbetragP),
      events: checkedEventIds,
      organizer: organizerId,
      ...(begrenzt ? {
        total_uses: parseInt(nutzungsLimit),
        available_uses: parseInt(nutzungsLimit),
      } : {}),
    });

  };

  useEffect(() => {
    let isValidName = couponName?.length > 3;
    let isValidValue = rabattbetragE || rabattbetragP;
    let isValidUsage = begrenzt || unbegrenzt;
    let isValidEvents = checkAllEvents || eventsChecked.some(e => e);
      
      if (isValidName) {
        change_color('nameCodeIcon', 'nameCodeIconNav');
      } else {
        rechange_color('nameCodeIcon', 'nameCodeIconNav');
      }
      
      if (isValidValue) {
        change_color('valueCodeIcon', 'valueCodeIconNav');
      } else {
        rechange_color('valueCodeIcon', 'valueCodeIconNav');
      }

      if (isValidUsage) {
        change_color('usageCodeIcon', 'usageCodeIconNav');
      } else {
        rechange_color('usageCodeIcon', 'usageCodeIconNav');
      }

      if (isValidEvents) {
        change_color('selectedEventsIcon', 'selectedEventsIconNav');
      } else {
        rechange_color('selectedEventsIcon', 'selectedEventsIconNav');
      }
    
  }, [couponName, rabattbetragE, rabattbetragP, begrenzt, unbegrenzt, checkAllEvents, eventsChecked]);

  
 return (
  <div className="content" style={{overflow: 'hidden'}}>
    <div className="flex-gap-10" id="breadcrumb-title-text">
      <div style={{cursor: 'pointer'}} onClick={() => navigate(-1)}>{chevronLeftIcon}</div>
      <div id="breadcrumb" style={{ position: "unset" }}>Dashboard / Coupon-Codes{" "}</div>
    </div>
    <div className="PageHeaderBox">
      <div>
        <div className="PageHeaderBoxTitle">Rabattcode Erstellung</div>
        <div className="PageHeaderBoxSubtitle">Nutze Rabattcodes, um loyale Kunden zu belohnen</div>
      </div>
      <div className="ButtonsBox">
        <div className="Button" style={{backgroundColor: "var(--bgBlue)", color: "var(--textMain)"}} onClick={() => {navigate(-1); submit()}}>
          Speichern
        </div>
        <div className="Button" style={{backgroundColor: "var(--bg4)", color: "var(--textMain)"}} onClick={() => navigate(-1)}>
          Abbruch
        </div>
      </div>
    </div>
    {/* Start of Side Menu */}
    <div
      className="mid-content"
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "3rem",
        justifyContent: "space-between",
        marginTop: "20px"
      }}
    >
          <div className="SideBarErstellen" style={{width: "250px"}}>
            <div className="erforderlichSidebar">Erforderliche Angaben</div>
            <div className="itemSideBar">
              <div className="circleSideBar" id="nameCodeIconNav">{nameIcon}</div>
              <div className="iconBeschreibung">Name des Codes</div>
            </div>
            <div className="itemSideBar">
              <div className="circleSideBar" id="valueCodeIconNav">{valueIcon}</div>
              <div className="iconBeschreibung">Wert des Codes</div>
            </div>
            <div className="itemSideBar">
              <div className="circleSideBar" id="usageCodeIconNav">{usageIcon}</div>
              <div className="iconBeschreibung">Nutzung</div>
            </div>
            <div className="itemSideBar">
              <div className="circleSideBar" id="selectedEventsIconNav">{listCheckIcon}</div>
              <div className="iconBeschreibung">Ausgewählte Events</div>
            </div>
          </div>
          {/* End of Side Menu */}

          {/* Start of Mid-Inputs */}

          <div className="inputs-mid-content">
            {/* Start of "Name des Codes" Input */}
            <div className="InputBox">
              <div className="InputBoxHeader">
                <div className="InputCircle" id="nameCodeIcon">{nameIcon}</div>
                <div className="InputBoxTitle">
                  <div className="InputName">Name des Codes</div>
                  <div className="InputDetails">Gib einen Namen ohne Leerzeichen an</div>
                </div>
              </div>
              <input
                value={couponName}
                onChange={(e) => setCouponName(e.target.value)}
                type="text"
                placeholder="z.B. Elgio10"
                className="TextInput coupons-code-name-inp"
              />
            </div>
            {/* End of Name des Codes Input */}

            {/* Start of "Wert des Codes" Input  */}
            <div className="InputBox">
              <div className="InputBoxHeader">
                <div className="InputCircle" id="valueCodeIcon">{valueIcon}</div>
                <div className="InputBoxTitle">
                  <div className="InputName">Wert des Codes</div>
                  <div className="InputDetails">Wähle einen fixen oder prozentualen Rabatt aus</div>
                </div>
              </div>
              <div className="inputs-flex">
                <div className="fakeInput">
                  <input
                    type="text"
                    placeholder="z.B. 5"
                    value={rabattbetragE}
                    onChange={(e) => setRabattBetragE(e.target.value)}
                    disabled={rabattbetragP.length !== 0}
                    style={rabattbetragP.length !== 0 ? {opacity:".6"}  : {}}
                    className="blackSheepInput"
                  />
                  <div style={{color: "var(--lightgrey"}}>€</div>
                </div>
                <div className="oder">oder</div>
                <div className="fakeInput">
                  <input
                    type="text"
                    placeholder="z.B. 20"
                    value={rabattbetragP}
                    onChange={(e) => setRabattBetragP(e.target.value)}
                    disabled={rabattbetragE.length !== 0}
                    style={rabattbetragE.length !== 0 ? {opacity:".6"}  : {} }
                    className="blackSheepInput"
                  />
                  <div style={{color: "var(--lightgrey"}}>%</div>
                </div>
              </div>
            </div>
            {/* End of "Wert des Codes" Input  */}

            {/* Start of "Nutzung" Input*/}
            <div className="InputBox">
              <div className="InputBoxHeader">
                <div className="InputCircle" id="usageCodeIcon">{usageIcon}</div>
                <div className="InputBoxTitle">
                  <div className="InputName">Nutzung</div>
                  <div className="InputDetails">Bestimme wie oft der Code verwendet werden darf</div>
                </div>
              </div>
              <div className="inputs-flex">
                <input type="radio" style={{display: 'none'}} id="begrenzt" name="nutzung" onChange={() => {
                  setBegrenzt(true);
                  setUnbegrenzt(false);
                }}/>
                <label for="begrenzt" className="usageButton">
                  Begrenzte Nutzung
                </label>

                <div className="oder">oder</div>

                <input type="radio" style={{display: 'none'}} id="unbegrenzt" name="nutzung" onChange={() => {
                  setBegrenzt(false);
                  setUnbegrenzt(true);
                }}/>
                <label for="unbegrenzt" className="usageButton">
                  Unbegrenzte Nutzung
                </label>
              </div>
              <div className="begrenztAdditional" style={begrenzt ? {}  : {display:"none"}}>
                <div className="input-subname"> Wie oft soll dieser Code nutzbar sein? </div>
                <div className="inputs-flex">
                  <input
                    style={{outline: "none"}}
                    type="text"
                    placeholder="z.B. 2000"
                    value={nutzungsLimit}
                    onChange={(e) => setNutzungsLimit(e.target.value)}
                    className="TextInput"
                    />
                </div>
              </div>
            </div>
          </div>
          {/* End of "Nutzung" Input*/}
          {/* End of Mid-Inputs */}

          {/* Start of Events Auswählen */}
          <div className="InputBox" style={{ width:"30%",height:"63vh"}}>
            <div className="InputBoxHeader">
              <div className="InputCircle" id="selectedEventsIcon">{listCheckIcon}</div>
              <div className="InputBoxTitle">
                <div className="InputName">Events Auswählen</div>
                <div className="InputDetails">Wähle alle Events aus, für die dieser Rabatt zählen soll.{" "}</div>
              </div> 
            </div>
            <div className="selectAllEvents" onClick={() => {setCheckAllEvents(!checkAllEvents);animateCheckbox()}} style={checkAllEvents ? {backgroundColor:"var(--blue)",color: "var(--textMain)"} : {backgroundcolor: "var(--textMain)"}}>
              {checkAllEvents ? "Alle Abwählen" : "Alle Auswählen" }
            </div>
            <div onClick={() => {document.getElementById("save4Future").click()}} className="forFutureEvents animate__animated animate__fadeInDown" style={rememberForFuture ? {display:"block"} : {display:"none"}}>
              <input onClick={(e) => {e.stopPropagation()}}type="checkbox"  name="" id="save4Future" />
              <label>
                Auch für alle zukünftigen Events speichern
              </label>
            </div>
            <div className="choose-event-body">
              {events.map((event,i) => {
                return(
                  <div onClick={() => {document.querySelectorAll(".eventDiv")[i].click()}} id="item" className="row-flex flex-gap-2rem   align_center">
                    <div className="itemLeft">
                      <input type="checkbox" className={"eventDiv pointer "} checked={checkAllEvents ? true : eventsChecked[i] ? true : false} onClick={() => {updateEventChecked(i)}}  />
                    </div>
                    <NextEventBox
                      onClick={() => updateEventChecked(i)}
                      id={event.id}
                      key={event.id}
                      date={event
                        .data()
                        ?.start?.toDate()
                        ?.toLocaleDateString("de-DE")}
                      titel={event.data().name}
                      club={event.data().venue}
                      shares={event.data().attendee_count ?? 0}
                      views={event.data().views ?? 0}
                      saved={event.data().bookmark_count ?? 0}
                      banner={
                        (event.data().images?.length ?? 0) > 0
                          ? event.data().images[0]
                          : null
                      }
                      pointer
                    ></NextEventBox>
                  </div>
                )
              })}
            </div>
        </div>
      </div>
    </div>
  );
}

function NextEventBox(props) {
  return (
    <div className={props.pointer ? "nextEventBoxEvents pointer" : "nextEventBoxEvents"}  style={{width:"85%"}}>
      <FirebaseStorageImage id="EventBanner" reference={props.banner} style={{width:"100%",height:"9vh"}}></FirebaseStorageImage>
      <div style={{display: "flex", flexDirection: "column", marginLeft: "15px", height: "fit-content", marginBottom: "10px"}}>
          <div id="eventDetailsText" style={{ width: "fit-content", height: "fit-content",fontSize:"var(--fontsize-medium", marginTop: "10px"}}>
            {props.titel ?? "Loading..."}
          </div>
          <div id="nextEventFooter" style={{display: "flex", alignItems: "center", height: "fit-content", marginLeft: "1px"}}>
            <div className="nextEventBoxDetailsLeft">
              <div id="nextEventDatum">
                {props.date ?? "Loading..."}
              </div>
              <div style={{display: "flex", flexDirection: "row", alignItems: "center", height: "fit-content", gap: ".5rem"}}>
                <div id="eventDetailsTextDetails">
                <OrganizerName id={props.club}/> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DiscountCodes;