import React from 'react'

export const showLoader = (id) => {
  id ? 
  document.getElementById(`loaderDiv_${id}`).style.visibility="visible"
  :
  document.getElementsByClassName("loaderDivNew").length > 1 ?
    console.error("Multiple loaders found in the DOM!\nPlease use the 'id' parameter if more than one loader is needed in one Component.")
  :
  document.getElementsByClassName("loaderDivNew")[0].style.visibility="visible"
}

export const hideLoader = (id) => {
  id ? 
  document.getElementById(`loaderDiv_${id}`).style.visibility="hidden"
  :
  document.getElementsByClassName("loaderDivNew")[0].style.visibility="hidden"
} 

export default function LoaderDivWithOverlay({id}) {
  return (
    <div id={`loaderDiv_${id}`} className="loaderDivNew col-flex justify_center align_center z-index-999" style={{width: "120px",height: "150px",backgroundColor: "var(--bg2)"}}>    
    <div
      style={{
        zIndex: "999",
        color: "white",
        marginTop:"20px",
        display:"flex",
        flexDirection:"row",
      }}
    >
        <span className="loaderEvent" id="loader"></span>
    </div>
        <div style={{marginLeft:"15px", marginRight:"15px", color: "var(--textMain)"}}>Loading...</div>
    </div>
    )
}

