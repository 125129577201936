import React, { useContext, useEffect, useRef, useState } from "react";
import logoWhite from "../../graphics/newIcon_white.png";
import logoDark from "../../graphics/newIcon_black.png";
import { Link, NavLink } from "react-router-dom";
import "../pages/home.css";
import "./sidenavs.css";
import "../../index.scss";
import "animate.css/animate.css";
import {
  dashboardIcon,
  myEventsIcon,
  ticketIcon,
  statistikenIcon,
  profilIcon,
  plusIcon,
  marketingIcon,
  networkIcon,
  websiteIcon,
} from "../../icons.jsx";
import { ThemeContext } from "../../providers/ThemeProvider";

export function SideNav(props) {
  const {theme} = useContext(ThemeContext);
  const sideNavRef = useRef(null)

  const handleClickOutside = (event) => {
    const showMenuIcon = document.querySelector("#showMenuIcon")
    if (sideNavRef.current && ! sideNavRef.current.contains(event.target) && showMenuIcon && !showMenuIcon.contains(event.target)) {
      document.getElementsByClassName("sideNav")[0].style.display="none";
    }
  };

  useEffect(() => {
    if(props.showSideNav){
      document.addEventListener("mousedown", handleClickOutside);
    }
    else{
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [props.showSideNav]);

  document.querySelectorAll(".Page").forEach((link) => {
    link.addEventListener("click", () => {
      document.getElementsByClassName("sideNav")[0].style.display = "none";
    })
  })

  return (
   <div className="sideNav" ref={sideNavRef} style={{display:"none"}}>
      <div>
        <Link to={"/dashboard"} id="logo">
          <img src={theme==="light" ? logoDark : logoWhite} style={{height: "35px"}} alt=""/>
        </Link>
        <div className="box">
          <NavLink to="/dashboard" end id="Dashboard" className={({ isActive }) => isActive ? "currentPage" : "Page"} >
            <div id="NavIcon">{dashboardIcon}</div>
              <div className="TextNavBar">Home</div>
          </NavLink>
          <NavLink to="/dashboard/events" className={({ isActive }) => isActive ? "currentPage" : "Page"} >
            <div id="NavIcon">{myEventsIcon}</div>
              <div className="TextNavBar">Meine Events</div>
          </NavLink>
          <NavLink to="/dashboard/ticketing" className={({ isActive }) => isActive ? "currentPage" : "Page el-hidden-small"}>
            <div id="NavIcon">{ticketIcon}</div>
              <div className="TextNavBar">Ticketing</div>
          </NavLink>
          <NavLink to="/dashboard/statistics" className={({ isActive }) => isActive ? "currentPage" : "Page"}>
            <div id="NavIcon">{statistikenIcon}</div>
              <div className="TextNavBar">Statistiken</div>
          </NavLink>                
          <NavLink to="/dashboard/marketing" className={({ isActive }) => isActive ? "currentPage" : "Page el-hidden-small"}>
            <div id="NavIcon">{marketingIcon}</div>
              <div className="TextNavBar">Marketing</div>
          </NavLink>
          <NavLink to="/dashboard/website" className={({ isActive }) => isActive ? "currentPage" : "Page el-hidden-small"}>
            <div id="NavIcon">{websiteIcon}</div>
              <div className="TextNavBar">Webseite</div>
          </NavLink>            
          {false && <NavLink to="/dashboard/network" className={({ isActive }) => isActive ? "currentPage" : "Page el-hidden-smallc"}>
            <div id="NavIcon">{networkIcon}</div>
              <div className="TextNavBar">Network</div>
          </NavLink>}                
          <NavLink to="/dashboard/profil" className={({ isActive }) => isActive ? "currentPage" : "Page"}>
            <div id="NavIcon">{profilIcon}</div>
              <div className="TextNavBar">Mein Profil</div>
          </NavLink>
        </div>
      </div>
      <Link to="/dashboard/events/event-erstellen">
        <div className="createEventButton">
          <div>{plusIcon}</div>
          <div id="buttonTextCreate">Neues Event</div>
        </div>
      </Link>
    </div>
  );
}