
export const handleCopyLink = (id,event,setCopied) => {
    if(event !== undefined) {
        event.stopPropagation();
        event.preventDefault();
    }
    
    window.navigator.clipboard.writeText(`https://t.elgio.de/${id}`)
    

    // This is for styling the copy button 
    // e.g. 
    //  normaler useState
    //  const [copied,setCopied] = useState(false)
    //  
    // 
    //  id des events aus der URL holen
    //  const {id} = useParams()
    // [...]
    // return(
    //       [...]
    //       <button style={copied ? {backgroundColor:"var(--green)"} : {}} 
    //               onClick={ (event) => {
    //                            handleCopyLink(event,id,setCopied())
    //                          }
    //               }>
    // )  
    // 
    if (setCopied) {
        setCopied(true)
        setTimeout(() => {
            setCopied(false)
        },2000)
    }
}
