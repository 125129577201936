import { toast, ToastOptions, ToastPosition } from "react-toastify";
import { toastConfig } from "../global_config/toastConfig.tsx";

// General toast functions with default toast config + ability to pass additional options
const createToast = (type: 'info' | 'success' | 'error' | 'warn') => (
  text: string,
  extraOptions?: Omit<ToastOptions, "position"> & { position?: ToastPosition }
): void => {
  toast[type](text, { ...toastConfig, ...extraOptions });
};

export const warningToast = createToast('warn');
export const infoToast = createToast('info');
export const successToast = createToast('success');
export const errorToast = createToast('error');

// Custom toast functions
const createCustomToast = (message: string) => (): void => {
  toast.info(message, toastConfig);
};

export const eventCanceledToast = createCustomToast("Das Event wurde abgesagt!");
export const eventSetPrivateToast = createCustomToast("Das Event wurde Privat geschaltet!");
export const eventSetLiveToast = createCustomToast("Das Event wurde Live geschaltet!");
export const eventDuplicatedToast = createCustomToast("Das Event wurde dupliziert!");
export const linkCopiedToast = (): void => {
  toast.success("Link wurde kopiert!", toastConfig);
};
