/* eslint-disable react-hooks/exhaustive-deps */
  import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../widgets/header";
import "./zwischenseite.css";
import { UserContext } from "../../../providers/UserProvider";
import "../../../index.scss";

function Zwischenseite() {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  useEffect(() => { user && navigate("/dashboard") }, [user])

  // if(document.URL === "")
  return (
    <div className="bg-img-1 bg-img-100">
      <Header></Header>
      <div className="col-flex flex-gap-2rem justify_center align_center">
        <div className="titleSignup">
          Eine Plattform, die weit über <br /> den Ticketverkauf hinausgeht
        </div>
        <div className="SubheaderSignup">
          ELGIO hilft dir, deine Events und dich so professionell wie noch nie zuvor zu präsentieren.<br />
          Passe dein Ticketing an deine Wünsche an und profitiere dann von unseren Statistiken & Auswertungen.
        </div>
        <div id="erstellenBtnZwischen" onClick={() => {document.getElementById("erstellenLink")?.click()}} >
          <Link to="/register" id="erstellenLink"></Link>
          <div>Profil erstellen</div>
        </div>
      </div>
      <div className="login-link-text-register">
        <div>Du hast schon einen Account? </div>
        <Link to={"/login"} className="linkBottomRegister">Logge dich hier ein</Link>
      </div>
    </div>
    );
}

export default Zwischenseite;
