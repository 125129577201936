import React from "react";
import { useNavigate } from "react-router-dom";
import { chevronLeftIcon } from "../../icons";
import "../../index.scss";
import logo from "../../graphics/newIcon_white.png";

export const elgio = 'https://elgio.de';

export default function Header(){
    const history = useNavigate()
    return(
        <div className="row-flex justify_space_between">
            <div className="back-btn-container" onClick={() => {history(-1)}}>
                <div>{chevronLeftIcon}</div>
                <div>Zurück</div>
            </div>
            <img alt="elgio_logo" onClick={() => {window.location.replace("https://elgio.de")}} src={logo} className="LogoSignup"></img>
        </div>
    );
}

