/* eslint-disable react-hooks/exhaustive-deps */
import Modal from "react-modal";
import { styleModal } from "../../global_config/modal_style";
import { useContext, useEffect, useState } from "react";
import "./subscribe_modal.css";
import { Elements } from "@stripe/react-stripe-js";
import { UserContext } from "../../../providers/UserProvider";
import { OrganizerContext } from "../../../providers/OrganizerProvider";
import { loadStripe } from "@stripe/stripe-js";
import { addDoc, collection, doc, getDoc, getDocs, limit, onSnapshot, query, where } from "firebase/firestore";
import { firestore, functions } from "../../../firebase";
import { httpsCallable } from "firebase/functions";
import PaymentSheet from "./payment_sheet";
import { cancelIcon, chevronLeftIcon,  plusIcon } from "../../../icons";
import { Link, useSearchParams } from "react-router-dom";

export default function SubscribeModal({ membership, show, setShow }) {
    const [searchParams, setSearchParams] = useSearchParams();

    const { user, organizerId } = useContext(UserContext);
    const { hasPro } = useContext(OrganizerContext);
    useEffect(() => { hasPro && currentPage !== 'done' && setShow(false) }, [hasPro]); //do not show this modal if organizer already has pro

    const [currentPage, setCurrentPage] = useState('selection');
    useEffect(() => {
        if(membership && show) {
            //initiate payment if membership already chosen
            initiatePayment();
        } else {
            //reset to first page when modal is closed or opened
            setCurrentPage('selection');
        }
    }, [show, membership]);

    const back = () => {
        switch(currentPage) {
            case 'selection':
            case 'done': setShow(false); break;
            default: setCurrentPage('selection');
        }
    };

    const [previouslySubscribed, setPreviouslySubscribed] = useState(true);
    useEffect(() => {
        getDocs(
            query(
                collection(firestore, 'membership_subscriptions'),
                where('organizer', '==', organizerId),
                where('status', 'in', ['active', 'expired', 'expired_unpaid']),
                limit(1),
            ),
        ).then(e => setPreviouslySubscribed(!e.empty));
    }, [organizerId]);

    //Payment stuff
    const stripePromise = loadStripe('pk_live_51J8PWkFdYz2NQ6zW2GeAoUEU5tuw92p2GpVkKaZxd766RgO8VGfghuTpixRMJ1zUTXpuDUdbTB2qSLx29zEKpX2y00mCnyqCAo');
    const [clientSecret, setClientSecret] = useState(null);
    const [, setPaymentId] = useState(null);
    const [price, setPrice] = useState(null);
    const initiatePayment = async () => {
        try {
            setCurrentPage('loading');

            setPaymentId(null);
            setClientSecret(null);
            setPrice(null);

            const m = membership??(previouslySubscribed ? 'dashboard_pro_monthly' : 'dashboard_pro_monthly_trial');
            const membershipDoc = await getDoc(doc(collection(firestore, 'memberships'), m));

            if(!membershipDoc.exists) return;

            setPrice(membershipDoc.data().price);

            setCurrentPage('payment');

            const ref = await addDoc(collection(firestore, 'membership_subscriptions'), {
                membership: m,
                status: 'requested',
                organizer: organizerId,
            });

            const status = await new Promise((resolve, _) => onSnapshot(ref, (snap) => {
                if(['confirmed', 'pending_payment', 'declined'].includes(snap.data().status))
                    resolve(snap.data().status);
                setTimeout(() => resolve('timeout'), 30000);
            }));

            if(status === 'pending_payment') {
                const createPayment = httpsCallable(functions, 'payments-createPayment');
                // eslint-disable-next-line no-unused-vars
                const { paymentDocId, paymentSecret, test: paymentTest, amount } = (await createPayment({
                    membershipSubscription: ref.id,
                    setupFutureUse: true,
                })).data;
                
                setPaymentId(paymentDocId);
                setClientSecret(paymentSecret);
                setPrice(amount);
            } else {
                setCurrentPage('error');
            }
        } catch(e) {
            console.error(e);
            setCurrentPage('error');
        }
    };

    return (
    
        <Modal
            isOpen={show}
            unselectable={true}
            autoFocus={false}
            className="Modal_Style subscribe-modal"
            shouldFocusAfterRender={false}
            style={styleModal}
            shouldCloseOnOverlayClick={true}
            onRequestClose={()=>{setShow(false)}}
        >
        <div className="subscribe-modal-iconbox">
            {currentPage === 'loading' ? <div /> : <div className="subscribe-modal-icons" onClick={back}>{chevronLeftIcon}</div>}
            <div className="subscribe-modal-icons" style={{fontSize: "var(--fontsize-humongous"}} onClick={() => {setShow(false)}}>{cancelIcon}</div>
        </div>
        <div className="subscribe-modal-content" style={currentPage === "done" ? {justifyContent: "center", marginBottom: "50px"} : {}}>
            <div className="subscribe-modal-titlebox">
                {currentPage === "done" ? <div className="subscribe-modal-title">
                    Glückwunsch, du hast jetzt das Premium Abo
                </div> : <div className="subscribe-modal-title">
                    Bring dein Auftreten aufs nächste Level
                </div>}
                {currentPage === "done" ? <div className="subscribe-modal-subtitle">
                    Probiere direkt mal deine ganzen neuen Vorteile aus, <br></br>wie zum Beispiel den neuen Marketing Bereich
                </div> : <div className="subscribe-modal-subtitle">
                    Upgrade zu Premium und profitiere von vielen Vorteilen
                </div>}
            </div>
            {currentPage === "done" ? "" : <div className="subscribe-modal-progress">
                <div className={`subscribe-modal-progress-item ${currentPage === 'selection' ? 'active' : ''} ${['loading', 'payment', 'done'].includes(currentPage) ? 'done' : ''}`}>
                    <div className="subscribe-modal-progress-number">
                        1.
                    </div>
                    <div className="subscribe-modal-progress-name">
                        Abo auswählen &nbsp; - &nbsp;&nbsp;
                    </div>
                </div>
                <div className={`subscribe-modal-progress-item ${currentPage === 'payment' ? 'active' : ''} ${['done'].includes(currentPage) ? 'done' : ''}`}>
                    <div className="subscribe-modal-progress-number">
                        2.
                    </div>
                    <div className="subscribe-modal-progress-name">
                        Zahlungsdaten angeben &nbsp; - &nbsp;&nbsp;
                    </div>
                </div>
                <div className={`subscribe-modal-progress-item ${currentPage === 'done' ? 'active' : ''} ${[].includes(currentPage) ? 'done' : ''}`}>
                    <div className="subscribe-modal-progress-number">
                        3.
                    </div>
                    <div className="subscribe-modal-progress-name">
                        Fertig!
                    </div>
                </div>
            </div>}
            {
                currentPage === 'selection' ? <div className="subscribe-modal-content-mini">
                    <div className="subscribe-modal-subscriptions">
                        <div className="subscribe-modal-subscriptions-item">
                            <div className="subscribe-modal-subscriptions-header">
                                <div className="subscribe-modal-subscriptions-title">Basic</div>
                                <div className="subscribe-modal-price">Kostenlos</div>
                            </div>
                            <div className="subscribe-modal-subscriptions-benefitbox">
                                <div className="subscribe-modal-subscriptions-benefit">
                                    <div>{plusIcon}</div>
                                    <div>Eventmanagement</div>
                                </div>
                                <div className="subscribe-modal-subscriptions-benefit">
                                    <div>{plusIcon}</div>
                                    <div>Statistiken</div>
                                </div>
                                <div className="subscribe-modal-subscriptions-benefit">
                                    <div>{plusIcon}</div>
                                    <div>Ticketing</div>
                                </div>
                                <div className="subscribe-modal-subscriptions-benefit">
                                    <div>{plusIcon}</div>
                                    <div>
                                        <div>0,40 + 5% Ticketkommission</div>
                                        <div className="subscribe-modal-price">bei Ticketpreisen ab 15,00€</div>
                                    </div>
                                </div>
                            </div>
                            <div className="subscribe-modal-subscriptions-placeholder">Derzeitiges Abo</div>
                        </div>
                        <div className="subscribe-modal-subscriptions-item">
                            <div className="subscribe-modal-subscriptions-header">
                                <div className="subscribe-modal-subscriptions-title">Premium</div>
                                <div className="subscribe-modal-priceBox">
                                    <div className="subscribe-modal-price" style={{color: "var(--textSecondary)", textDecoration: previouslySubscribed ? null : "line-through"}}>19,90€</div>
                                    {!previouslySubscribed && <div className="subscribe-modal-price" style={{color: "var(--green)", fontWeight: "600"}}>0€ (1.Monat)</div>}
                                </div>
                            </div>
                            <div className="subscribe-modal-subscriptions-benefitbox">
                                <div className="subscribe-modal-subscriptions-benefit">
                                    <div>{plusIcon}</div>
                                    <div>Alle Vorteile von <span style={{fontWeight: "600"}}>Basic</span></div>
                                </div>
                                <div className="subscribe-modal-subscriptions-benefit">
                                    <div>{plusIcon}</div>
                                    <div>Marketing</div>
                                </div>
                                <div className="subscribe-modal-subscriptions-benefit">
                                    <div>{plusIcon}</div>
                                    <div>1x eigenes Post-Template</div>
                                </div>
                                <div className="subscribe-modal-subscriptions-benefit">
                                    <div>{plusIcon}</div>
                                    <div>
                                        <div>0,40 + 4% Ticketkommission</div>
                                        <div className="subscribe-modal-price">bei Ticketpreisen ab 15,00€</div>
                                    </div>
                                </div>
                            </div>
                            <div className="subscribe-modal-subscriptions-button" onClick={initiatePayment}>Upgraden</div>
                        </div>
                    </div>
                    <div className="subscribe-modal-subscriptions-allbenefits" onClick={() => {
                        setShow(false);
                        searchParams.set('show_modal', 'settings');
                        searchParams.set('settings_page', 'subscriptions');
                        setSearchParams(searchParams);
                    }}>Alle Vorteile ansehen</div>
                </div> : currentPage === 'payment' ? <>
                    <Elements stripe={stripePromise} options={{
                        mode: previouslySubscribed ? 'payment' : 'setup',
                        amount: price,
                        setupFutureUsage: 'off_session',
                        paymentMethodTypes: ['card', 'link'],
                        currency: 'eur',
                        locale: 'de',
                        appearance: { theme: 'night', variables: { fontSizeBase: '12px' }},
                    }}>
                        <PaymentSheet
                            clientSecret={clientSecret}
                            billingDetails={{ email: user.email }}
                            price={price}
                            priceMonthly={1990}
                            onSuccess={() => setCurrentPage('done')}
                            submitButtonText="Abonnement starten"
                        />
                    </Elements>
                </> : currentPage === 'done' ? <>
                    <Link to="/dashboard/marketing" className="subscribe-modal-success-button" onClick={() => setShow(false)}>Zum Marketing</Link>
                </> : currentPage === 'loading' ? <div className="loadingPlaceholder">
                    Bitte warten...{price}
                </div> : <div className="loadingPlaceholder">
                    Es ist etwas schief gelaufen
                </div>
            }
        </div>
    </Modal>
    )
}