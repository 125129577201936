/* eslint-disable no-extend-native */
export const weekdays = [
    'Sonntag',
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag',
];

Date.prototype.getWeekOfMonth = function() {
    return (0 | (this.getDate() - 1) / 7)+1;
  }