import { addDoc, collection, doc, getDoc, getDocs, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { useContext, useEffect, useMemo, useState } from "react";
import Modal from "react-modal";
import { auth, firestore, functions } from "../../../firebase";
import { chevronLeftIcon } from "../../../icons";
import "./purchase-marketing-bundle.css";
import { Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { httpsCallable } from "firebase/functions";
import { UserContext } from "../../../providers/UserProvider";
import { signInAnonymously } from "firebase/auth";
import { useSearchParams } from "react-router-dom";
import FirebaseStorageImage from "../../functions/FirebaseStorageImage";

export function PurchaseMarketingBundleModal({ eventId, bundleId, setBundleId, showModal, setShowModal }) {
    const el = Modal.setAppElement("#portal");
    return <Modal
        isOpen={showModal}
        appElement={el}
        unselectable="true"
        autoFocus={false}
        className="modalContentNormal pmb-modal"
        overlayClassName="modalOverlayNormal"
        shouldFocusAfterRender={false}
        shouldCloseOnOverlayClick={true}
        onRequestClose={()=>{setShowModal(false)}}
    >
        <PurchaseMarketingBundle eventId={eventId} bundleId={bundleId} setBundleId={setBundleId} cancel={() => setShowModal(false)} />
    </Modal>;
}

export default function PurchaseMarketingBundle({ eventId, bundleId, setBundleId, cancel }) {
    const [searchParams, setSearchParams] = useSearchParams();

    //page state
    const [currentPage, setCurrentPage] = useState('overview');
    const progress = useMemo(() => {
        switch(currentPage) {
            case 'overview': return 1;
            case 'customize': return 2;
            case 'billing': return 2;
            case 'payment': return 3;
            case 'error': return 3;
            default: return 0;
        };
    }, [currentPage]);
    const maxProgress = 3;
    useEffect(() => {
        if(searchParams.get('open_payment')) {
            setCurrentPage('payment');
            searchParams.delete('open_payment');
            setSearchParams(searchParams);
        }
    }, [searchParams, setSearchParams]);
    const back = () => {
        switch(currentPage) {
            case 'overview': return cancel();
            case 'customize': return setCurrentPage('overview');
            // case 'billing': return setCurrentPage('customize');
            case 'billing': return setCurrentPage('overview');
            case 'payment': return setCurrentPage('billing');
            case 'error': return setCurrentPage('overview');
            default: return;
        };
    };

    //user
    const { organizerId, loaded } = useContext(UserContext);
    const [user, setUser] = useState(null);
    useEffect(() => {
        if(loaded && organizerId) return;
        signInAnonymously(auth).then(e => setUser(e.user)).catch((e) => setCurrentPage('error'));
    }, [loaded, organizerId]);
    
    //bundle
    const [bundle, setBundle] = useState(null);
    useEffect(() => {
        if(!bundleId) return;
        getDoc(doc(firestore, 'marketing_bundles', bundleId)).then((snapshot) => {
            if (snapshot.exists()) {
                setBundle(snapshot);
            } else {
                console.error('Bundle not found');
            }
        });
    }, [bundleId]);

    //event
    const [event, setEvent] = useState(null);
    useEffect(() => {
        if(!eventId) return;
        getDoc(doc(firestore, 'events', eventId)).then((snapshot) => {
            if (snapshot.exists()) {
                setEvent(snapshot);
            } else {
                console.error('Event not found');
            }
        });
    }, [eventId]);

    //billing data
    const [billingData, setBillingData] = useState(null);

    //payment
    const stripePromise = loadStripe('pk_live_51J8PWkFdYz2NQ6zW2GeAoUEU5tuw92p2GpVkKaZxd766RgO8VGfghuTpixRMJ1zUTXpuDUdbTB2qSLx29zEKpX2y00mCnyqCAo');
    const [clientSecret, setClientSecret] = useState(null);
    const [paymentId, setPaymentId] = useState(null);
    const [price, setPrice] = useState(null);
    useEffect(() => {
        if(currentPage !== 'payment') return;
        if(!bundle || !bundleId || !eventId) return;
        if(!user?.uid && !organizerId) return;
        if(!billingData) return;
        const initiatePayment = async () => {
            try {
                setPaymentId(null);
                setClientSecret(null);
                setPrice(bundle.data().price);
    
                const ref = await addDoc(collection(firestore, 'marketing_bundle_bookings'), {
                    marketing_bundle: bundleId,
                    status: 'requested',
                    event: eventId,
                    ...(organizerId ? {organizer: organizerId} : {user: user.uid}),
                });
    
                const status = await new Promise((resolve, _) => onSnapshot(ref, (snap) => {
                    if(['confirmed', 'pending_payment', 'declined'].includes(snap.data().status))
                        resolve(snap.data().status);
                    setTimeout(() => resolve('timeout'), 30000);
                }));
    
                if(status === 'pending_payment') {
                    const createPayment = httpsCallable(functions, 'payments-createPayment');
                    const { paymentDocId, paymentSecret, amount } = (await createPayment({
                        marketingBundleBooking: ref.id,
                        email: billingData.email,
                    })).data;
                    
                    setPaymentId(paymentDocId);
                    setClientSecret(paymentSecret);
                    setPrice(amount);
                } else {
                    setCurrentPage('error');
                }
            } catch(e) {
                console.error(e);
                setCurrentPage('error');
            }
        };
        if(searchParams.get('payment') && searchParams.get('payment_intent_client_secret')) {
            setPaymentId(searchParams.get('payment'));
            setClientSecret(searchParams.get('payment_intent_client_secret'));
            setPrice(parseInt(searchParams.get('price')));
            return;
        }
        initiatePayment();
    }, [currentPage, bundle, bundleId, eventId, setPaymentId, user, organizerId, billingData, searchParams]);

    return <div className="pmb-container">
        <div className="pmb-nav">
            <button className="backBox" onClick={back}>{chevronLeftIcon}</button>
            <div className="pmb-progress-bar-wrapper">
                <div className="pmb-progress-bar" style={{maxWidth: `${progress / maxProgress * 100}%`}}></div>
                <div className="pmb-progress-bar-text">Progress: {progress}/{maxProgress}</div>
            </div>
        </div>
        {(() => {
            if(!bundle || !event) return <div>Bitte warten...</div>;

            switch(currentPage) {
                case 'overview': return <Overview bundle={bundle} event={event} setCurrentPage={setCurrentPage} setBundleId={setBundleId} />;
                case 'customize': return <Customize bundle={bundle} setCurrentPage={setCurrentPage} />;
                case 'billing': return <Billing setCurrentPage={setCurrentPage} billingData={billingData} setBillingData={setBillingData} />;
                case 'payment': return <Elements stripe={stripePromise} options={{
                    mode: 'payment',
                    amount: bundle.data().price,
                    currency: 'eur',
                    locale: 'de',
                    appearance: { theme: 'night', variables: { fontSizeBase: '12px' }},
                    paymentMethodTypes: ['card', 'klarna', 'giropay', 'link', 'ideal', 'eps', 'bancontact', 'paypal'],
                }}>
                    <Payment bundle={bundle} setCurrentPage={setCurrentPage} billingDetails={billingData} price={price} clientSecret={clientSecret} paymentId={paymentId} />
                </Elements>;
                case 'error': return <div>Es ist ein Fehler aufgetreten, bitte probiere es später erneut.</div>;
                default: return <div>Page not found</div>;
            };
        })()}
    </div>
}

function Overview({ bundle, event, setCurrentPage, setBundleId }) {
    const [alternativePremiumBundle, setAlternativePremiumBundle] = useState(null);
    useEffect(() => {
        if(bundle.data().premium) {
            setAlternativePremiumBundle(null);
            return;
        }
        getDocs(query(collection(firestore, 'marketing_bundles'), where('premium', '==', true), orderBy('order'))).then((snapshot) => {
            if(snapshot.empty) {
                setAlternativePremiumBundle(null);
                return;
            }
            setAlternativePremiumBundle(snapshot.docs[0]);
        });
    }, [bundle]);

    return <div className="pmb-overview">
        <div className="pmb-header">Deine Auswahl</div>
        <div className="pmb-selection">
            <div className="pmb-selectionItem">Bundle: <span style={{fontWeight: "600"}}>{bundle.data().name}</span></div>
            <div className="pmb-selectionItem">Event: <span style={{fontWeight: "600"}}>{event.data().name}</span></div>
        </div>
        <FirebaseStorageImage className="pmb-bundleImage" reference={bundle.data().image} />
        <div className="pmb-headAdvantages">Zusammenfassung & Vorteile:</div>
        <div className="pmb-advantage">{bundle.data().details?.map((line, index) => <div key={index}> · {line}</div>)}</div>
        <button className="pmb-button" onClick={() => setCurrentPage('customize')}>Für {(bundle.data().price / 100).toFixed(2).replace('.', ',')}€ buchen</button>
        {alternativePremiumBundle && <div className="pmb-alternativeBox">
            <div className="pmb-alternative-header">
                <div>
                    <div>Alternativer Vorschlag</div>
                    <div className="pmb-alternativeBundleName">{alternativePremiumBundle.data().name}</div>
                </div>
                <div className="pmb-alternativePrice">+ {((alternativePremiumBundle.data().price - bundle.data().price) / 100).toFixed(2).replace('.', ',')}€ </div>
            </div>
            <div className="pmb-headAdvantages" >{alternativePremiumBundle.data().short_description}</div>
            <button className="pmb-buttonAlternative" onClick={() => setBundleId(alternativePremiumBundle.id)}>Mehr Infos</button>
        </div>}
    </div>;
}

function Customize({ bundle, setCurrentPage }) {
    useEffect(() => {setCurrentPage && setCurrentPage('billing')}, [setCurrentPage]); //temp

    return <div className="pmb-customize">
        Bitte warten...
    </div>;
}

function Billing({ setCurrentPage, billingData, setBillingData }) {
    const [email, setEmail] = useState(billingData?.email || null);
    const [name, setName] = useState(billingData?.name || null);
    const [company, setCompany] = useState(billingData?.company || null);
    const [line1, setLine1] = useState(billingData?.address?.line1 || null);
    const [postalCode, setPostalCode] = useState(billingData?.address?.postal_code || null);
    const [city, setCity] = useState(billingData?.address?.city || null);

    useEffect(() => {
        setBillingData({
            email,
            name,
            company,
            address: {
                line1,
                postal_code: postalCode,
                city,
            },
        });
    }, [email, name, company, line1, postalCode, city, setBillingData]);

    return <div className="pmb-billing">
        <div style={{fontSize: "var(--fontsize-huge", fontWeight: "600"}}>Rechnungsangaben</div>
        <div className="colBox" style={{gap: "15px"}}>
            <div className="pmb-col">
                <div className="titel-text-details">Vor- und Nachname</div>
                <input
                    type="text"
                    className="pmb-input"
                    id="name"
                    style={{ position: "unset" }}
                    placeholder="z.B. Max Mustermann"
                    value={name}
                    onInput={(e) => {
                        setName(e.target.value);
                    }}
                />
            </div>
            <div className="pmb-col">
                <div className="titel-text-details">Name der Firma (optional)</div>
                <input
                    type="text"
                    className="pmb-input"
                    id="company"
                    placeholder="z.B. XYZ GmbH"
                    style={{ position: "unset" }}
                    value={company}
                    onInput={(e) =>{
                        setCompany(e.target.value)
                    }
                    }
                />
            </div>
            <div className="pmb-col">
                <div className="titel-text-details">E-Mail-Adresse</div>
                <input
                    type="text"
                    className="pmb-input"
                    id="mail"
                    placeholder="z.B. max.mustermann@xyz.de"
                    value={email}
                    onInput={(e) => {
                        setEmail(e.target.value)
                    }}
                />
            </div>
            <div className="pmb-col">
                <div className="titel-text-details">Straße & Hausnummer</div>
                <input
                    type="text"
                    className="pmb-input"
                    id="address-line1"
                    placeholder="z.B. Musterstraße 1"
                    value={line1}
                    onInput={(e) => {
                        setLine1(e.target.value)
                    }}
                />
            </div>
            <div className="col1">
                <div className="pmb-col" style={{width: "calc(50% - 5px)"}}>
                    <div className="titel-text-details">Postleitzahl</div>
                    <input
                        type="text"
                        className="pmb-input"
                        id="address-postal-code"
                        style={{ position: "unset" }}
                        placeholder="z.B. 52313"
                        value={postalCode}
                        onInput={(e) => {
                            setPostalCode(e.target.value)
                        }}
                    />
                </div>
                <div className="pmb-col" style={{width: "calc(50% - 5px)"}}>
                    <div className="titel-text-details">Stadt</div>
                    <input
                        type="text"
                        className="pmb-input"
                        id="address-city"
                        style={{ position: "unset" }}
                        placeholder="z.B. Musterstadt"
                        value={city}
                        onInput={(e) => {
                            setCity(e.target.value)
                        }}
                    />
                </div>
            </div>
        </div>
        <button className="pmb-button" onClick={() => setCurrentPage('payment')} disabled={!email || !name || !line1 || !postalCode || !city}>Weiter</button>
    </div>;
}

function Payment({ bundle, setCurrentPage, billingDetails, price, clientSecret, paymentId }) {
    const stripe = useStripe();
    const elements = useElements();

    const [loading, setLoading] = useState(true);
    useEffect(() => { setLoading(!clientSecret) }, [clientSecret]);

    const completePayment = async (event) => {
        event.preventDefault();

        if(!stripe || !elements) return;
        
        setLoading(true);

        const { error: submitError } = await elements.submit();

        if(submitError) {
            alert('Es gab einen Fehler mit deiner Zahlung. Bitte versuche es erneut.');
            setLoading(false);
            return;
        }

        const returnUrl = new URL(window.location.href);
        returnUrl.searchParams.set('payment', paymentId);
        returnUrl.searchParams.set('price', price);
        returnUrl.searchParams.set('open_payment', 'true');

        const { error: confirmError } = await (stripe.confirmPayment)({
            elements,
            clientSecret: clientSecret,
            
            confirmParams: {
                return_url: returnUrl.href,
                payment_method_data: {
                    billing_details: billingDetails,
                },
            },
        });

        if(confirmError) {
            alert('Es gab einen Fehler mit deiner Zahlung. Bitte versuche es erneut.');
            setLoading(false);
            return;
        }
    };

    return <div className="pmb-payment">
        <form onSubmit={completePayment}>
            <PaymentElement options={{
                defaultValues: { billingDetails },
                // fields: { billingDetails: { email: 'never' } },
                terms: { card: 'never' },
                // layout: 'accordion',
            }} />
            <button className="pmb-payment-button" disabled={loading}>{loading ? 'Bitte warten...' : `Für ${(price / 100).toFixed(2).replace('.', ',')}€ kaufen`}</button>
            <div className="pmb-payment-conditions">
                Durch das Klicken auf "Für {(price / 100).toFixed(2).replace('.', ',')}€ kaufen" stimmst du den&nbsp;
                <a href={"https://www.elgio.de/terms-of-service-dashboard"} target="_blank" rel="noopener noreferrer" id="AgbLink" disabled>AGBs</a>&nbsp;
                von ELGIO zu.
            </div>
        </form>
    </div>;
}

