export const recurrenceTextMap = {
    "weekly__monday": "Jeden Montag",
    "weekly__tuesday": "Jeden Dienstag",
    "weekly__wednesday": "Jeden Mittwoch",
    "weekly__thursday": "Jeden Donnerstag",
    "weekly__friday": "Jeden Freitag",
    "1stOfMonth__monday": "Jeden 1. Montag im Monat",
    "2ndOfMonth__monday": "Jeden 2. Montag im Monat",
    "3rdOfMonth__monday": "Jeden 3. Montag im Monat",
    "4thOfMonth__monday": "Jeden 4. Montag im Monat",
    "1stOfMonth__tuesday": "Jeden 1. Dienstag im Monat",
    "2ndOfMonth__tuesday": "Jeden 2. Dienstag im Monat",
    "3rdOfMonth__tuesday": "Jeden 3. Dienstag im Monat",
    "4thOfMonth__tuesday": "Jeden 4. Dienstag im Monat",
    "1stOfMonth__wednesday": "Jeden 1. Mittwoch im Monat",
    "2ndOfMonth__wednesday": "Jeden 2. Mittwoch im Monat",
    "3rdOfMonth__wednesday": "Jeden 3. Mittwoch im Monat",
    "4thOfMonth__wednesday": "Jeden 4. Mittwoch im Monat",
    "1stOfMonth__thursday": "Jeden 1. Donnerstag im Monat",
    "2ndOfMonth__thursday": "Jeden 2. Donnerstag im Monat",   
    "3rdOfMonth__thursday": "Jeden 3. Donnerstag im Monat",
    "4thOfMonth__thursday": "Jeden 4. Donnerstag im Monat",
    "1stOfMonth__friday": "Jeden 1. Freitag im Monat",
    "2ndOfMonth__friday": "Jeden 2. Freitag im Monat",
    "3rdOfMonth__friday": "Jeden 3. Freitag im Monat",
    "4thOfMonth__friday": "Jeden 4. Freitag im Monat",
    "1stOfMonth__saturday": "Jeden 1. Samstag im Monat",
    "2ndOfMonth__saturday": "Jeden 2. Samstag im Monat",
    "3rdOfMonth__saturday": "Jeden 3. Samstag im Monat",
    "4thOfMonth__saturday": "Jeden 4. Samstag im Monat",
    "1stOfMonth__sunday": "Jeden 1. Sonntag im Monat",
    "2ndOfMonth__sunday": "Jeden 2. Sonntag im Monat",
    "3rdOfMonth__sunday": "Jeden 3. Sonntag im Monat",
    "4thOfMonth__sunday": "Jeden 4. Sonntag im Monat",
}