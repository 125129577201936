/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState } from "react";
import "./fast_fertig.css";
import "../../../graphics/newIcon_white.png";
import PasswordChecklist from "react-password-checklist";
import { Link, useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth, firestore } from "../../../firebase";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import { useEffect } from "react";
import { Loader } from "../../../loader";
import Header from "../../widgets/header";
import { UserContext } from "../../../providers/UserProvider";
import "../../../index.scss";
import { closedEyeIcon, eyeIcon } from "../../../icons";

var emailDone = false;
var passwordDone = false;
function FastFertig() {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  useEffect(() => { user && navigate("/dashboard") }, [user])

  
  const [isChanged, setType] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [passwordValid,setPasswordValid] = useState(false);
  const [allInpDone, setAllInpDone] = useState(false)
  const [errorMessage,setErrorMessage] = useState("Initial");
  const register = async () => {
    if(allInpDone){
      try{
        createUserWithEmailAndPassword(
          auth,
          email,
          password
        ).then((credentials) => {
        showLoading()
        setTimeout(async () => {
          const organizerRef = await addDoc(collection(firestore,'organizers'),{
            user: credentials.user.uid
          });
          // eslint-disable-next-line no-unused-vars
          const userRef = await setDoc(doc(collection(firestore,'users'),credentials.user.uid),{
            email: email,
            organizer : organizerRef.id,
            role: "organizer"
          });
          window.location.href = ("/register/create-profile");
        }, 200);
      })
      .catch((error) => {
        document.getElementById("error-message").style.visibility="visible"
        switch(error.code){
          case "auth/email-already-in-use":
            setErrorMessage("Diese Email wird bereits benutzt!")
            break;
            case "auth/network-request-failed":
            setErrorMessage("Du bist offline");
            break;
            case "auth/invalid-email":
            setErrorMessage("Bitte gib eine gültige Email Adresse ein");
            setEmail("");
            break;
          default: 
            setErrorMessage(error.code)
          }
        });
      }
      catch (error) {
        console.log(error.code);
      }
    }
    else{
      document.getElementById("error-message").style.visibility="visible"
      setErrorMessage("Fülle zuerst alle Felder aus!")
    }
  };


  function showLoading(){
    document.getElementById("registerContent").style.visibility="hidden"
    document.getElementsByClassName("loaderDiv")[0].style.visibility="visible"
  }

  return (
    <>
      <div className="bg-img-1 bg-img-100"  id="registerContent">
        <Header></Header>
        <div className="col-flex flex-gap-2rem justify_center align_center">
          <div className="flex col-flex">
            <div className="titleSignup">Kontoerstellung</div>
            <div className="SubheaderSignup">
              Gib bitte eine Email und ein Passwort an,
              <br />
              mit dem du dich als Veranstalter in Zukunft anmelden willst.
            </div>
          </div>
          <div className="InputsBox">
            <div className="InputsFields">
            <input
              autoComplete="off"
              className="Input inputSignup"
              type="email"
              id="emailFast"
              name="Email"
              placeholder="Email"
              value={email}
              onKeyDown={(e) => {if(e.keyCode  === 13 && emailDone && passwordDone){register()}}}
              onChange={(e) => {
                if(document.getElementById("error-message").style.visibility === "visible"){
                  document.getElementById("error-message").style.visibility="hidden"
                }
                setEmail(e.target.value);validateEmail(e.target.value);}}
              />
            <div className="passwInput Input" id="password-Input" style={{position:"relative"}}>
              <input
                autoComplete="new-password"
                type={isChanged ? "text" : "password"}
                id="passwordFast"
                className="w-100 inputSignup"
                name="Password"
                placeholder="Passwort"
                value={password}
                onKeyDown={(e) => {if(e.keyCode  === 13 && emailDone && passwordDone){register()}}}
                onChange={(e) => {
                  if(document.getElementById("error-message").style.visibility === "visible"){
                    document.getElementById("error-message").style.visibility="hidden"
                  }  
                  setPassword(e.target.value);check(e.target.value)
                }}
              ></input>
              <div
                onClick={() => setType(!isChanged)}
                id={"passwIconRegis"}
                >{!isChanged ? eyeIcon : closedEyeIcon}</div>
            </div>
            <div className="error-message" id="error-message" >{errorMessage}</div>
            <div className={allInpDone ? "erstellenBtnFastFertig" : "erstellenBtnFastFertigError"} onClick={() => {register()} }>
              <div id="BtnText">Profil erstellen</div>
            </div>
            <div id="passw-checklist-register-content" style={{backgroundImage:"none"}}>
              <PasswordChecklist
              style={password.length !== 0 ? {visibility:"visible"} : {visibility:"hidden"} }
              className="passw-checklist-register"
              minLength={8}
              rules={[
                "minLength",
                "specialChar",
                "number",
                "capital",
              ]}
              value={password}
              messages={{
                minLength: "Passwort hat mindestens 8 Zeichen.",
                specialChar: "Passwort hat mindestens ein Symbol.",
                number: "Passwort hat mindestens eine Zahl.",
                capital: "Passwort hat mindestens einen Großbuchstaben.",
              }}
              onChange={(valid) => {
                setPasswordValid(valid)
              }}
              iconSize={17}
              ></PasswordChecklist>
            </div>
          </div>
        </div>
      </div>
      <div className="login-link-text-register">
        <div>Du hast schon einen Account? </div>
        <Link to={"/login"} className="linkBottomRegister">Logge dich hier ein</Link>
      </div>
    </div>
    <Loader></Loader>
  </>
  );

  function check(val){
    //var regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
    var regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/
    if(!regex.test(val)){
      passwordDone = false;
      setAllInpDone(false)
    }
    else{
      if(emailDone){
        setAllInpDone(true);
        passwordDone = true
      }
      else{
        setAllInpDone(false)
        passwordDone = true;
      }
    }
  }

  function validateEmail(email) {
    var regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!regex.test(email)) {
      emailDone = false;
      setAllInpDone(false)
      document.getElementById("emailFast").style.borderColor="var(--red)"
    } else {
      if(passwordDone){
        emailDone = true;
        setAllInpDone(true)

      }
      else{
        setAllInpDone(false)
        emailDone = true;
      }
      document.getElementById("emailFast").style.borderColor="var(--lightgrey)"
    }
  }
}

export default FastFertig;
