import React from "react";
import "./benefits.css"
import 'animate.css/animate.css'
import Modal from "react-modal";
import "../../../index.scss";
import { 
    cancelIcon,
    commissionIcon,
    paymentsIcon,
    valueIcon,
    webappIcon,
    scannerIcon,
    trackingIcon,
    onePaymentIcon,
    customerServiceIcon,
    flexibilityIcon,
    friendslistIcon,
} from "../../../icons.jsx";

export default function Benefits({ show, handleClose }) {
    const el = Modal.setAppElement("#portal");
    

    return (
    
        <Modal
            isOpen={show}
            appElement={el}
            unselectable="true"
            autoFocus={false}
            className="modalContentNormal"
            overlayClassName="modalOverlayNormal"
            shouldFocusAfterRender={false}
            shouldCloseOnOverlayClick={true}
            onRequestClose={handleClose}
            style={{
            content: {
                width: "75vw",
                height: "75vh",
                backgroundSize: "cover",
                display: "flex",
                flexDirection: "column",
                padding: "35px 45px",
                justifyContent: "space-between",
            }}}
        >

{/* content */}
        <div className="HeaderRowBenefits">
            <div className="HeaderText">
                <div className="benefitHeader">Deine Vorteile</div>
                <div className="benefitSubheading">Nicht nur sind wir der billigste Anbieter am Markt, aber bieten zudem noch viele weitere Vorteile</div>
            </div>
            <div className="exitCross" style={{cursor:"pointer"}} onClick={handleClose}>{cancelIcon}</div>
        </div>
        <div className="mainContentBox">
            <div className="bigSix">
            <div className="contentRow">
                <div className="benefitElement">
                    <div className="benefitSquare" style={{color: "var(--green)"}}>{commissionIcon}</div>
                    <div className="benefitText">
                        <div className="benefitTitle">0,99€ Kommission</div>
                        <div className="benefitDescription">Bis zu einem Ticketpreis von 15€ zahlst du fix 0,99€.</div>
                    </div>
                </div>
                <div className="benefitElement">
                    <div className="benefitSquare" style={{color: "var(--yellow)"}}>{paymentsIcon}</div>
                    <div className="benefitText">
                        <div className="benefitTitle">Alle Bezahlmethoden</div>
                        <div className="benefitDescription">Deine Kunden können mit Paypal, Klarna, Kreditkarte, per Handy etc. bezahlen.</div>
                    </div>
                </div>
                <div className="benefitElement">
                    <div className="benefitSquare" style={{color: "var(--blue)"}}>{valueIcon}</div>
                    <div className="benefitText">
                        <div className="benefitTitle">Rabattcodes</div>
                        <div className="benefitDescription">Erstelle und verschicke ganz leicht Rabatte an deine Kunden und Freunde.</div>
                    </div>
                </div>
            </div>
        <div className="contentRow">
            <div className="benefitElement">
                <div className="benefitSquare" style={{color: "var(--purple)"}}>{webappIcon}</div>
                <div className="benefitText">
                    <div className="benefitTitle">Webapp</div>
                    <div className="benefitDescription">Jeder Kunde kann deine Tickets auch ganz einfach über den Browser kaufen.</div>
                </div>
            </div>
            <div className="benefitElement">
                <div className="benefitSquare" style={{color: "var(--red)"}}>{friendslistIcon}</div>
                <div className="benefitText">
                    <div className="benefitTitle">Gäste & Freundeliste</div>
                    <div className="benefitDescription">Verwalte deine Gästeliste komplett online. Ganz leicht für dich und Türsteher.</div>
                </div>
            </div>
            <div className="benefitElement">
                <div className="benefitSquare" style={{color: "var(--orange)"}}>{scannerIcon}</div>
                <div className="benefitText">
                    <div className="benefitTitle">Scan-App</div>
                    <div className="benefitDescription">Unsere kostenlose App für die Einlassreglung am Abend.</div>
                </div>
            </div>
            </div>
        </div>
        <div className="contentRow">
            <div className="benefitElementSmall">
                <div className="benefitSquareSmall">{customerServiceIcon}</div>
                <div className="benefitText">
                    <div className="benefitTitleSmall">24/7 Kundenservice</div>
                    <div className="benefitDescriptionSmall">Du kannst uns immer bzgl. Problemen, Wünschen oder Fragen erreichen.</div>
                </div>
            </div>
            <div className="benefitElementSmall">
                <div className="benefitSquareSmall">{flexibilityIcon}</div>
                <div className="benefitText">
                    <div className="benefitTitleSmall">Flexibilität</div>
                    <div className="benefitDescriptionSmall">Du schließt mit uns keinen langwiedrigen binden Vertrag ab.</div>
                </div>
            </div>
            <div className="benefitElementSmall">
                <div className="benefitSquareSmall">{trackingIcon}</div>
                <div className="benefitText">
                    <div className="benefitTitleSmall">Tracking Pixel</div>
                    <div className="benefitDescriptionSmall">Gib deine TikTok- und Meta-Pixel an um zu verfolgen, woher deine Kunden kommen.</div>
                </div>
            </div>
            <div className="benefitElementSmall">
                <div className="benefitSquareSmall">{onePaymentIcon}</div>
                <div className="benefitText">
                    <div className="benefitTitleSmall">Keine Extrakosten</div>
                    <div className="benefitDescriptionSmall">Die Kommission umfasst alles. Es kommen keine weiteren Kosten auf dich zu.</div>
                </div>
            </div>
        </div>
        </div>
        <div className="BottomRow" style={{gap:".2rem"}}>
            <div style={{color:"var(--textSecondary)"}}>
                Du hast weitere Fragen? Ruf uns unter 
            </div>
                015119488634
            <div style={{color:"var(--textSecondary)"}}>
                an oder schreib uns eine E-mail an
            </div>
            <a style={{color:"var(--textMain)"}} href="mailto:niclas.reif@elgio.de"> {" niclas.reif@elgio.de"}</a>
            <div style={{color:"var(--textSecondary)"}}>
               .
            </div>
            
        </div>
    </Modal>
    )
}