import React, { useState } from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { showLoader, hideLoader } from "../../widgets/loaderdiv";
import Header from "../../widgets/header";
import LoaderDivWithOverlay from "../../widgets/loaderdiv";

function ResetPassw(){
    const [email,setEmail] = useState("");
    const auth = getAuth();
    const [errorMessage,setErrorMessage] = useState("Initial")
  
    const handleRequest = () =>  {
        showLoader();
        if(email.length > 0){
          sendPasswordResetEmail(auth, email)
            .then(() => {
              alert("Passwort Reset Email wurde verschickt!");
            })
            .catch((error) => {
              document.getElementById("error-message").style.visibility = "visible";
              switch (error.code) {
                case "auth/network-request-failed":
                  setErrorMessage("Du bist offline");
                  break;
                case "auth/invalid-email":
                  setErrorMessage("Bitte gib eine richtige Email Adresse ein");
                  break;
                default:
                  setErrorMessage(error.message || "An unexpected error occurred");
              }
            });
        } else {
          setErrorMessage("Du musst eine Email angeben!");
          document.getElementById("error-message").style.visibility = "visible";
        }
        hideLoader("login_loader");
      }
  
    return(
      <div className="bg-img-2 bg-img-100">
        <Header></Header>      
          <div className="col-flex flex-gap-2rem position-center justify_center align_center">
            <div className="flex col-flex">
              <div className="titleSignup">Setze dein Passwort zurück</div>
              <div className="SubheaderSignup">Dir wird per Email ein Link zur Zurücksetzung geschickt</div>
            </div>
            <div className="mid-inputs">
              <input type="text" className="inputSignup" onChange={(e) => {
                document.getElementById("error-message").visibility = "hidden"
                setEmail(e.target.value)}} placeholder="Email" name="" id="emailFast"
              />
              <div className="error-message" id="error-message">{errorMessage}</div>
              <button id="loginBtn" onClick={handleRequest}>Passwort zurücksetzen</button>
            </div>
          </div>
        <LoaderDivWithOverlay id={"login_loader"}/> 
      </div>
    )
}

export default ResetPassw;
