import React, { useContext, useEffect, useState } from "react";
import "./home.css";
import { Link, useNavigate } from "react-router-dom";
import { Inputs } from "./login_signup/account_erstellen_inputs";
import { addDoc, collection, deleteDoc, doc, getDocs,  setDoc, updateDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import { UserContext } from "../../providers/UserProvider";
import "../../index.scss";
import "./profil.css";
import "./profil.jsx";
import { chevronLeftIcon } from "../../icons";
import SideBar from "./login_signup/account_erstellen_sidebar";



function MeinProfilBearbeiten() {
    const navigate = useNavigate();
    const {user,organizerId} = useContext(UserContext);
    const [data, setData] = useState();
    const [accountTyp, setAccountTyp] = useState();
    const [areas, setAreas] = useState();
    const submit = async () => {
      const documentRef = doc(
        firestore,
        "organizers",
        organizerId
      );

      //Areas
      const areasRef = collection(documentRef, 'areas');
      await getDocs(areasRef).then((querySnapshot) => Promise.all(querySnapshot.docs.map(doc => deleteDoc(doc.ref))));
      if(areas?.length){
        await Promise.all(areas.map(async (e, i) => await setDoc(doc(documentRef, 'areas', i.toString()), {
          ...e,
          agent: user.uid,
        }, { merge: true })));
      }

      //Residents
      await Promise.all(data.residents?.map(async e => e.startsWith('$') && await addDoc(collection(firestore, 'artists'), {
        name: e.substring(1),
        agent: user.uid,
        visibility: 'public',
      }).then(x => data.residents[data.residents.indexOf(e)] = x.id))??[]);

      updateDoc(documentRef, {
        agent: user.uid,
        ...data,
      })
        .then(() => {
          window.location.href = "/dashboard/profil?showSuccessEditToast=true";
        })
        .catch((e) => {
          console.log("Fail with error Code:");
          console.log(e);
        });
    }

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => setWindowWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    const isFullWidth = windowWidth < 768;

    
    return (
      <div className="content" style={{overflow: 'hidden'}}>
        <div className="flex-gap-10" id="breadcrumb-title-text">
          <div style={{cursor: 'pointer'}} onClick={() => navigate(-1)}>{chevronLeftIcon}</div>
          <div>
            <Link to="/dashboard/profil" className="breadcrumb">Profil</Link>
            &nbsp;/&nbsp;
            Profil bearbeiten
          </div>
        </div>
        <div className="PageHeaderBox">
          <div>
            <div className="PageHeaderBoxTitle">Profil bearbeiten</div>
            <div className="PageHeaderBoxSubtitle">Gib so viele Informationen wie möglich an, um mehr Nutzer zu erreichen.</div>
          </div>
          <div className="ButtonsBox">
            <Link to={"#"} className="Button textCoMain bg-blue" onClick={submit} style={{ width: isFullWidth ? "100%" : "revert-layer" }}> Änderungen speichern </Link>
            <Link to={"/dashboard/profil"} className="bg-grey textCoMain Button" style={{ width: isFullWidth ? "100%" : "revert-layer" }}> Abbruch </Link>
          </div>
        </div>
        <div className="VorschauBox">
          <div style={{display: isFullWidth ? "none" : "flex"}}>
            <SideBar accountTyp={accountTyp}/>
          </div>
          <Inputs onDataChange={setData} setAccountTyp={setAccountTyp} accountTyp={accountTyp} areas={areas} setAreas={setAreas}></Inputs>
          <div className="VorschauContainer" style={{ alignSelf: 'stretch' }}>
            <div id="vorschauheader">
              Vorschau in der App
              <div className="Beschreibungsuntertitel">
                So sehen deine Gäste dein Profil in der App
              </div>
            </div>
            <div className="VorschauContainerBody" style={{height:"70%",display:"flex",justifyContent:"center",alignItems:"center"}}>
              <div id="noUpcoming" className="animate__animated animate__fadeInDown" style={{color: "var(--textSecondary)"}}>Coming Soon!</div>
            </div>
          </div>
        </div>
      </div>
    );
}
  
export { MeinProfilBearbeiten };