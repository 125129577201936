/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState,useEffect, useContext } from "react";
import { Link,useNavigate} from "react-router-dom";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import de from "date-fns/locale/de";
import "../../index.scss";
import "./website_bearbeiten.css";
import "./ticketing/ticketing_anlegen.css"
import "./events/event-erstellen.css";
import { toast } from "react-toastify";
import { enableRipple } from "@syncfusion/ej2-base";
import {  collection, doc, getDocs, getDoc, setDoc, query, where } from "firebase/firestore";
import { firestore} from "../../firebase";
import { UserContext } from "../../providers/UserProvider";
import { 
  titelbildIcon,
  nameIcon,
  datumIcon,
  adresseIcon,
  genreIcon,
  lineupIcon,
  minAlterIcon,
  eintrittIcon,
  chevronLeftIcon,
  chevronRightIcon,
  legalIcon,
  residentsIcon,
} from "../../icons.jsx";
import  {
    change_color,
    rechange_color,
  } from "./login_signup/account_erstellen_functions";
import { CompanyInput } from "./login_signup/account_erstellen_inputs.jsx";

enableRipple(true);

registerLocale("de", de);


  function Inputs(props) {
    const { organizerId } = useContext(UserContext);

    const [id, setId] = useState();
    const[events, setEvents] = useState(false);
    const[gallery, setGallery] = useState(false);
    const[about, setAbout] = useState(false);
    const[faq, setFaq] = useState(false);
    const[jobs, setJobs] = useState(false);
    const[floors, setFloors] = useState(false);
    const[residents, setResidents] = useState(false);
    const[font, setFont] = useState("Verdana");
    const[backgroundColor, setBackgroundColor] = useState("#000000");
    const[mainColor, setMainColor] = useState("#222222");
    const[fontColor, setFontColor] = useState("#ffffff");

    useEffect(() => { getDocs(query(
        collection(firestore, 'subsites'),
        where('organizer', '==', organizerId)
      )).then(e => {
        if(e.empty) return;
        const doc = e.docs[0];
        setId(doc.id);
        const data = doc.data();
        setEvents(data.modules?.includes('events'));
        setGallery(data.modules?.includes('gallery'));
        setAbout(data.modules?.includes('about'));
        setFaq(data.modules?.includes('faq'));
        setJobs(data.modules?.includes('jobs'));
        setFloors(data.modules?.includes('areas'));
        setResidents(data.modules?.includes('residents'));
        setFont(data.style?.font_family);
        setBackgroundColor(data.style?.background_color);
        setMainColor(data.style?.main_color);
        setFontColor(data.style?.text_color);
      }) }, [organizerId]);

      useEffect(() => { props.setId(id) }, [id]);
    
      useEffect(() => {
        props.onDataChange({
            modules: [...new Set([
                events ? 'events' : null,
                gallery ? 'gallery' : null,
                about ? 'about' : null,
                faq ? 'faq' : null,
                jobs ? 'jobs' : null,
                floors ? 'areas' : null,
                residents ? 'residents' : null,
            ].filter(e => e))],
            style: {
                font_family: font,
                background_color: backgroundColor,
                main_color: mainColor,
                text_color: fontColor,
            }
        });
      }, [events, gallery, about, faq, jobs, floors, font, backgroundColor, mainColor, fontColor, residents]);

    //company
    const [company, setCompany] = useState({});
    useEffect(() => {
        getDoc(doc(firestore, 'organizers', organizerId)).then(doc => {
            setCompany(doc.data().company);
        });
    }, [organizerId]);
    useEffect(() => { props.setCompany(company) }, [company]);

    return (
      <div className="ErstellenInputs" style={{gap: "30px"}}>
        <div className="middleSectionWebsite">
            <div>
                <div className="optionalHead">Elemente</div>
                <div className="optionalSubHead">Hier entscheidest du welche Elemente auf deiner Webseite zu sehen sind</div>
            </div>
            <div className="InputsItem">
                <EventsInput events={events} setEvents={setEvents}></EventsInput>
            </div>
            <div className="InputsItem">
                <GalleryInput gallery={gallery} setGallery={setGallery}></GalleryInput>
            </div>
            <div className="InputsItem">
                <AboutInput about={about} setAbout={setAbout}></AboutInput>
            </div>
            <div className="InputsItem">
                <FaqInput faq={faq} setFaq={setFaq}></FaqInput>
            </div>
            <div className="InputsItem">
                <FloorsInput floors={floors} setFloors={setFloors}></FloorsInput>
            </div>
            <div className="InputsItem">
                <ResidentsInput residents={residents} setResidents={setResidents}></ResidentsInput>
            </div>
            <div className="InputsItem">
                <JobsInput jobs={jobs} setJobs={setJobs}></JobsInput>
            </div>
        </div>
        <div className="middleSectionWebsite">
            <div>
                <div className="optionalHead">Aussehen</div>
                <div className="optionalSubHead">Hier kannst du das Aussehen deiner Webseite anpassen</div>
            </div>
            <div className="InputsItem">
                <FontInput font={font} setFont={setFont}></FontInput>
            </div>
            <div className="InputsItem">
                <FarbenInput
                    backgroundColor={backgroundColor} setBackgroundColor={setBackgroundColor}
                    mainColor={mainColor} setMainColor={setMainColor}
                    fontColor={fontColor} setFontColor={setFontColor}
                ></FarbenInput>
            </div>
        </div>
        <div className="middleSectionWebsite">
            <div>
                <div className="optionalHead">Einstellungen</div>
                <div className="optionalSubHead">Hier kannst du technische Sachen anpassen</div>
            </div>
            <div className="InputsItem">
                <DomainInput id={id} setId={setId}></DomainInput>
            </div>
            {!props.companyComplete && <div className="InputsItem">
                <CompanyInput company={company} setCompany={setCompany}></CompanyInput>
            </div>}
        </div>
        <div style={{height: 50}}>&nbsp;</div>
      </div>
    );
  }

  
    function EventsInput(props) {
        useEffect(() => {
            if (props.events) {
                change_color('events-icon', 'events-icon-nav');
            } else {
                rechange_color('events-icon', 'events-icon-nav');
            }
        }, [props.events]);
            
        return (
            <div className="InputBox" id="Events" key={"EventNameDiv"}>
                <div className="InputBoxTop">
                    <div className="InputBoxHeader" style={{marginBottom: "0px"}}>
                        <div className="InputCircle" id="events-icon">{nameIcon}</div>
                        <div className="InputBoxTitle">
                            <div className="InputName">Events</div>
                            <div className="InputDetails">
                                {props.events === true 
                                    ? <a href="/dashboard/events" target="_blank">Deine Events bearbeiten {chevronRightIcon}</a>
                                    : <div>Eine Übersicht aller deiner anstehenden Events</div>
                                }
                            </div>
                        </div>
                    </div>
                    <label className="switch">
                        <input
                            type="checkbox"
                            id="eventsSwitch"
                            className="checkboxDate"
                            checked={props.events}
                            onClick={() => {
                                if(!props.events){
                                    props.setEvents(true)
                                }
                                else{
                                    props.setEvents(false);
                                }
                            }}
                        />
                        <span className="slider round"></span>
                    </label>
                </div>
            </div>
        );
    }


    function GalleryInput(props) {
        useEffect(() => {
            if (props.gallery) {
                change_color('gallery-icon', 'gallery-icon-nav');
            } else {
                rechange_color('gallery-icon', 'gallery-icon-nav');
            }
        }, [props.gallery]);
            
        return (
            <div className="InputBox" id="Galerie" key={"EventNameDiv"}>
                <div className="InputBoxTop">
                    <div className="InputBoxHeader" style={{marginBottom: "0px"}}>
                        <div className="InputCircle" id="gallery-icon">{nameIcon}</div>
                        <div className="InputBoxTitle">
                            <div className="InputName">Galerie</div>
                            <div className="InputDetails">
                                {props.gallery === true 
                                    ? <a href="/dashboard/events" target="_blank">Deine Fotos hochladen {chevronRightIcon}</a>
                                    : <div>Gebündelt für jedes Event werden alle Fotos angezeigt</div>
                                }
                            </div>
                        </div>
                    </div>
                    <label className="switch">
                        <input
                        type="checkbox"
                        id="eventsSwitch"
                        className="checkboxDate"
                        checked={props.gallery}
                        onClick={() => {
                            if(!props.gallery){
                                props.setGallery(true)
                            }
                            else{
                                props.setGallery(false);
                            }
                        }}
                        />
                        <span className="slider round"></span>
                    </label>
                </div>
            </div>
        );
    }

    function AboutInput(props) {
        useEffect(() => {
            if (props.about) {
                change_color('about-icon', 'about-icon-nav');
            } else {
                rechange_color('about-icon', 'about-icon-nav');
            }
        }, [props.about]);
            
        return (
            <div className="InputBox" id="Über uns" key={"EventNameDiv"}>
                <div className="InputBoxTop">
                    <div className="InputBoxHeader" style={{marginBottom: "0px"}}>
                        <div className="InputCircle" id="about-icon">{nameIcon}</div>
                        <div className="InputBoxTitle">
                            <div className="InputName">Über Uns</div>
                            <div className="InputDetails">
                                {props.about === true 
                                    ? <a href="/dashboard/profil/bearbeiten#description" target="_blank">Deine Beschreibung bearbeiten {chevronRightIcon}</a>
                                    : <div>Deine Profilbeschreibung wird angezeigt</div>
                                }   
                            </div>
                        </div>
                    </div>
                    <label className="switch">
                        <input
                        type="checkbox"
                        id="eventsSwitch"
                        className="checkboxDate"
                        checked={props.about}
                        onClick={() => {
                            if(!props.about){
                                props.setAbout(true)
                            }
                            else{
                                props.setAbout(false);
                            }
                        }}
                        />
                        <span className="slider round"></span>
                    </label>
                </div>
            </div>
        );
    }

    function FaqInput(props) {
        useEffect(() => {
            if (props.faq) {
                change_color('faq-icon', 'faq-icon-nav');
            } else {
                rechange_color('faq-icon', 'faq-icon-nav');
            }
        }, [props.faq]);
            
        return (
            <div className="InputBox" id="FAQ" key={"EventNameDiv"}>
                <div className="InputBoxTop">
                    <div className="InputBoxHeader" style={{marginBottom: "0px"}}>
                        <div className="InputCircle" id="faq-icon">{nameIcon}</div>
                        <div className="InputBoxTitle">
                            <div className="InputName">FAQ</div>
                            <div className="InputDetails">
                                {props.faq === true 
                                    ? <a href="/dashboard/profil/bearbeiten#faq" target="_blank">Deine Fragen und Antworten bearbeiten {chevronRightIcon}</a>
                                    : <div>Wir zeigen das von dir erstellte FAQ an</div>
                                }  
                            </div>
                        </div>
                    </div>
                    <label className="switch">
                        <input
                        type="checkbox"
                        id="eventsSwitch"
                        className="checkboxDate"
                        checked={props.faq}
                        onClick={() => {
                            if(!props.faq){
                                props.setFaq(true)
                            }
                            else{
                                props.setFaq(false);
                            }
                        }}
                        />
                        <span className="slider round"></span>
                    </label>
                </div>
            </div>
        );
    }

    function FloorsInput(props) {
        useEffect(() => {
            if (props.floors) {
                change_color('floors-icon', 'floors-icon-nav');
            } else {
                rechange_color('floors-icon', 'floors-icon-nav');
            }
        }, [props.floors]);
            
        return (
            <div className="InputBox" id="Areas" key={"EventNameDiv"}>
                <div className="InputBoxTop">
                    <div className="InputBoxHeader" style={{marginBottom: "0px"}}>
                        <div className="InputCircle" id="floors-icon">{nameIcon}</div>
                        <div className="InputBoxTitle">
                            <div className="InputName">Floors</div>
                            <div className="InputDetails">
                                {props.floors === true 
                                    ? <a href="/dashboard/profil/bearbeiten#areas" target="_blank">Angaben zu deinen Floors anpassen {chevronRightIcon}</a>
                                    : <div>Wir präsentieren deine verschiedenen Floors</div>
                                }
                            </div>
                        </div>
                    </div>
                    <label className="switch">
                        <input
                        type="checkbox"
                        id="eventsSwitch"
                        className="checkboxDate"
                        checked={props.floors}
                        onClick={() => {
                            if(!props.floors){
                                props.setFloors(true)
                            }
                            else{
                                props.setFloors(false);
                            }
                        }}
                        />
                        <span className="slider round"></span>
                    </label>
                </div>
            </div>
        );
    }

    function ResidentsInput(props) {
        useEffect(() => {
            if (props.residents) {
                change_color('residents-icon', 'residents-icon-nav');
            } else {
                rechange_color('residents-icon', 'residents-icon-nav');
            }
        }, [props.residents]);
            
        return (
            <div className="InputBox" id="Residents" key={"EventNameDiv"}>
                <div className="InputBoxTop">
                    <div className="InputBoxHeader" style={{marginBottom: "0px"}}>
                        <div className="InputCircle" id="residents-icon">{residentsIcon}</div>
                        <div className="InputBoxTitle">
                            <div className="InputName">Residents</div>
                            <div className="InputDetails">
                                {props.residents === true 
                                    ? <a href="/dashboard/profil/bearbeiten#residents" target="_blank">Angaben zu deinen Residents anpassen {chevronRightIcon}</a>
                                    : <div>Wir präsentieren deine Stammkünstler und Djs</div>
                                }
                            </div>
                        </div>
                    </div>
                    <label className="switch">
                        <input
                        type="checkbox"
                        id="eventsSwitch"
                        className="checkboxDate"
                        checked={props.residents}
                        onClick={() => {
                            if(!props.residents){
                                props.setResidents(true)
                            }
                            else{
                                props.setResidents(false);
                            }
                        }}
                        />
                        <span className="slider round"></span>
                    </label>
                </div>
            </div>
        );
    }

    function JobsInput(props) {
        useEffect(() => {
            if (props.jobs) {
                change_color('jobs-icon', 'jobs-icon-nav');
            } else {
                rechange_color('jobs-icon', 'jobs-icon-nav');
            }
        }, [props.jobs]);
            
        return (
            <div className="InputBox" id="Jobs" key={"EventNameDiv"}>
                <div className="InputBoxTop">
                    <div className="InputBoxHeader" style={{marginBottom: "0px"}}>
                        <div className="InputCircle" id="jobs-icon">{nameIcon}</div>
                        <div className="InputBoxTitle">
                            <div className="InputName">Jobs</div>
                            <div className="InputDetails">
                                {props.jobs === true 
                                    ? <a href="/dashboard/profil/bearbeiten#jobs" target="_blank">Stellenanzeigen bearbeiten {chevronRightIcon}</a>
                                    : <div>Deine offenen Stellenanzeigen werden angezeigt</div>
                                }
                            </div>
                        </div>
                    </div>
                    <label className="switch">
                        <input
                        type="checkbox"
                        id="eventsSwitch"
                        className="checkboxDate"
                        checked={props.jobs}
                        onClick={() => {
                            if(!props.jobs){
                                props.setJobs(true)
                            }
                            else{
                                props.setJobs(false);
                            }
                        }}
                        />
                        <span className="slider round"></span>
                    </label>
                </div>
            </div>
        );
    }

    function FontInput(props) {

        const fontOptions = ['Verdana', 'Courier New', 'Vensfolk', 'Gagalin'];
        const handleFontChange = async (e) => {
            props.setFont(e.target.value);
        };

        useEffect(() => {
            if (props.font) {
                change_color('font-icon', 'font-icon-nav');
            } else {
                rechange_color('font-icon', 'font-icon-nav');
            }
        }, [props.font]);
            
        return (
            <div className="InputBox" id="Schriftart" key={"EventNameDiv"}>
                <div className="InputBoxHeader">
                    <div className="InputCircle" id="font-icon">{nameIcon}</div>
                    <div className="InputBoxTitle">
                        <div className="InputName">Schriftart</div>
                        <div className="InputDetails">Hier kannst du die Schriftart anpassen</div>
                    </div>
                </div>
                <select name="font" id="fontSelector" className='selectMarketingMini' value={props.font} onChange={handleFontChange}>
                    {fontOptions.map((font) => {
                        return(
                            <option key={font} value={font}>{font}</option>
                        )
                    })}
                </select>
            </div>
        );
    }

    function FarbenInput(props) {

        const themes = {
            modern: {
                name: 'Modern',
                mainColor: '#0d6efd',
                fontColor: '#0000000',
                backgroundColor: '#FFFFFF'
            },
            mystical: {
                name: 'Mystisch',
                mainColor: '#fb2f20',
                fontColor: '#3fff14',
                backgroundColor: '#111111'
            },
            dark: {
                name: 'Dunkel',
                mainColor: '#ffeb3b',
                fontColor: '#ffffff',
                backgroundColor: '#1e1e1e'
            },
            colorful: {
                name: 'Bunt',
                mainColor: '#9c27b0',
                fontColor: '#5b1d1d',
                backgroundColor: '#f6d112'
            },
        };

        const [selectedThemeId, setSelectedThemeId] = useState('custom');

        useEffect(() => {
            if (props.mainColor && props.backgroundColor && props.fontColor) {
                change_color('colors-icon', 'colors-icon-nav');
            } else {
                rechange_color('colors-icon', 'colors-icon-nav');
            }
        }, [props.mainColor, props.backgroundColor, props.fontColor]);

        const handleThemeChange = async (e) => {
            const id = e.target.value;
            setSelectedThemeId(id);
            if(id === 'custom') return;
            props.setMainColor(themes[id].mainColor);
            props.setBackgroundColor(themes[id].backgroundColor);
            props.setFontColor(themes[id].fontColor);
        };

        useEffect(() => {
            for(const id of Object.keys(themes)) {
                if(
                    props.mainColor === themes[id].mainColor
                    && props.fontColor === themes[id].fontColor
                    && props.backgroundColor === themes[id].backgroundColor
                ) {
                    setSelectedThemeId(id);
                    return;
                }
            }
            setSelectedThemeId('custom');
        }, [props.mainColor, props.fontColor, props.backgroundColor]);
            
        return (
            <div className="InputBox" id="Farben" key={"EventNameDiv"}>
                <div className="InputBoxHeader">
                    <div className="InputCircle" id="colors-icon">{nameIcon}</div>
                    <div className="InputBoxTitle">
                        <div className="InputName">Farben</div>
                        <div className="InputDetails">Bestimme das Aussehen deiner Webseite</div>
                    </div>
                </div>
                <div className="websiteColorBox">
                    <select name="theme" id="themeSelector" className='selectMarketingMini' style={{marginBottom: "15px"}} value={selectedThemeId} onChange={handleThemeChange}>
                        {Object.entries(themes).map((theme) => {
                            return(
                                <option value={theme[0]}>{theme[1].name}</option>
                            )
                        })}
                        {selectedThemeId === 'custom' && <option value="custom">Benutzerdefiniert</option>}
                    </select>
                    <div className="websiteColorBoxSingle">
                        <div className="websiteColorTitle">Hauptfarbe</div>
                        <input type="color" value={props.mainColor} className = "colorBox" onChange={(e) => {props.setMainColor(e.target.value)}}/>
                    </div>
                    <div className="websiteColorBoxSingle">
                        <div className="websiteColorTitle">Textfarbe</div>
                        <input type="color" value={props.fontColor} className = "colorBox" onChange={(e) => {props.setFontColor(e.target.value)}}/>  
                    </div>
                    <div className="websiteColorBoxSingle">
                        <div className="websiteColorTitle">Hintergrundfarbe</div>
                        <input type="color" value={props.backgroundColor} className = "colorBox" onChange={(e) => {props.setBackgroundColor(e.target.value)}}/>    
                    </div>
                </div>
            </div>
        );
    }

    function DomainInput(props) {

        const[domainInUse, setDomainInUse] = useState(false);
           
        function checkDomain(value) {
            if (value?.length > 4) {
                change_color('domain-icon', 'domain-icon-nav');
            } else {
                rechange_color('domain-icon', 'domain-icon-nav');
            }
        }

        useEffect(() => {
            checkDomain(props.id);
        }, [props.id]);

        const checkDomainAvailability = (value) => {
            if (value === 'usedDomain') {
              setDomainInUse(true);
            } else {
              setDomainInUse(false);
            }
        };

        return (
            <div className="InputBox" id="Domain" key={"EventNameDiv"}>
                <div className="InputBoxHeader">
                    <div className="InputCircle" id="domain-icon">{nameIcon}</div>
                    <div className="InputBoxTitle">
                        <div className="InputName">Domain</div>
                        <div className="InputDetails">Wähle bitte deine Domain</div>
                    </div>
                </div>
                <input
                    name="Name"
                    className="TextInput"
                    placeholder="Deine Domain"
                    id="DomainInput"
                    key={"DomainInput"}
                    value={props.id}
                    onChange={(e) => {
                        const value = e.target.value;
                        props.setId(value);
                        checkDomain(value);
                        checkDomainAvailability(value);
                    }}
                ></input>
                {props.id && !domainInUse && (
                    <div className="domainPreview">
                        Deine Webseite wird unter der Domain&nbsp;
                        <a href={`https://${props.id}.elgio.app`} target="_blank" rel="noopener noreferrer">
                        <span style={{textDecoration: "underline", cursor: "pointer"}}>{props.id}</span>
                        <span style={{textDecoration: "underline", cursor: "pointer"}}>.elgio.app</span>
                        </a>
                        &nbsp;auffindbar sein.
                    </div>
                )}
                {domainInUse && (
                    <div className="domainInUseAlert">
                        Diese Domain wird leider schon benutzt. Probier eine andere.
                    </div>
                )}
            </div>
        );
    }

    function SideBar({companyComplete}) {

        function smoothScroll(divid) {
            document.getElementById(divid).scrollIntoView({ behavior: "smooth" });
        }

        return (
            <div className="SideBarErstellen" style={{width: "400px"}}>
                <div>
                <div className="erforderlichSidebar">Elemente</div>
                <div className="itemSideBar" onClick={() => smoothScroll("Events")}>
                    <div className="circleSideBar" id="events-icon-nav">{nameIcon}</div>
                    <div className="iconBeschreibung">Events</div>
                </div>
                <div className="itemSideBar" onClick={() => smoothScroll("Galerie")}>
                    <div className="circleSideBar" id="gallery-icon-nav">{titelbildIcon}</div>
                    <div className="iconBeschreibung">Galerie</div>
                </div>
                <div className="itemSideBar" onClick={() => smoothScroll("Über Uns")}>
                    <div className="circleSideBar" id="about-icon-nav">{datumIcon}</div>
                    <div className="iconBeschreibung">Über Uns</div>
                </div>
                <div className="itemSideBar" onClick={() => smoothScroll("FAQ")}>
                    <div className="circleSideBar" id="faq-icon-nav">{adresseIcon}</div>
                    <div className="iconBeschreibung">FAQ</div>
                </div>
                <div className="itemSideBar" onClick={() => smoothScroll("Areas")}>
                    <div className="circleSideBar" id="floors-icon-nav">{genreIcon}</div>
                    <div className="iconBeschreibung">Floors</div>
                </div>
                <div className="itemSideBar" onClick={() => smoothScroll("Residents")}>
                    <div className="circleSideBar" id="residents-icon-nav">{residentsIcon}</div>
                    <div className="iconBeschreibung">Residents</div>
                </div>
                <div className="itemSideBar" onClick={() => smoothScroll("Jobs")}>
                    <div className="circleSideBar" id="jobs-icon-nav">{eintrittIcon}</div>
                    <div className="iconBeschreibung">Jobs</div>
                </div>
                <div className="optionalSidebar">Aussehen</div>
                <div className="itemSideBar" onClick={() => smoothScroll("Schriftart")}>
                    <div className="circleSideBar" id="font-icon-nav">{lineupIcon}</div>
                    <div className="iconBeschreibung">Schriftart</div>
                </div>
                <div className="itemSideBar" onClick={() => smoothScroll("Farben")}>
                    <div className="circleSideBar" id="colors-icon-nav">{lineupIcon}</div>
                    <div className="iconBeschreibung">Farben</div>
                </div>
                <div className="optionalSidebar">Einstellungen</div>
                <div className="itemSideBar" onClick={() => smoothScroll("Domain")}>
                    <div className="circleSideBar" id="domain-icon-nav">{minAlterIcon}</div>
                    <div className="iconBeschreibung">Domain</div>
                </div>
                {!companyComplete && <div className="itemSideBar" onClick={() => smoothScroll("company")}>
                    <div className="circleSideBar" id="legal-icon-nav">{legalIcon}</div>
                    <div className="iconBeschreibung">Firmendaten</div>
                </div>}
                </div>
            </div>
        );
    }

    export default function WebsiteBearbeiten() {
        const {user, organizerId} = useContext(UserContext);
        const [id, setId] = useState(null);
        const [newWebsite, setNewWebsite] = useState(true);
        const [data,setData] = useState({});
        const [company, setCompany] = useState({});
        const navigate = useNavigate();

        const [companyComplete, setCompanyComplete] = useState(false);
        useEffect(() => {
            getDoc(doc(firestore, 'organizers', organizerId)).then(doc => {
                const company = doc.data().company;
                const companyAddressComplete = company && company.address && company.address.line1 && company.address.postalCode && company.address.city && company.address.country;
                const companyComplete = company && company.name && company.representative && company.registrationNumber && company.court && companyAddressComplete;
                setCompanyComplete(companyComplete);
            });
        }, [organizerId]);

        const saveChanges = async () => {
            if(!id) return;

            await setDoc(doc(firestore, 'organizers', organizerId), {company}, {merge: true})
            
            const collectionRef = collection(firestore, 'subsites');
            const documentRef = doc(collectionRef, id);
            await setDoc(documentRef, {
                ...data,
                agent: user.uid,
                organizer: organizerId,
            }, {merge: true});
            return documentRef.id;
        }

        useEffect(() => { getDocs(query(
            collection(firestore, 'subsites'),
            where('organizer', '==', organizerId)
        )).then(e => e.docs.empty || setNewWebsite(false)) }, [organizerId]);


        return (
            <div className="content" style={{overflow: 'hidden'}}>
                {/* <div className="hochscrollBtn" onClick={() => {window.scrollTo({behavior: "smooth", top: 0, left: 0})}}>
                    <div id="hochscrollIcon">{arrowUpIcon}</div>
                </div> */}
                <div className="flex-gap-10" id="breadcrumb-title-text">
                    <div style={{cursor: 'pointer'}} onClick={() => navigate(-1)}>
                        {chevronLeftIcon}
                    </div>
                    <div>
                        <Link to="/dashboard/website" className="breadcrumb">Webseite</Link>
                        &nbsp;/&nbsp;
                        {id ? <>Webseite bearbeiten</> : <>Webseite erstellen</>}
                    </div>
                </div>
                <div className="PageHeaderBox">
                    <div>
                        <div className="PageHeaderBoxTitle">{id ? <>Webseite bearbeiten</> : <>Webseite erstellen</>}</div>
                        <div className="PageHeaderBoxSubtitle">Entscheide wie deine Webite aussehen und welche Elemente sie beinhalten soll.</div>
                    </div>
                    <div className="ButtonsBox">
                        
                        <Link to={"#"} 
                            className="Button" 
                            style={{backgroundColor: "var(--bgGreen)", color: "var(--textMain)"}}
                            onClick={async () => {
                                await saveChanges();
                                navigate('/dashboard/website');
                                toast.success("Deine Änderungen wurden gespeichert",{theme:"dark"})
                            }}
                        >
                            {newWebsite ? <>Live schalten</> : <>Änderungen speichern</>}
                        </Link>
                        <div className="Button" style={{backgroundColor: "var(--bg4)", color: "var(--textMain)"}} onClick={() => navigate("/dashboard/website")}>
                            Abbruch
                        </div>
                    </div>
                </div>
                <div style={{display: 'flex', gap: 75, overflowY: 'scroll', flex: 1}}>
                <SideBar companyComplete={companyComplete}></SideBar>
                <Inputs saveChanges={saveChanges} onDataChange={setData} setId={setId} setCompany={setCompany} companyComplete={companyComplete}></Inputs>
                    <div className="VorschauContainer" style={{ alignSelf: 'stretch', width: "60vw"}}>
                        <div id="vorschauheader">
                            Vorschau 
                            <div className= "Beschreibungsuntertitel">So wird deine Webseite aussehen</div>
                        </div>
                        <div className="ComingSoon">Coming Soon</div>
                    </div>
                </div>
            </div>
        );
    }