/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import Handlebars from 'handlebars'; // Import Handlebars library

function HandlebarsIframe({ templateHTML, data, autoWidth, autoHeight, onIframeRefChange }) {
  const containerRef = useRef();
  const iframeRef = useRef(null);

  useEffect(() => {onIframeRefChange && onIframeRefChange(iframeRef)}, [iframeRef]);

  function resizeIframe(obj) {
    const heightScale = autoHeight ? Number.MAX_SAFE_INTEGER : obj.parentElement.clientHeight / obj.contentWindow.document.documentElement.scrollHeight;
    const widthScale = autoWidth ? Number.MAX_SAFE_INTEGER : obj.parentElement.clientWidth / obj.contentWindow.document.documentElement.scrollWidth;
    const scale = Math.min(heightScale, widthScale);

    if(autoWidth) {
      containerRef.current.style.width = (containerRef.current.clientHeight / obj.contentWindow.document.documentElement.scrollHeight * obj.contentWindow.document.documentElement.scrollWidth) + 'px';
    }

    if(autoHeight) {
      containerRef.current.style.height = (containerRef.current.clientWidth / obj.contentWindow.document.documentElement.scrollWidth * obj.contentWindow.document.documentElement.scrollHeight) + 'px';
    }

    if(autoWidth && autoHeight) return;

    obj.style.height = obj.contentWindow.document.documentElement.scrollHeight + 'px';
    obj.style.width = obj.contentWindow.document.documentElement.scrollWidth + 'px';
    obj.style.transform = 'scale(' + scale + ')';
    obj.style['-o-transform'] = 'scale(' + scale + ')';
    obj.style['-webkit-transform'] = 'scale(' + scale + ')';
    obj.style['-moz-transform'] = 'scale(' + scale + ')';
  }

  const [compiledTemplate, setCompiledTemplate] = useState(null);
  useEffect(() => {
    if(!templateHTML) return;
    // Compile the Handlebars template
    setCompiledTemplate(() => Handlebars.compile(templateHTML));
  }, [templateHTML]);
  useEffect(() => {
    if(!iframeRef.current || !compiledTemplate) return;
    // Render the template with the provided data
    const renderedHTML = compiledTemplate(data);
    // Set the rendered HTML as the srcdoc of the iframe
    iframeRef.current.srcdoc = renderedHTML;
  }, [compiledTemplate, data, iframeRef]); // Add iframeKey to dependencies array

  return <div ref={containerRef} style={{width: '100%', height: '100%'}}>
    <iframe style={{ transformOrigin: '0% 0%', border: 'none', margin: 0, padding: 0, pointerEvents: 'none' }} scrolling='no' sandbox='allow-same-origin' onLoad={() => resizeIframe(iframeRef.current)} ref={iframeRef} title="Handlebars Iframe"></iframe>
  </div>;
}

export default HandlebarsIframe;

