import React, { useEffect, useState } from "react";
import { collection, doc, getDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import "../../index.scss";

export default function OrganizerName({id, alt, ...rest }) {
    const [name, setName] = useState('Loading...');

    useEffect(() => { id ? getDoc(doc(collection(firestore, 'organizers'), id)).then((doc) => setName(doc.data()?.name??alt??'')) : setName(alt??'') }, [id, alt]);
     return <span {...rest}>{name}</span>;
}