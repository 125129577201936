import React from "react";
import "./account_erstellen.css";
import "./account_erstellen";
import "./account_erstellen_inputs";
import "../../../index.scss";
import { adresseIcon, areasIcon, beschreibungIcon, genreIcon, helpIcon, jobsIcon, kontaktdatenIcon, legalIcon, nameIcon, openingHoursIcon, regionIcon, residentsIcon, socialMediaIcon, titelbildIcon } from "../../../icons";


export default function SideBar(props) {

  function smoothScroll(divid) {
    document.getElementById(divid).scrollIntoView({ behavior: "smooth" });
  }

  return (
    <div className="SideBarErstellen">
      <div>
        <div className="erforderlichSidebar">Erforderliche Angaben</div>
        <div className="itemSideBar" onClick={() => smoothScroll("name")}>
          <div className="circleSideBar" id="name-icon-nav">{nameIcon}</div>
          <div className="iconBeschreibung">Name</div>
        </div>
        <div className="itemSideBar" onClick={() => smoothScroll("art")}>
          <div className="circleSideBar" id="art-icon-nav">{socialMediaIcon}</div>
          <div className="iconBeschreibung">Accounttyp</div>
        </div>
       {["standard","label"].includes(props.accountTyp) && <div className="itemSideBar" onClick={() => smoothScroll("land-region")}>
          <div className="circleSideBar" id="land-region-icon-nav">{regionIcon}</div>
          <div className="iconBeschreibung">Gebiet</div>
        </div>}
        <div className="itemSideBar" onClick={() => smoothScroll("titelbild")}>
          <div className="circleSideBar" id="titelbild-icon-nav">{titelbildIcon}</div>
          <div className="iconBeschreibung">Titelbild</div>
        </div>
        <div className="itemSideBar" onClick={() => smoothScroll("description")}>
          <div className="circleSideBar" id="beschreibung-icon-nav">{beschreibungIcon}</div>
          <div className="iconBeschreibung">Beschreibung</div>
        </div>
        <div className="itemSideBar"  onClick={() => smoothScroll("adresse")} style={["label","standard"].includes(props.accountTyp) ? {display: 'none'} : {}}>
          <div className="circleSideBar" id="adresse-icon-nav">{adresseIcon}</div>
          <div className="iconBeschreibung">Adresse</div>
        </div>
        <div className="optionalSidebar">Optionale Angaben</div>
        <div className="itemSideBar" onClick={() => smoothScroll("genreDiv")}>
          <div className="circleSideBar" id="logo-icon-genre">{genreIcon}</div>
          <div className="iconBeschreibung">Genre</div>
        </div>
        <div className="itemSideBar" onClick={() => smoothScroll("logoInputDiv")}>
          <div className="circleSideBar" id="logo-icon-nav">{titelbildIcon}</div>
          <div className="iconBeschreibung">Logo / Icon</div>
        </div>
        <div className="itemSideBar" onClick={() => smoothScroll("social-media")}>
          <div className="circleSideBar" id="social-media-icon-nav">{socialMediaIcon}</div>
          <div className="iconBeschreibung">Social Media</div>
        </div>
        <div className="itemSideBar" onClick={() => smoothScroll("kontaktdaten")}>
          <div className="circleSideBar" id="kontaktdaten-icon-nav">{kontaktdatenIcon}</div>
          <div className="iconBeschreibung">Kontaktdaten</div>
        </div>
        <div className="itemSideBar" onClick={() => smoothScroll("residents")}>
          <div className="circleSideBar" id="residentsNav">{residentsIcon}</div>
          <div className="iconBeschreibung">Residents</div>
        </div>
        {!["label","standard"].includes(props.accountTyp) &&  <div className="itemSideBar" onClick={() => smoothScroll("opening-hours")}>
          <div className="circleSideBar" id="opening-hours-icon-nav">{openingHoursIcon}</div>
          <div className="iconBeschreibung">Öffnungszeiten</div>
        </div>}
        {!["label","standard"].includes(props.accountTyp) && <div className="itemSideBar" onClick={() => smoothScroll("areas")}>
          <div className="circleSideBar" id="areas-icon-nav">{areasIcon}</div>
          <div className="iconBeschreibung">Areas</div>
        </div>}
        <div className="itemSideBar" onClick={() => smoothScroll("faq")}>
          <div className="circleSideBar" id="faq-icon-nav">{helpIcon}</div>
          <div className="iconBeschreibung">FAQ</div>
        </div>
        <div className="itemSideBar" onClick={() => smoothScroll("company")}>
          <div className="circleSideBar" id="legal-icon-nav">{legalIcon}</div>
          <div className="iconBeschreibung">Firmendaten</div>
        </div>
        <div className="itemSideBar" onClick={() => smoothScroll("jobs")}>
          <div className="circleSideBar" id="jobs-icon-nav">{jobsIcon}</div>
          <div className="iconBeschreibung">Jobs</div>
        </div>
      </div>
    </div>
  );
}
