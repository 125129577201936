export const styleModal = {
    content: {
        opacity: "100%",
        backgroundColor: "var(--bg2)",
        width: "85vw",
        height: "80vh",
        borderRadius: "7px",
        position: "relative",
        border: "none",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        padding: "35px 45px",
        display: "flex",
        flexDirection: "column",
    },
    overlay: {
        zIndex: "1",
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        opacity: "100%",
    },
} 