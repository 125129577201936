import React, { useEffect, useState } from "react";
import { collection, doc, getDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import "../../index.scss";

export default function GenreName({ id, alt, ...rest }) {
    const [name, setName] = useState('Loading...');

    useEffect(() => { id ? getDoc(doc(collection(firestore, 'genres'), id)).then((doc) => setName(doc.data()?.de??alt??'')) : setName(alt??'') }, [alt, id]);
    
    // console.log(id);
    // console.log(name);
    return <span {...rest}>{name}</span>;
}