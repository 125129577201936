import React, { useState } from "react";
import {
  banIcon,
  closedEyeIcon,
  copyIcon,
  deleteIcon,
  eyeIcon,
  linkIcon,
} from "../../icons";
import { isTestUser } from "../../App";
import { ContextMenu, ContextMenuOption } from "./contextmenu";
import { collection, deleteDoc, doc, getDoc, updateDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import { handleCopyLink } from "../functions/copyLink";
import { errorToast, eventCanceledToast, eventDuplicatedToast, eventSetLiveToast, eventSetPrivateToast, linkCopiedToast } from "./toasts.tsx";
import LoaderDivWithOverlay from "./loaderdiv";
import { Link } from "react-router-dom";

function ContextMenu__Event({id="",pendingReview=false,visibility = "public",startDateRaw = new Date(),canceled = false,...props}) {
   const [closeContextMenu, setCloseContextMenu] = useState(false)

    const cancelEvent = async () => {
        if (
      !window.confirm(
          "Bist du dir sicher, dass du das Event absagen möchtest?\nÄnderungen können nur von unserem Serviceteam vorgenommen werden!"
        )
    )
    return;
    await updateDoc(doc(collection(firestore, "events"), id), {
        canceled: true,
    }).then(() => {});
    eventCanceledToast();
};

  const handleSetPrivate = async () => {
      if(!window.confirm("Bist du dir sicher, dass du das Event Privat stellen willst?")) return
      await updateDoc(doc(collection(firestore,"events"),id),{visibility:"private"})
      eventSetPrivateToast()
    }

    const handleSetLive = async () => {
      if(!window.confirm("Bist du dir sicher, dass du das Event Privat stellen willst?")) return
      await updateDoc(doc(collection(firestore,"events"),id),{visibility:"public"})
      eventSetLiveToast()
  }

  const duplicate = async () => {
    let data = {}
    const loaderDiv = document.getElementsByClassName('loaderDivNew')[0]
    loaderDiv.style.visibility = "visible"
  
    try {
      // Fetch the original document
      const eventDoc = await getDoc(doc(firestore, "events", id));
      
      if (eventDoc.exists()) {
        data = eventDoc.data();
        data["name"] = "Kopie von " + eventDoc.data().name;
        data["visibility"] = "draft";
        data["created_timestamp"] = new Date();
  
        
        try {  
          eventDuplicatedToast();
        } catch (e) {
          console.error("Error adding document:", e);
          errorToast(e.code);
        }
      } else {
        console.error("Original document not found");
        errorToast("document-not-found");
      }
    } catch (e) {
      console.error("Error fetching document:", e);
      errorToast(e.code);
    } finally {
      loaderDiv.style.visibility = "hidden";
    }
  };    

    const delete_event = async () => {
        if(window.confirm("Bist du dir sicher, dass du dieses Event löschen willst?")){
            await deleteDoc(doc(firestore,"events",id))
            setCloseContextMenu(true)
            props.setReloadDrafts ? 
            props.setReloadDrafts(true)
            :
            props.setReloadEvents(true)
          }
    }
    
  return (
    <>
    <ContextMenu className="dropdown-box" id={id} closeContextMenu={closeContextMenu} isDraft={visibility === "public"}>
        {(visibility === "public" && startDateRaw > new Date()  && canceled !== true) ? 
            <ContextMenuOption icon={banIcon} text={"Event absagen"} onClick={cancelEvent}></ContextMenuOption>
            : <></>
        }
        {visibility === "public" ?
            <ContextMenuOption icon={linkIcon} text="Eventlink kopieren" onClick={() => {handleCopyLink(id);linkCopiedToast()}} />   
            : <></>
        }
        {visibility === "public" ?
            <ContextMenuOption icon={closedEyeIcon} text={"Event Privat stellen"} onClick={handleSetPrivate}></ContextMenuOption>
            :
            visibility !== "draft" && !pendingReview ? 
                <ContextMenuOption icon={eyeIcon} text={"Event Live stellen"} onClick={() => {handleSetLive()}}></ContextMenuOption>
            : <></>
        }
    <ContextMenuOption icon={copyIcon} text="Event duplizieren" onClick={duplicate} />
    {visibility === "draft" || isTestUser ? <ContextMenuOption icon={deleteIcon} text="Event löschen" onClick={delete_event} />  : <></>}
    </ContextMenu>
    <LoaderDivWithOverlay></LoaderDivWithOverlay>
    </>

  );
}

export default ContextMenu__Event;
