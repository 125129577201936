import { doc, getDoc, updateDoc } from 'firebase/firestore'
import React, { useEffect } from 'react'
import { firestore } from '../../../firebase'
import OrganizerName from '../../widgets/OrganizerName';
import { useContext } from 'react';
import { UserContext } from '../../../providers/UserProvider';
import { Link, useNavigate } from 'react-router-dom';
import "../../../index.scss";


function Fusion() {
    const { organizerId } = useContext(UserContext);
    const navigate = useNavigate()
    useEffect(() => {
        document.getElementById('root').style.display="flex"
        document.getElementById('root').style.justifyContent="center"
        document.getElementById('root').style.alignItems="center"
        document.getElementById('root').style.height="100vh"
        document.getElementById('root').style.backgroundImage="none"
    },[])
    
    
      useEffect(() => {
    getDoc(doc(firestore,"organizers",organizerId)).then((organizer) => {
      if(!organizer.data().show_fusion){
        document.getElementById('root').style.display="flex"
        document.getElementById('root').style.justifyContent=""
        document.getElementById('root').style.alignItems=""
        document.getElementById('root').style.height="100vh"
        document.getElementById('root').style.backgroundImage="none"
          navigate("/dashboard")
        }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
    async function  handleClick (){
        document.getElementById('root').style.display="flex"
        document.getElementById('root').style.justifyContent=""
        document.getElementById('root').style.alignItems=""
        document.getElementById('root').style.height="100vh"
        document.getElementById('root').style.backgroundImage="none"
        await updateDoc(doc(firestore,"organizers",organizerId),{
            show_fusion: false
        }) 

    }
    return (
        <div style={{color: "var(--textMain)",textAlign:"center",height:"60%",alignItems:"center"}} className='col-flex flex-gap-1rem' >
            <div className="header1" style={{color: "var(--textMain)",fontSize:"2.3vw",fontWeight:"600", margin: "20px"}}>Wir haben euer Profil ergänzt!</div>
            <div className="header2" style={{color:"var(--lightgrey)",fontSize:"var(--fontsize-huge)", margin: "10px"}}>Liebes <OrganizerName id={organizerId}></OrganizerName> Team, <br /> Wie ihr vielleicht schon gesehen habt, hatten wir schon ein temporäres <br /> Profil von euch in der App, damit wir diesem eure Events zuordnen konnten. </div>
            <div className="header2" style={{color:"var(--lightgrey)",fontSize:"var(--fontsize-huge)", margin: "10px"}}>
                Jetzt wo ihr euer eigenes Profil habt, haben wir das temporärer, alte Profil <br /> von uns gelöscht. Alle <b style={{color: "var(--textMain)"}}>Events, Rankings und Statistiken  </b>
                     haben wir aber  <br /> auf euer neues, eigenes Profil <b style={{color: "var(--textMain)"}}>übertragen</b>. 
            </div>

            <div className="header2" style={{color:"var(--lightgrey)",fontSize:"var(--fontsize-huge)", margin: "10px"}}>
                Keine Sorge, es verändert sich also nichts für euch. <br />
                Wenn ihr noch Fragen habt, schreibt uns.
            </div>


            <Link to="/dashboard" onClick={handleClick} style={{width:"fit-content",textDecoration:"none",fontWeight:"600",marginTop:"50px"}}>
                <div className="Item" id="newEvent" style={{position:"unset"}}>
                    Zum Dashboard
                </div>
            </Link>
        </div>
    )
}

export default Fusion