/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import "./network.css";
import { useNavigate } from 'react-router-dom'
import "../../index.scss";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { firestore } from "../../firebase";
import { UserContext } from "../../providers/UserProvider";
import { instagramIcon, mailIcon, phoneIcon, searchIcon, websiteIcon, organizerLogoIcon, cancelIcon } from '../../icons';
import { index } from "../../algolia";
import FirebaseStorageImage from "../functions/FirebaseStorageImage";
import { typeMap } from "../global_config/organizerTypeMap";


export default function Network(){
    const navigate = useNavigate()
    const {organizerId} = useContext(UserContext);
    // eslint-disable-next-line no-unused-vars
    const [organizerName,setOrganizerName] = useState();
    // eslint-disable-next-line no-unused-vars
    const [organizerType,setOrganizerType] = useState("");
    // eslint-disable-next-line no-unused-vars
    const [organizerCity,setOrganizerCity] = useState("");
    // eslint-disable-next-line no-unused-vars
    const [organizerWebsite,setOrganizerWebsite] = useState("");
    // eslint-disable-next-line no-unused-vars
    const [organizerTele,setOrganizerTele] = useState("");
    // eslint-disable-next-line no-unused-vars
    const [organizerEmail,setOrganizerEmail] = useState("");
    // eslint-disable-next-line no-unused-vars
    const [organizerInsta,setOrganizerInsta] = useState("");
    const [idk, setIdk] = useState("");
    const [value,setValue] = useState("")
    const [organizerList,setOrganizerList] = useState([])
    const [citiesArray,setCitiesArray] = useState([]);
    const [typesArray] = useState(
        [
            {type:"Club / Eventlocation",value:"venue"},
            {type:"Veranstalter",value:"standard"},
            {type:"Label",value:"label"},
            {type:"Cafe",value:"cafe"},
            {type:"Location",value:"location"},
            {type:"Bar",value:"bar"},
        ])
    const [musikGenreArray,setMusikGenreArray] = useState([]);
    const [selectedCities,setSelectedCities] = useState([]);
    const [selectedTypes,setSelectedTypes] = useState([]);
    const [selectedGenres,setSelectedGenres] = useState([]);
    const [searchFilters,setSearchFilters] = useState([selectedCities,selectedTypes,selectedGenres])
    const [searchFilterIDsCities,setSearchFilterIDsCities] = useState([])
    const [searchFilterIDsTypes,setSearchFilterIDsTypes] = useState([])
    const [searchFilterIDsGenres,setSearchFilterIDsGenres] = useState([])
    const [searchQueryAlgolia,setSearchQueryAlgolia] = useState("")
    const [page,setPage] = useState(0)
    const [genreMap,setGenreMap] = useState({})

    // get genres from firebase
    useEffect(() => {
        getDocs(collection(firestore, 'genres')).then(e => {
            setMusikGenreArray(e.docs)
            if (e.docs && e.docs.length > 0) {
                const genreMap = e.docs.reduce((map, item) => {
                  map[item.id] = item.data().de;
                  return map;
                }, {});  
            setGenreMap(genreMap);
            }})},[])

    // get cities from firebase
    useEffect(() => {
        getDocs(collection(firestore, 'cities')).then(e => setCitiesArray(e.docs))
    },[]);

    // Define state to keep track of whether there are more pages to load
    const [hasMorePages, setHasMorePages] = useState(false);
    // Function to perform the search
    const performSearch = (filter, pageNumber) => {
        let query = value.length === 0 ? "" : value; // Include value in the query
        index.search(query, {
            filters: filter,
            hitsPerPage: "10",
            page: pageNumber // Include the page parameter
        }).then(({ hits,nbPages }) => {
            // Sort the hits by city
            const sortedHits = hits.sort((a, b) => {
                if (a.city < b.city) return -1;
                if (a.city > b.city) return 1;
                return 0;
            });

            if (pageNumber === 0) {
                // Reset the array if it's the initial search
                setOrganizerList(sortedHits.length > 0 ? sortedHits : []);
            } else {
                if (sortedHits.length > 0) {
                    // Append new hits to the existing array
                    setOrganizerList(prevHits => [...prevHits, ...sortedHits]);
                } else {
                    // If there are no more hits, set hasMorePages to false
                    setHasMorePages(false);
                }
            }
            // Check if there are more pages to load
            setHasMorePages(pageNumber + 1 < nbPages);
        });
    };

    // Perform initial load
    const initialLoad = () => {
        let filter = `recordType:"organizer"`;
        if (searchQueryAlgolia.length > 0) {
            filter += ` AND ${searchQueryAlgolia}`;
        }
        performSearch(filter, 0); // Initialize with page 0
    };

    useEffect(() => {
        initialLoad();
    }, []);

    // Handle search when filters change or value changes
    useEffect(() => {
        let filter = `recordType:"organizer"`;
        if (searchQueryAlgolia.length > 0) {
            filter += ` AND ${searchQueryAlgolia}`;
        }
        setPage(0); // Reset page to 0 when search query changes
        performSearch(filter, 0); // Perform search with page 0
    }, [value, searchQueryAlgolia]); // Include value and searchQueryAlgolia in the dependency array

    // Handle "Load More" button click
    const handleLoadMore = () => {
        setPage(prevPage => prevPage + 1);
    };

    // Handle the effect of page changes
    useEffect(() => {
        if (page > 0) {
            let filter = `recordType:"organizer"`;
            if (searchQueryAlgolia.length > 0) {
                filter += ` AND ${searchQueryAlgolia}`;
            }
            performSearch(filter, page);
        }
    }, [page]); // Re-perform search when page changes

    useEffect(() => {
        getDoc(doc(collection(firestore,'organizers'),organizerId))
        .then((snapshot) =>  {
          setOrganizerName(snapshot.data().name)
          setOrganizerType(typeMap[snapshot.data()?.type])
          setOrganizerCity(snapshot.data().city ?? ((snapshot.data().address?.split(', ')?.length??0) > 2 ? snapshot.data().address.split(', ')[2] : ''))
          setOrganizerWebsite(snapshot.data().website)
          setOrganizerTele(snapshot.data().phone)
          setOrganizerEmail(snapshot.data().email)
          setOrganizerInsta(snapshot.data().instagram)
        } )
        .catch((error) => console.log(error)
        )  
      },
        [organizerId]
      );

    const deleteSearchFilter = (arrayIndex,index) => {
        let tmpOuter = searchFilters
        let tmpInner = searchFilters[arrayIndex];
        tmpInner.splice(index, 1);
        tmpOuter[arrayIndex] = tmpInner;
        setSearchFilters(tmpOuter);

        let tmp = arrayIndex === 0 ? searchFilterIDsCities : arrayIndex === 1 ? searchFilterIDsTypes : searchFilterIDsGenres;
        tmp.splice(index, 1);
        switch(arrayIndex) {
            case 0: 
                setSearchFilterIDsCities([...tmp])
                break
            case 1:
                setSearchFilterIDsTypes([...tmp])
                break
            case 2: 
                setSearchFilterIDsGenres([...tmp])
                break
            default: break
        }

        // Needs some State update to render deletion 
        setIdk(!idk)
    }

    const handleQueryChange = (firebaseID,array) => {
        switch(array){
            case "city": 
                setSearchFilterIDsCities((prev) => [...prev,firebaseID])
                break
            case "type": 
                setSearchFilterIDsTypes((prev) => [...prev,firebaseID])
                break
            case "genre": 
                setSearchFilterIDsGenres((prev) => [...prev,firebaseID])
                break
            default: break
        }
    }
    
    useEffect(() => {
        let searchQueryCities = searchFilterIDsCities.map(element => `city:"${element}"`).join(" OR ");
        let searchQueryGenres = searchFilterIDsGenres.map(element => `genre:"${element}"`).join(" OR ");
        let searchQueryTypes = searchFilterIDsTypes.map(element => `type:"${element}"`).join(" OR ");
        let query = searchQueryCities +
            (searchQueryTypes.length !== 0 && searchQueryCities.length !== 0 ? " AND " : "") +
            (searchQueryTypes) +
            (searchQueryGenres.length !== 0 && (searchQueryTypes.length !== 0 || searchQueryCities.length !== 0) ? " AND " : "") +
            (searchQueryGenres);
        setSearchQueryAlgolia(query);
    }, [searchFilterIDsCities, searchFilterIDsTypes, searchFilterIDsGenres]);

    const deleteAllFilters = () => {
        setSelectedCities([])
        setSelectedGenres([])
        setSelectedTypes([])
        setSearchQueryAlgolia("")
        setSearchFilterIDsCities([])
        setSearchFilterIDsGenres([])
        setSearchFilterIDsTypes([])
    }

    const handleSelect = (index, value, array, firebaseID) => {
        if (value !== "null" && !searchFilters[index].includes(value)) {
          switch (array) {
            case "city":
              setSelectedCities((prev) => [...prev, value]);
              break;
            case "type":
              setSelectedTypes((prev) => [...prev, value]);
              break;
            case "genre":
              setSelectedGenres((prev) => [...prev, value]);
              break;
            default:
              break;
          }
        //   setSearchFilterIDs((...prev) => [...prev]);
          handleQueryChange(firebaseID,array)
        }
        let docId = "select" + index;
        document.getElementById(docId).value = "null";
      };

  
    useEffect(() => {
        setSearchFilters([selectedCities,selectedTypes,selectedGenres])
    },[selectedCities,selectedTypes,selectedGenres])

    return(
        <div className="content">
            <div>
                <div id="breadcrumb-title-text" onClick={() => navigate(-1) }>Netzwerk</div>
                <div className="PageHeaderBox">
                    <div>
                        <div className="PageHeaderBoxTitle">Netzwerk</div>
                        <div className="PageHeaderBoxSubtitle">Hier kannst du neue Veranstalterkontakte schließen und finden</div>
                    </div> 
                </div>
            </div>
            <div className='searchBox'>
                <div className="searchBoxLeft">
                    <input type="text" id="suchfeld-tickets" value={value} onChange={(e) => {setValue(e.target.value)}} placeholder="z.B. Veranstaltername"></input>
                    <div style={{ marginLeft: "-4%", color: "var(--grey)" }}>{searchIcon}</div>
                </div>
                <div className="searchBoxRight">
                    <select name="" id="select0" className="selectNetwork"
                        onChange={(e) => {
                            let selectedOption = e.target.options[e.target.selectedIndex];
                            let customPropValue = selectedOption.dataset.firebaseid;
                            handleSelect(0, e.target.value, "city", customPropValue);
                        }}>
                        <option value="null">Stadt</option>
                            {citiesArray.map((city) => (
                                <option value={city.data().name} data-firebaseID={city.id}>
                                {city.data().name}
                                </option>
                            ))}
                    </select>
                    <select name="" id="select1" className="selectNetwork"
                        onChange={(e) => {
                            let selectedOption = e.target.options[e.target.selectedIndex];
                            let customPropValue = selectedOption.dataset.firebaseid;
                            handleSelect(1, e.target.value, "type", customPropValue);
                        }}>
                        <option value="null">Typ</option>
                            {typesArray.map(type => {
                                return(
                                    <option value={type.type} key={type.type} data-firebaseID={type.value}>{type.type}</option>
                                )
                            })}
                    </select>
                    <select name="" id="select2" className="selectNetwork"
                        onChange={(e) => {
                            let selectedOption = e.target.options[e.target.selectedIndex];
                            let customPropValue = selectedOption.dataset.firebaseid;
                            handleSelect(2, e.target.value, "genre", customPropValue);
                        }}>
                            <option value="null">Musik-Genre</option>
                            {musikGenreArray.map(genre => 
                                <option value={genre.data().de} data-firebaseID={genre.id}>{genre.data().de}</option>
                            )}
                    </select>
                </div>
            </div>

            {/* all selected filters box, delete filters on click from .map list */}
            <div className="selectedFiltersBox">
                {(searchFilters[0].length > 0 || searchFilters[1].length > 0 || searchFilters[2].length > 0) && <div className="deleteAllText" onClick={() => {deleteAllFilters()}}>Alle löschen</div>}
                {searchFilters.map((filterArray,filterArrayIndex) => 
                    filterArray.map((filter,index) => 
                         <div className="selectedFilters" onClick={() => {deleteSearchFilter(filterArrayIndex,index)}}>
                        <div className="ItemText">{filter}</div>
                        <div>{cancelIcon}</div>
                    </div>
                )
                )}
            </div>

            {/* data that needs to be pulled from server and be displayed in result box */}
            <div className='resultBox' style={organizerList.length === 0 ? {justifyContent:"center",alignItems:"center"} : {}}>
                {Object.keys(organizerList).length !== 0 
                ?
                    Object.keys(organizerList).map((key,index) => {
                        return(
                        <OrganizerRow
                          name={organizerList[key].name??"-"}
                          type={organizerList[key].type??"-"}
                          city={organizerList[key].city??"-"}
                          phone={organizerList[key].phone??"-"}
                          email={organizerList[key].email??"-"}
                          website={organizerList[key].website??"-"}
                          banner={organizerList[key].banner?.length > 0 ? organizerList[key].banner : organizerList[key].images?.length ? organizerList[key].images[0] : null}
                          instagram={organizerList[key].instagram??"-"}
                          genre={organizerList[key].genre?.length > 0 ? organizerList[key].genre : "-"}
                          genreMap={genreMap}
                        />
                        )
                    }) 
                : 
                <div className='noResults'>Es gibt leider keine Ergebnisse für deine Suchanfrage</div>
                }
            </div>
            <div className="row-flex justify_center align_center" style={{marginTop:"20px"}} >
                {hasMorePages && <div onClick={handleLoadMore} style={{textAlign:"center",backgroundColor:"var(--white)",color:"var(--black"}} className='selectNetwork pointer'>
                    Mehr laden
                </div>}
            </div>
        </div>
    )
}

function OrganizerRow(props){
    const [website,setWebsite] = useState("")
    const [instagram,setInstagram] = useState("")
    const [email,setEmail] = useState("")
    const [name, setName] = useState ("")
    const [genre,setGenre] = useState("")
    function isValidUrl(string) {
        try {
          new URL(string);
          return true;
        } catch (err) {
          return false;
        }
      }

    useEffect(() => {
        if(props.website !== "-" && isValidUrl(props.website)){
            let parsedUrl = new URL(props.website)
            if(parsedUrl.hostname.length > 20){
                let websiteShort = parsedUrl.hostname.slice(0,20) + "..."
                setWebsite(websiteShort)                
            }
            else{
                setWebsite(parsedUrl.hostname)
            }

        }
        if(props.genre !== "-"){
            if (props.genre.length > 1){
                let genreArr = []
                for (let i = 0;i < 2;i++){
                    genreArr.push(props.genreMap[props.genre[i]])
                    if (genreArr.length === 2) setGenre(genreArr.join(", "))
                }
            }
            else{
                setGenre(props.genreMap[props.genre[0]])
            }
        }
        if(props.email !== "-" && props.email.length > 20){
            let emailShort = props.email.slice(0,20) + "..."
            setEmail(emailShort)
        }
        else{
            setEmail(props.email)
        }

        if (props.name.length > 30){
            let nameShort = props.name.slice(0,30) + "..."
            setName(nameShort)
        }
        else {
            setName(props.name)
        }

        if(props.instagram !== "-" && props.instagram.includes("/")){
            let parsedUrl = new URL(props.instagram)
            let username = parsedUrl.pathname.slice(1,-1)
            if(username.length > 20){
                let usernameShort = username.slice(0,20) + "..."
                setInstagram(usernameShort)
            }
            else{
                setInstagram(username)
            }
        }

        return () => {setInstagram(""); setWebsite("")}
    },[props])

    return(
        <div className="resultOnly">
            <div className="resultsOnlyLeft">
                <div className={props.banner !== null ? "resultCircle" : "resultCircle placeholderCircle"}>
                    {props.banner !== null ? <FirebaseStorageImage reference={props.banner} className="resultsLogo"></FirebaseStorageImage> : organizerLogoIcon }
                </div>
                <div className="resultTitleBox">
                    <div className="resultTitle" title={props.name}>{name}</div>
                    <div className="resultSubtitle">{typeMap[props.type]}</div>
                </div>
            </div>
            <div className="resultsOnlyMiddle">
                <div className="resultDetails">
                    <div className="resultInfoHeader">
                        Stadt
                    </div>
                    <div className="resultInfo">
                        {props.city !== "-" ? props.city[0].toUpperCase() + props.city.slice(1) : <div style={{color: "var(--textSecondary)"}}>Keine Info</div>}
                    </div>  
                </div>
                <div className="resultDetails" style={{width:"60%"}}>
                    <div className="resultInfoHeader">
                        Musik-Genre
                    </div>
                    <div className="resultInfo">
                        {props.genre !== "-" ?  genre : <div style={{color: "var(--textSecondary)"}}>Keine Info</div>}
                    </div> 
                </div>
            </div>
            <div className="resultsOnlyRight">
                <div className="resultContact">
                    <div className="colContact">
                        <div className="contactInfo"> {phoneIcon} {props.phone !== "-" ? props.phone[0].toUpperCase() + props.phone.slice(1) : <div style={{color: "var(--textSecondary)"}}>Keine Info</div>}</div>
                        <div className="contactInfo">
                            {instagramIcon}
                            {instagram || props.instagram !== "-"
                            ?
                            <a className="contactInfo" href={instagram ? "https://www.instagram.com/" + instagram : "https://www.instagram.com/" + props.instagram } target="_blank" title={props.instagram} rel="noreferrer">
                                {instagram ? instagram : props.instagram !== "-" ? props.instagram : ""}
                            </a>
                            : <div style={{color: "var(--textSecondary)"}}>Keine Info</div>}
                        </div>
                    </div>
                    <div className="colContact">
                        <div className="contactInfo">
                            {websiteIcon}
                            {props.website !== "-" ?
                            <a className="contactInfo" href={props.website} target="_blank" title={props.website} rel="noreferrer">
                                {website}
                            </a>
                            : <div style={{color: "var(--textSecondary)"}}>Keine Info</div>}
                        </div>
                        <div className="contactInfo">
                            {mailIcon}
                            {props.email !== "-" ?
                            <a className="contactInfo" href={"mailto:" + email} title={props.email}>
                                {email}
                            </a>
                            : <div style={{color: "var(--textSecondary)"}}>Keine Info</div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}