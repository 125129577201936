import React, { useContext, useEffect, useState } from "react";
import "./home.css";
import { Link } from "react-router-dom";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { firestore } from "../../firebase";
import { UserContext } from "../../providers/UserProvider";
import FirebaseStorageImage from "../functions/FirebaseStorageImage";
import "../../index.scss";
import "./profil.css";
import {
  instagramIcon,
  youtubeIcon,
  tiktokIcon,
  facebookIcon,
  mailIcon,
  phoneIcon,
  websiteIcon,
} from "../../icons.jsx";
import { toast } from "react-toastify";
import FurtherInfo from "../widgets/tooltip";
import { linkCopiedToast } from "../widgets/toasts.tsx";
import phoneFrame from "../../graphics/phone_frame.png";
import LoaderDivWithOverlay, { hideLoader } from '../widgets/loaderdiv';
import { typeMap } from "../global_config/organizerTypeMap.jsx";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";


function MeinProfil (){
  const {organizerId} = useContext(UserContext);
  const [organizerBanner, setOrganizerBanner] = useState(null);
  const [organizerIcon, setOrganizerIcon] = useState(null);
  const [organizerName,setOrganizerName] = useState();
  const [organizerType,setOrganizerType] = useState("");
  const [organizerCity,setOrganizerCity] = useState("");
  const [organizerBeschreibung,setOrganizerBeschreibung] = useState("");
  const [organizerGenres, setOrganizerGenres] = useState([]);
  const [organizerWebsite,setOrganizerWebsite] = useState("");
  const [organizerTele,setOrganizerTele] = useState("");
  const [organizerEmail,setOrganizerEmail] = useState("");
  const [organizerAdresse,setOrganizerAdresse] = useState("");
  const [organizerInsta,setOrganizerInsta] = useState("");
  const [organizerResidents, setOrganizerResidents] = useState([]);
  const [organizerYoutube,setOrganizerYoutube] = useState("");
  const [organizerTiktok,setOrganizerTiktok] = useState("");
  const [organizerFacebook,setOrganizerFacebook] = useState("");
  const [organizerOpeningHours, setOrganizerOpeningHours] = useState([]);
  const [organizerLocation, setOrganizerLocation] = useState("");
  const [organizerLatitude, setOrganizerLatitude] = useState();
  const [organizerLongitude, setOrganizerLongitude] = useState();

  const { isLoaded: mapLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyCPOlehIKrDlk3mMLQfY3-fRVRsmNIMi5U',
  });
 
  useEffect(() => {
    // Fetch organizer details
    getDoc(doc(collection(firestore, 'organizers'), organizerId))
      .then(async (snapshot) => {
        const organizerData = snapshot.data();
        
        // Set organizer details
        setOrganizerBanner((organizerData.images?.length ?? 0) > 0 ? organizerData.images[0] : null);
        setOrganizerIcon(organizerData.icon);
        setOrganizerName(organizerData.name);
        setOrganizerType(typeMap[organizerData?.type]);
        setOrganizerCity(organizerData.city ?? ((organizerData.address?.split(', ')?.length ?? 0) > 2 ? organizerData.address.split(', ')[2] : ''));
        setOrganizerBeschreibung(organizerData.description);
        setOrganizerWebsite(organizerData.website);
        setOrganizerTele(organizerData.phone);
        setOrganizerEmail(organizerData.email);
        setOrganizerAdresse(organizerData.address);
        setOrganizerInsta(organizerData.instagram);
        setOrganizerYoutube(organizerData.youtube);
        setOrganizerTiktok(organizerData.tiktok);
        setOrganizerFacebook(organizerData.facebook);
        setOrganizerOpeningHours(organizerData.opening_hours);
        setOrganizerLocation(organizerData.location);
        setOrganizerLatitude(organizerData.location.latitude);
        setOrganizerLongitude(organizerData.location.longitude);
  
        // Fetch genres and set them
        const organizerGenres = organizerData?.genre ?? [];
        const genrePromises = organizerGenres.map((genre) => getDoc(doc(collection(firestore, "genres"), genre)));
        const genreDocs = await Promise.all(genrePromises);
        setOrganizerGenres(genreDocs.map((genreDoc) => genreDoc.data().de));
  
        // Fetch residents and set them
        const organizerResidents = organizerData?.residents ?? [];
        const residentPromises = organizerResidents.map((resident) => getDoc(doc(collection(firestore, "artists"), resident)));
        const residentDocs = await Promise.all(residentPromises);
        setOrganizerResidents(residentDocs.map((resident) => resident.data()?.name));
  
      })
      .catch((error) => console.log(error));
  }, [organizerId]);

  const [organizerFloors, setOrganizerFloors] = useState([]);
  const [activeFloorIndex, setActiveFloorIndex] = useState(0);

  useEffect(() => {
    // Fetch organizer details
    getDoc(doc(collection(firestore, 'organizers'), organizerId))
      .then(async (snapshot) => {
        const organizerData = snapshot.data();

        // Fetch floors and set them
        const areasSnapshot = await getDocs(collection(snapshot.ref, 'areas'));
        setOrganizerFloors(
          areasSnapshot.docs.map((e) => ({
            id: e.id,
            ...e.data(),
          }))
        );
      })
      .catch((error) => console.log(error));
  }, [organizerId]);

  const handleDotClick = (index) => {
    setActiveFloorIndex(index);
  };
  

  const successEditToast = () => toast.success("Dein Profil wurde aktualisiert!",{theme:"dark"})

  useEffect(() => {
    // Function to get query parameters
    const getQueryParams = () => {
        const queryParams = new URLSearchParams(window.location.search);
        return queryParams.get('showSuccessEditToast');
    };

    // Get the value of showToast parameter
    const showToastValue = getQueryParams();

    if (showToastValue === 'true') {
      successEditToast()
    }
  }, []);

  const handleLinkCopyClick = (e,id) => {
    e.stopPropagation();
    e.preventDefault();
    window.navigator.clipboard.writeText(`https://elgio.de/organizer/${organizerId}`)
  }

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isFullWidth = windowWidth < 768;

  function convertOpeningTimes(openingTimes) {
    const daysOfWeek = ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'];
    const readableTimes = {};

    // This will store groups of days with the same opening times
    const timeGroups = [];

    // Initialize variables to track current group of days with the same opening times
    let currentGroup = [];
    let currentOpenTime = null;

    for (let i = 0; i < openingTimes.length; i++) {
        const dayIndex = i % 7;
        const openingTime = openingTimes[dayIndex].open ? `${openingTimes[dayIndex].opening_time} - ${openingTimes[dayIndex].closing_time}` : 'geschlossen';

        if (currentOpenTime === null) {
            currentOpenTime = openingTime;
            currentGroup.push(daysOfWeek[dayIndex]);
        } else if (currentOpenTime === openingTime) {
            currentGroup.push(daysOfWeek[dayIndex]);
        } else {
            timeGroups.push({ days: currentGroup, time: currentOpenTime });
            currentOpenTime = openingTime;
            currentGroup = [daysOfWeek[dayIndex]];
        }
    }

    // Push the last group
    if (currentGroup.length > 0) {
        timeGroups.push({ days: currentGroup, time: currentOpenTime });
    }

    // Iterate through the time groups and construct the readableTimes object
    for (const group of timeGroups) {
        const days = group.days;
        const time = group.time;

        if (days.length === 1) {
            readableTimes[days[0]] = time;
        } else {
            const startDay = days[0];
            const endDay = days[days.length - 1];
            readableTimes[`${startDay} bis ${endDay}`] = time;
        }
    }

    // Ensure ordering from Monday to Sunday
    const orderedReadableTimes = {};
    daysOfWeek.forEach(day => {
        for (const key in readableTimes) {
            if (key.includes(day)) {
                orderedReadableTimes[key] = readableTimes[key];
            }
        }
    });

    return orderedReadableTimes;
  }

  return (
    <div className="content">
      <div id="breadcrumb-title-text">Profil</div>
      <div className="profil-header">
        <FirebaseStorageImage reference={organizerBanner}  id="eventImageProfil" alt="bgImageOrganizer"/>
        <FirebaseStorageImage reference={organizerIcon} className="ProfilIcon" alt="iconImageOrganizer"/>
        <div className="profilheader">
          <div className="VeranstalterTextProfil">
            <div className="VeranstalterName">{organizerName}</div>
            <div id="VeranstalterBezeichnung">{organizerType?.charAt(0)?.toUpperCase() + organizerType?.slice(1)}{organizerCity && ', '}{organizerCity?.charAt(0)?.toUpperCase() + organizerCity?.slice(1)}</div>
          </div>
          <div className="profilButtonBox">
          <div className="bg-grey Button" 
              style={{ width: isFullWidth ? "100%" : "revert-layer" }} 
              onClick={(e) => {handleLinkCopyClick(e,organizerId);linkCopiedToast()}}>
              Profillink kopieren
          </div>
          <Link to={"./bearbeiten"} className="bg-blue Button" style={{ width: isFullWidth ? "100%" : "revert-layer" }}> Profil bearbeiten </Link>
          </div>
        </div>
      </div>
      <div className="BottomContentBox">
        <div className="Profilbox">
          <div className="profilBackgroundBox" id="backgroundBox">
            <div className="col-flex gap-1">
              <div className="profilheaders">Beschreibung</div>
              <div className="t-secondary">{organizerBeschreibung}</div>
            </div>
            <div className="col-flex gap-1">
              <div className="profilheaders">Genres</div>
              {organizerGenres.length > 0 ? <div className="t-secondary">
                {organizerGenres.join(", ")}
              </div> : <div className="t-secondary" style={{fontStyle: "italic"}}> Du hast keine Genres angegeben</div>}
            </div>
            <div className="col-flex gap-1">
              <div className="profilheaders">Residents</div>
              {organizerResidents.length > 0 ? <div className="t-secondary">
                {organizerResidents.join(", ")}
              </div> : <div className="t-secondary" style={{fontStyle: "italic"}}> Du hast keine Residents angegeben</div>}
            </div>
          </div>
          <div className="profilContactBox">
            <div className="profilBackgroundBox" id="SocialMedia">
              <div className="profilheaders">Social Media</div>
              <div className="KontaktBoxFlex">
                {(organizerInsta != null &&
                  <a href={ organizerInsta.startsWith("https://") || organizerInsta.startsWith("www") ? organizerInsta : `https://instagram.com/${organizerInsta}`} target="_blank" rel="noopener noreferrer" className="SocialMediaLink" disabled>
                    <div className="InfoKasten" style={organizerInsta?.length === 0 ? {backgroundColor:"var(--bg4)"} : {backgroundColor:"var(--bg7)"}}>
                      <div className="KontaktDatenItem">
                        <div className="SocialMediaIcon">{instagramIcon}</div>
                        <div id="SocialMediaLinkText">{organizerInsta ?? "-"}</div>
                      </div>
                    </div>
                  </a>
                )}
                {(organizerYoutube != null &&
                  <a href={ organizerYoutube.startsWith("https://") || organizerYoutube.startsWith("www") ? organizerYoutube : `https://youtube.com/${organizerYoutube}`} target="_blank" rel="noopener noreferrer" className="SocialMediaLink" disabled>
                    <div className="InfoKasten" style={organizerInsta?.length === 0 ? {backgroundColor:"var(--bg4)"} : {backgroundColor:"var(--bg7)"}}>
                      <div className="KontaktDatenItem">
                        <div className="SocialMediaIcon">{youtubeIcon}</div>
                        <div id="SocialMediaLinkText">{organizerYoutube ?? "-"}</div>
                      </div>
                    </div>
                  </a>
                )}
                {(organizerTiktok != null &&
                  <a href={ organizerTiktok.startsWith("https://") || organizerTiktok.startsWith("www") ? organizerTiktok : `https://tiktok.com/@${organizerTiktok}`} target="_blank" rel="noopener noreferrer" className="SocialMediaLink" disabled>
                    <div className="InfoKasten" style={organizerTiktok?.length === 0 ? {backgroundColor:"var(--bg4)"} : {backgroundColor:"var(--bg7)"}}>
                      <div className="KontaktDatenItem">
                        <div className="SocialMediaIcon">{tiktokIcon}</div>
                        <div id="SocialMediaLinkText">{organizerTiktok ?? "-"}</div>
                      </div>
                    </div>
                  </a>
                )}
                {organizerFacebook != null && (
                  <a href={ organizerFacebook.startsWith("https://") || organizerFacebook.startsWith("www") ? organizerFacebook : `https://facebook.com/${organizerFacebook}`} target="_blank" rel="noopener noreferrer" className="SocialMediaLink" disabled>
                    <div className="InfoKasten" style={organizerFacebook?.length === 0 ? {backgroundColor:"var(--bg4)"} : {backgroundColor:"var(--bg7)"}}>
                      <div className="KontaktDatenItem">
                        <div className="SocialMediaIcon">{facebookIcon}</div>
                        <div id="SocialMediaLinkText">{organizerFacebook ?? "-"}</div>
                      </div>
                    </div>
                  </a>
                )}
              </div>
            </div>
            <div className="profilBackgroundBox" id="SocialMedia">
              <div className="profilheaders">Kontaktdaten</div>
              <div className="KontaktBoxFlex">
                {(organizerEmail != null &&
                  <div className="InfoKasten">
                    <div id="Email" className="KontaktDatenItem">
                      <div id="KontaktDatenIcon">{mailIcon}</div>
                      <div id="KontaktDatenItemText">{organizerEmail ?? "-"}</div>
                    </div>
                  </div>
                )}
                {(organizerTele != null &&
                  <div className="InfoKasten">
                    <div id="Telefonnummer" className="KontaktDatenItem">
                      <div id="KontaktDatenIcon">{phoneIcon}</div>
                      <div id="KontaktDatenItemText">{organizerTele ?? "-"}</div>
                    </div>
                  </div>
                )}
                {(organizerWebsite != null &&
                  <div className="InfoKasten">
                    <div id="Website" className="KontaktDatenItem">
                      <div id="KontaktDatenIcon">{websiteIcon}</div>
                      <div id="KontaktDatenItemText">{" "}{organizerWebsite ?? "-"}</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="profilContactBox">
            {(organizerAdresse != null && <div className="profilBackgroundBox" id="SocialMedia">
              <div className="profilheaders">Adresse</div>
              <div className="t-white">{organizerAdresse}</div>
              <div>
                {organizerLocation && mapLoaded && <GoogleMap
                    mapContainerClassName="eventAdresseMap"
                    center={{
                        lat: organizerLatitude,
                        lng: organizerLongitude,
                    }}
                    zoom={11}
                >
                    <Marker
                        position={{
                            lat: organizerLatitude,
                            lng: organizerLongitude,
                        }}
                    />
                </GoogleMap>}
              </div>
              <div className="t-white">
                {organizerOpeningHours ? (
                    <div>
                        {Object.entries(convertOpeningTimes(organizerOpeningHours)).map(([days, time]) => (
                            <div key={days}>
                                <span className="font-semibold">{days}:</span> {time}
                            </div>
                        ))}
                    </div>
                ) : null}
              </div>
            </div> )}
            {(organizerFloors != null && <div className="profilBackgroundBox" id="SocialMedia">
              <div className="row-flex align_center justify_space_between">
                <div className="profilheaders">Floors</div>
                <div className="dots-container">
                  {organizerFloors.map((_, index) => (
                    <span
                      key={index}
                      className={`dot ${index === activeFloorIndex ? 'active' : ''}`}
                      onClick={() => handleDotClick(index)}
                    ></span>
                  ))}
                </div>
              </div>
              {organizerFloors.length > 0 && (
                <div className="col-flex gap-1">
                  <FirebaseStorageImage
                    className="floorsImage"
                    reference={organizerFloors[activeFloorIndex].images[0]}
                  />
                  <div>
                    <div className="medium font-semibold t-white">{organizerFloors[activeFloorIndex].name}</div>
                    <div className="t-secondary">{organizerFloors[activeFloorIndex].description}</div>
                  </div>
                </div>
              )}
            </div>)}
          </div>
        </div>
        <div className="WebsiteVorschau">
          <div className='websiteVorschauBox'>
            <div className="profilheaders">Vorschau in der App</div>
            <FurtherInfo 
              explanation="Hier siehst du eine mobile Vorschau deines Profils. Manchmal dauert es bis zu 2min bis Änderungen hier sichtbar sind."
            />
          </div>
          <div className="previewContainer">
              <img src={phoneFrame} alt="phone_frame_for_preview" className='phoneFramePreview'/>
              <div className="iframeContainer" style={{width: "89%"}}>
                <LoaderDivWithOverlay id={"vorschau_organizer_iframe"}/>
                <iframe onLoad={() => hideLoader("vorschau_organizer_iframe")} title="Vorschau_Organizer" src={`https://elgio.de/organizer/${organizerId}?hide_navigation=true`} style={{ transformOrigin: '0% 0%', border: 'none', margin: 0, padding: 0, height: '100%', width: '100%' }} />
              </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { MeinProfil };