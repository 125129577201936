import React from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import './404.css'
import "../../../index.scss";
import { chevronRightIcon } from '../../../icons';

function NotFound () {
    useEffect(() => {
        document.getElementById('root').style.width="100vw"
        document.getElementById('root').style.height="100vh"
    }, [])

    return (
        <div className='NotFoundBox' style={ window.location.pathname.includes("/dashboard") ? {backgroundColor: "var(--bg3)"} : {backgroundColor:"unset"}}>
            <div className='errorBox404'>
                <div className="errorText404">Sorry, diese Seite gibt es nicht :/</div>
                <div className='errorTextSmall'>Für Nerds: 404 - Not Found</div>
            </div>
            <Link to={"/"} className='HomeLink'>
                <div>Home</div>
                <div style={{fontSize: "var(--fontsize-large)"}}>{chevronRightIcon}</div>
            </Link>
        </div>
    )
}

export default NotFound 