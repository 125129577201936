/* eslint-disable react-hooks/exhaustive-deps */
import ProgressBar from "@ramonak/react-progress-bar";
import { collection, doc, getDocs, onSnapshot, query, where } from "firebase/firestore";
import jsPDF from "jspdf";
import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import "../../../index.scss";
import { useState } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";
import { Link, useNavigate, useParams } from "react-router-dom";
import { firestore } from "../../../firebase";
import "./ticketoverview.css";
import {
  searchIcon,
  chevronLeftIcon,
  pdfIcon,
  excelIcon,
} from "../../../icons.jsx"



function Ticketoverview() {
  const [eventName, setEventName] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [ticketVerkauf, setTicketVerkauf] = useState(0);
  const [tickets,setTickets] = useState({})
  const [ticketTypes,setTicketTypes] = useState([])
  const [ticketScanners,setTicketScanners] = useState(null)
  const [rows, setRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [ticketanzahl,setTicketanzahl] = useState(0)
  const [ticketKaeufe,setTicketKaeufe] = useState(0)
  const [percentage,setPercentage] = useState(0)
  // eslint-disable-next-line no-unused-vars
  
  
  let navigate = useNavigate();

  const { id } = useParams();

  // this effect gets all the tickets for the event
  useEffect(() => {
    getDocs(
      query(
        collection(firestore,"tickets"),
        where("event", "==", id),
        where("type", "!=", "guestlist"),
        where("status", "in", ["redeemed", "unredeemed", "confirmed"]),
      )).then((tickets) => {
        setTickets(tickets.docs)
        setTicketKaeufe(tickets.docs.length)
      })
  },[])

  // this effect is for the stats
  useEffect(() => {
    getDocs(collection(firestore,"events",id,"tickets"))
        .then((ticketTypesEventSnap) => {
          setTicketTypes(ticketTypesEventSnap.docs);

          const anzahl = ticketTypesEventSnap.docs.reduce((prev, curr) => prev + curr.data()?.total_tickets??0, 0)
          setTicketanzahl(anzahl)
        })
  },[id])

  useEffect(() => {
    setTicketVerkauf(ticketKaeufe / ticketanzahl * 100);
  }, [ticketKaeufe, ticketanzahl]);
  
  // this effect sets the ticketScanners array, which includes all ticket scanners registered for this event
  useEffect(() => { getDocs(query(
    collection(firestore,"users"),
    where("event", "==", id)
  )).then((tickets) => {
    setTicketScanners(tickets.docs)
  }) }, [id])
  
  // this effect renders the rows which are going to be displayed in the table
  useEffect(() => {
    if(!tickets) return;
    const res = [];
    for (let i = 0; i < tickets.length; i++) {
      let ticketType = ticketTypes?.find(e => e.id === tickets[i].data().type)?.data().name;
      let scanner = ticketScanners?.find(e => e.id === tickets[i].data().redeemed_by)?.data().name;
      if(
        searchQuery
        && !tickets[i].data().code?.toLowerCase().includes(searchQuery.toLocaleLowerCase())
        && !ticketType?.toLowerCase().includes(searchQuery.toLocaleLowerCase())
        && !scanner?.toLowerCase().includes(searchQuery.toLocaleLowerCase())
        && !(tickets[i].data().first_name?.toLowerCase() + ' ' + tickets[i].data().last_name?.toLowerCase()).includes(searchQuery.toLocaleLowerCase())
        && !(tickets[i].data().last_name?.toLowerCase() + ' ' + tickets[i].data().first_name?.toLowerCase()).includes(searchQuery.toLocaleLowerCase())
      ) continue;
      res.push({
        code: tickets[i].data().code,
        ticketType,
        status: tickets[i].data().status,
        date: tickets[i].data().redemption_timestamp?.toDate().toLocaleTimeString("de-DE", {weekday: 'short', day: '2-digit', month: '2-digit', year: 'numeric'}),
        scanner,
        firstName: tickets[i].data().first_name,
        lastName: tickets[i].data().last_name,
      });
    }
    setRows(res);
  }, [tickets, ticketTypes, ticketScanners, searchQuery])

  const tableRef = useRef(null)



  useEffect(() => {
    onSnapshot(doc(firestore, "events", id), (event) => {
      setEventName(event.data().name);
    });
  }, [id]);

  // eslint-disable-next-line no-unused-vars
  const [ticketingOnline, setTicketingOnline] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: eventName + "Ticketoverview_" + new Date().toLocaleDateString(),
    sheet: "Event",
  });

// eslint-disable-next-line no-unused-vars
function generatePDF(id){
    const table = document.getElementById(id)
    document.getElementById(id).style.color="black"
    const doc = new jsPDF('landscape','pt',["1500","900"])
    doc.setTextColor("black")
    doc.setFontSize(3)
    doc.html(table,{
      callback: function (doc){
        doc.save(eventName + "_Ticketoverview.pdf")
        document.getElementById(id).style.color="white"
      },
      x:0,
      y:60,
    })  
      } 
      useEffect(() => {
        let percentage = (ticketKaeufe * 100) / ticketanzahl
        setPercentage(
          Math.round(percentage)
        )
      },[ticketKaeufe,ticketanzahl])
    
  return (
    <div className="content">
      <div id="breadcrumb-title-text" style={{display:"flex",gap:".4rem"}}>
        <div style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
          {chevronLeftIcon}
        </div>
         <Link to ={"/dashboard/events"} style={{textDecoration:"none",color:"var(--lightgrey)"}}>
          Events 
         </Link >
         /
         <Link to={"/dashboard/events/event/" + id} style={{textDecoration:"none",color:"var(--lightgrey)"}}>
         {eventName} 
         </Link>
         / Ticketübersicht
      </div> 
      <div className="event-ticketoverview-header">
        <div className="row justify_space_between">
          <div className="ticketoverview-first">
              <div className="event-ticketoverview-header-title">Ticketübersicht</div>
              <div className="event-name-col">für das Event: {eventName}</div>
          </div>
          <div className={ticketingOnline ? "status-online-btn" : "status-offline-btn"}>
            {ticketingOnline ? "Online" : "Offline"}
          </div>
        </div>
        <div className="ticketoverview-second">
          <div className="ticketoverview-progress">
            <div className="ticketbestand-title">Ticketbestand</div>
            <ProgressBar
              completed={percentage}
              className="progressBar-ticket-overview"
              labelColor="white"
              baseBgColor="var(--lightgrey)"
              bgColor="var(--blue)"
              height="30px"
              customLabel=""
              animateOnRender="true"
              maxCompleted={100}
              borderRadius="5px"
              isLabelVisible={false}
            ></ProgressBar>
            <div className="ticketbestand-text">
              {ticketKaeufe + " "} / {ticketanzahl + " "}{" "}
              {"(" + percentage + "%)"}
            </div>
          </div>
        </div>
      </div>
      <div className="midContent">
          <div className='leftSideMid'>
            <input
              type="text"
              className="input-suchfeld"
              id="suche-tickets"
              placeholder="z.B. Name"
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
            ></input>
            <div className="searchIconGuest" onClick={() => {document.getElementById("suche-tickets")?.click();}}>{searchIcon}</div>
          </div>
        {/* <div className="exportBox">
          <div className="col1-inRow" style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
            <div onClick={() => {generatePDF('html-table')}} className="circleSmall PDF">
              <div>{pdfIcon}</div>
            </div>
          </div>
          <div className="col2-inRow" style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
            <div className="circleSmall EXCEL" onClick={() => {onDownload();}}>
              <div>{excelIcon}</div>
            </div>
          </div>
        </div> */}
      </div>
      <div className="table-tickets">
        <table id="html-table" className="table-ticket-overview" cellPadding={"10%"} ref={tableRef}>
          <thead>
            <tr>
              <th className="table-ticket-box-small">Ticket-ID</th>
              <th className="table-ticket-box">Vorname</th>
              <th className="table-ticket-box">Nachname</th>
              <th className="table-ticket-box">Tickettyp</th>
              <th className="table-ticket-box">Eingecheckt</th>
              <th className="table-ticket-box">Checked - In</th>
              <th className="table-ticket-box" style={{borderRight: "0px"}}>Eingecheckt von</th>
            </tr>
          </thead>
          <tbody>
          {rows.length !== 0  ? 
              rows.map(row => {
                return(
                    <tr>
                      <td className="table-ticket-box-small">{row.code ?? 'N/a'}</td>
                      <td className="table-ticket-box">{row.firstName ?? "N/a"}</td>
                      <td className="table-ticket-box">{row.lastName ?? "N/a"}</td>
                      <td className="table-ticket-box">{row.ticketType ?? "N/a"}</td>
                      <td className="table-ticket-box">
                          <div className="ticketStatus" style={row.status === "redeemed" ? {backgroundColor:"var(--green)"} : row.status === "unredeemed" ? {backgroundColor:"yellow",color:"black"} :  {backgroundColor:"var(--red)"}}>
                          {row.status === "redeemed" ? "Ja" : row.status === "unredeemed" ? "Ausgecheckt" : "Nein" ?? "N/a"}
                          </div>
                      </td>
                      <td className="table-ticket-box">{row.date ?? "-"}</td>
                      <td className="table-ticket-box" style={{borderRight:"none"}}> {row.scanner ?? "-"}</td>
                    </tr>
                );
              }) : 
            <>
                <tr style={{display:"flex",justifyContent:"center", marginTop:"20px", fontSize:"var(--fontsize-medium)"}}>
                <td style={{border:"none"}}>Es wurden noch keine Tickets verkauft!</td>
                </tr>
            </>
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Ticketoverview;