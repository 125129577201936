import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import UserProvider from "./providers/UserProvider";
import App from "./App";
import { LoaderOffline } from "./loader";
import OrganizerProvider from "./providers/OrganizerProvider.jsx";
import StatsProvider from "./providers/StatsProvider.jsx";
import { ToastContainer } from "react-toastify";
import removeNaNs from "./components/functions/removeAllNaN";
import { BrowserRouter as Router } from "react-router-dom";
import ThemeProvider from "./providers/ThemeProvider";
// import URLChangeDetector from "./components/functions/detectURLchange.jsx";


let online = navigator.onLine;
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider>
    <Router>
    {/* <URLChangeDetector></URLChangeDetector> */}
    {online ? (
      <UserProvider>
        <OrganizerProvider>
          <StatsProvider>
            <App></App>
          </StatsProvider>
        </OrganizerProvider>
      </UserProvider>
    ) : (
      <LoaderOffline></LoaderOffline>
    )}
    </Router>
    <ToastContainer></ToastContainer>
    </ThemeProvider>
);

removeNaNs();
