import { collection, doc, getDoc } from "firebase/firestore";
import { useContext, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { firestore } from "../../firebase";
import { chevronRightIcon, helpIcon, mailIcon, menuIcon, moonIcon, phoneIcon, settingsIcon, sunIcon } from "../../icons";
import { ThemeContext } from "../../providers/ThemeProvider";
import { UserContext } from "../../providers/UserProvider";
import ModalSettings from "../pages/settings";
import "./topBar.css";
// import HandleClickOutside from "../functions/handleClickOutside";

export default function TopBar(props) {
    const { organizerId } = useContext(UserContext);
    const [organizerName, setOrganizerName] = useState('');
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [helpCenterVisible, setHelpCenterVisible] = useState(false);
    const { theme, setTheme } = useContext(ThemeContext);

    const menuRef = useRef(null)
      const toggleHelpCenter = () => {
          setHelpCenterVisible(!helpCenterVisible);
      };

    //search param stuff
    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => { searchParams.get('show_modal') === 'settings' && setShow(true) }, [searchParams]);
    useEffect(() => {
      if(show || searchParams.get('show_modal') !== 'settings')
        return;
      searchParams.delete('show_modal');
      setSearchParams(searchParams);
    }, [searchParams, setSearchParams, show]);
  
    useEffect(() => {
      getDoc(doc(collection(firestore, "organizers"), organizerId))
          .then((snapshot) => {
              const data = snapshot.data();
              if (data) {
                  setOrganizerName(data.name);
              }
          })
          .catch((error) => console.log(error));
  }, [organizerId]);
  
    document.getElementById("root").style.backgroundImage = "none";
    document.body.style.backgroundImage = "none";
    document.body.style.backgroundColor = "var(--darkgrey)";
  
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setHelpCenterVisible(false);
      }
    };
  
    useEffect(() => {
      if(helpCenterVisible){
        document.addEventListener("mousedown", handleClickOutside);
      }
      else{
        document.removeEventListener("mousedown", handleClickOutside);
      }
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [helpCenterVisible]);

    const handleShowMenu = () => {
      const sideNav = document.getElementsByClassName("sideNav")[0];  
      if (sideNav.style.display === "flex") {
          props.setShowSideNav(false);
          sideNav.style.display = "none";
      } else {
          props.setShowSideNav(true);
          sideNav.style.display = "flex";
      }
    };



    return (
      <>
        <div className="TopBarBox">
          <div className="row-flex flex-gap-1rem align_center">
            <div className="SettingsIcon IconSearch el-visible-small" id="showMenuIcon" onClick={handleShowMenu}>
              {menuIcon}
            </div>
            <div className="organizerNameTop">{organizerName}</div>
          </div>
          <div className="TopBarRight">
            <div className="SettingsIcon IconSearch" onClick={() => setTheme(prev => prev === 'light' ? 'dark' : 'light')}>
              <div id="SettingIcon">{theme==="light" ? moonIcon : sunIcon}</div>
            </div>
            <div ref={menuRef}>
              <div className="SettingsIcon IconSearch" onClick={toggleHelpCenter}>
                <div id="HelpCenterIcon">{helpIcon}</div>
              </div>
              {helpCenterVisible && (
                <div className="helpBox">
                    <div className="helpBoxTitle">Du hast Fragen?</div>
                    
                    <div className="helpSection">
                      <div className="helpBoxSectionTitle">Eine schnelle Antwort findest du hier</div>
                      <a href={"https://www.elgio.de/faq"} target="_blank" rel="noopener noreferrer" id="faqLink" disabled>
                        <div className="faqBox">FAQ {chevronRightIcon}</div>
                      </a>
                    </div>
                    <div className="helpSection">
                      <div className="helpBoxSectionTitle">Kontakt</div>
                      <div className="col-flex" style={{gap: "5px"}}>
                        <a href="mailto:niclas.reif@elgio.de">
                          <div className="helpSectionBox">
                            {mailIcon} niclas.reif@elgio.de
                          </div>
                        </a>
                        <div className="helpSectionBox">{phoneIcon} 0151 19488634</div>
                      </div>
                    </div>
                </div>
              )}
            </div>
            <div className="SettingsIcon IconSearch" onClick={handleShow}>
              <div id="SettingIcon">{settingsIcon}</div>
            </div>
          </div>
        </div>
        <ModalSettings show={show} handleClose={handleClose} />
      </>
    );
  }