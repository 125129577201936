import { useState } from "react";
import { infoIcon } from "../../icons";
import "../../index.scss";

export default function FurtherInfo({explanation}){
    const [isVisible, setIsVisible] = useState(false);

    return ( 
        <div className="tooltipHidden">
            {isVisible && (
                <div className="tooltipStatistics">
                    <div className="tooltipContainer">{explanation}</div>
                </div>
            )}
            <div
                className="InfoIcon"
                onMouseLeave={() => setIsVisible(false)}
                onMouseEnter={() => setIsVisible(true)}
            >
                {infoIcon}
            </div>
        </div>
    );
}