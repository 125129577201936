import { deleteIcon, repeatIcon, instagramIcon, facebookIcon} from "../../icons.jsx";
import React from "react";
import FirebaseStorageImage from "../functions/FirebaseStorageImage"
import "./postbox.css"
import "../../index.scss";


export default function PostRow(props){

    return( 
        <div className="postSolo">
            <div className="leftSectionPost">
                <div className="picturePost">
                    <FirebaseStorageImage reference={props.banner} resolution={200} style={{objectFit: "cover", borderRadius: "5px"}} onClick={() => {props.setModalPic(props.banner)}}></FirebaseStorageImage>
                </div>
                <div className="middleSectionPost">
                    <div className="row-flex" style={{color: "var(--textMain", gap: "5px"}}>
                        <div>Status:</div>
                        <div style={props.status === "scheduled" ? {color: "var(--blue)"} : props.status === "posting" ? {color: "var(--orange)"} : props.status === "posted" ? {color: "var(--green)"} : {color: "var(--red)"}}>{props.status === "scheduled" ? "Angekündigt" : props.status === "posting" ? "Wird geposted" : props.status === "posted" ? "Gepostet" : "Fehler"}</div>
                    </div>                            
                    <div className="postingDate">{props.post_at}</div>
                    <div className="captionPost">{props.caption}</div>
                </div>
            </div>
            <div className="rightSectionPost" style={props.post_at_raw < new Date() ? {justifyContent:"space-between"} : {}}>
            {props.post_at_raw > new Date() ? <div className="actionsPost">
                <div className="bearbeitenPost" onClick={() => {props.setDataEdit(props.wholeDoc);props.setIdEdit(props.id);props.setShow(true)}}>Bearbeiten</div>
                <div className="deletePost pointer"  onClick={() => {props.deletePost(props.id)}}>{deleteIcon}</div>
            </div> : <></>}
            <div className="postType">{props.type === 'event' ? 'Eventpost' : props.type === 'overview_week' ? 'Wochenpost' : 'Monatspost'}</div>
                {props.repeat && <div className="repeatStatus">
                {repeatIcon}
                {props.repeat && `${(() => {
                    switch(props.repeat) {
                    case 'daily': return 'tägliche';
                    case 'weekly': return 'wöchentliche';
                    case 'monthly': return 'monatliche';
                    default: return '';
                }
                })()} Wiederholung`}</div> }
                <div className="postPlattform" style={(props.instagram_post || props.instagram_story) && (props.facebook_post || props.facebook_story) ? {gap:"10px"} : {gap:"0"}}>
                    <div style={{display: "flex", flexDirection: "row", gap: "10px", alignItems: "center"}}>
                        <div>{props.instagram_post || props.instagram_story ? instagramIcon : ""}</div>
                        <div className="postTypeExact">{props.instagram_post && props.instagram_story ? "1x Post, 1x Story" : props.instagram_post ? "1x Post" : props.instagram_story ? "1x Story" : ""}</div>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", gap: "10px", alignItems: "center"}}>
                        <div>{props.facebook_post || props.facebook_story ? facebookIcon :  ""}</div>
                        <div className="postTypeExact">{props.facebook_post && props.facebook_story ? "1x Post, 1x Story" : props.facebook_post ? "1x Post" : props.facebook_story ? "1x Story" :  ""}</div>
                    </div>
                </div>
            </div>        
        </div>
    )
}