import { useState } from "react";
import "../../../index.scss";
import SubscribeModal from "../subscriptions/subscribe_modal";
import "./probenefits.css";
import NoGraphic3 from "../../../graphics/Marketing_NonPro_3.png";
import NoGraphic2 from "../../../graphics/Marketing_NonPro_2.png";
import NoGraphic1 from "../../../graphics/Marketing_NonPro_1.png";

export default function ProBenefits() {
    const [showSubscribeModal, setShowSubscribeModal] = useState(false);

    return <div className="content" id="proBenefits">
        <SubscribeModal show={showSubscribeModal} setShow={setShowSubscribeModal} />
        <div className="mainBox">
            <div className="TitleArea">
                <div className="Title">Automatisches Marketing</div>
                <div className="Subtitle">Generiere und poste automatisch Social Media Posts von deinen Events</div>
            </div>
            <div className="previewArea">
                <div className="previewStep">
                    <div className="previewGraphic">
                        <img src={NoGraphic1} className="graphic" alt="Angaben machen grafik"/>
                    </div>
                    <div className="previewText">
                        <div className="previewTitle">1. Angaben machen</div>
                        <div className="previewSubtitle">Passe Informationen wie Postart, Format, Caption, Plattform oder Sonstiges an.</div>
                    </div>
                </div>
                <div className="previewStep">
                    <div className="previewGraphic">
                        <img src={NoGraphic3} className="graphic" alt="design wählen grafik"/>
                    </div>
                    <div className="previewText">
                        <div className="previewTitle">2. Design auswählen</div>
                        <div className="previewSubtitle">Wähle von über 20 verschiedenen Layouts und passe nach Belieben noch Aspekte an.</div>
                    </div>
                </div>
                <div className="previewStep">
                    <div className="previewGraphic">
                        <img src={NoGraphic2} className="graphic" alt="posten lassen grafik"/>
                    </div>
                    <div className="previewText">
                        <div className="previewTitle">3. Posten lassen</div>
                        <div className="previewSubtitle">Der Post wird automatisch am Zeitpunkt deiner Wahl, auf Social Media gepostet.</div>
                    </div>
                </div>
            </div>
            <div className="buttonArea">
                <div className="trialButton" onClick={() => setShowSubscribeModal(true)}>1 Monat kostenlos testen</div>
                <div className="additionalInfo">Danach 19,90€ pro Monat. Jederzeit kündbar. Zusätzlich 1x eigenes Layout nach Wünschen.</div>
            </div>
       </div>
    </div>;
}