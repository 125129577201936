import { useEffect, useMemo, useRef, useState } from "react";
import { ref, getDownloadURL } from 'firebase/storage';
import { storage } from '../../firebase';
import useOnScreen from "./useOnScreen";
import placeholder from '../../graphics/placeholder_image.png';

export default function FirebaseStorageImage({ reference, alt=placeholder, ...rest }) {
    const possibleResolutions = useMemo(() => [32, 64, 128, 256, 512, 1024], []);
    const [resolution, setResolution] = useState(32);

    const imageRef = useRef(null);
    const isVisible = useOnScreen(imageRef);

    useEffect(() => {
        if(!imageRef.current) return;

        const handleResize = () => {
            const minResolution = Math.max(imageRef.current.clientWidth, imageRef.current.clientHeight) * 1.5;
            for(const res of possibleResolutions) {
                if(res > minResolution) {
                    setResolution(res);
                    return;
                }
            }
            setResolution(null);
        };
        
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [imageRef, possibleResolutions]);

    const [url, setUrl] = useState('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdjUFNT+w8AAloBcv9Z6WMAAAAASUVORK5CYII=');

    let updatedReference = reference;
    try {
        let suffix;
        if(resolution) {
            suffix = `_${resolution}x${resolution}`;
        }

        const splitReference = reference?.split('.')??[reference];
        if(suffix) {
            updatedReference = splitReference.length === 1 ? reference + suffix : splitReference.slice(0, -1).join('.') + suffix + '.webp';
        }
    } catch(e) {
        console.error(e);
    }

    useEffect(() => { isVisible && updatedReference && getDownloadURL(ref(storage, updatedReference)).then(url => setUrl(url)).catch(e => setUrl(alt)) }, [isVisible, updatedReference, alt]);

    return <img {...rest} ref={imageRef} src={url} alt={resolution} />;
}