import "./ticketing.css";
import "../../../index.scss";
import "./ticketing_anlegen.css";
import { useContext, useEffect } from "react";
import {
  cancelIcon,
  variantsIcon,
  paymentsIcon,
  plusIcon,
  chevronLeftIcon,
  chevronDownIcon,
  copyIcon,
  finishIcon,
  playIcon,
  clockIcon,
  numberIcon,
  closedEyeIcon,
  infoIcon,
  bankIcon,
} from "../../../icons.jsx";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { setDoc, collection, doc, getDoc, updateDoc,getDocs, Timestamp, onSnapshot } from "firebase/firestore";
import { firestore, functions } from "../../../firebase";
import { UserContext } from "../../../providers/UserProvider";
import Paypalpic from "../../../graphics/paypal2.jpeg";
import Mastercardpic from "../../../graphics/mastercard.png";
import Klarnapic from "../../../graphics/klarna_logo.png";
import Applepic from "../../../graphics/applepay4.png";
import Googlepic from "../../../graphics/googlepay2.jpeg";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import OrganizerName from "../../widgets/OrganizerName";
import FirebaseStorageImage from "../../functions/FirebaseStorageImage";
import { httpsCallable } from "firebase/functions";
import  {
  change_color,
  rechange_color,
} from "../login_signup/account_erstellen_functions";
import { toast } from "react-toastify";
import Modal from 'react-modal'
import { OrganizerContext } from "../../../providers/OrganizerProvider.jsx";
import BankInput from "../../widgets/BankInput.jsx";

// Ticketing Anlegen Modal imbedded start
export function ModalTicketing({edit=false,...props}){
  const el = Modal.setAppElement("#portal");
  const [EventLocation, setEventLocation] = useState("-");
  const [start,setStart] = useState()

  useEffect(() => {
    if(props.venue){
      getDoc(doc(collection(firestore, 'organizers'), props.venue)).then( (doc) => {
        setEventLocation(doc.data().name + " - " +  doc.data().address);
      });
    }
    else if(props.location){
      setEventLocation(props.location);
    }
    props.startDate != null && props.startTime != null ? 
    setStart(props.startDate ? (props.startDate instanceof Date  ? props.startDate?.toLocaleDateString("de-DE") : props.startDate )+ " ab " + props.startTime : "-")
    : setStart("-")
  },[props.venue, props.startDate, props.startTime, props.location])
  
  return(

  
  <Modal
      isOpen={props.showModal}
      appElement={el}
      unselectable="true"
      autoFocus={false}
      className="modalContentNormal"
      overlayClassName="modalOverlayNormal"
      shouldFocusAfterRender={false}
      shouldCloseOnOverlayClick={true}
      onRequestClose={()=>{props.setShowModal(false)}}
  >
    <TicketingAnlegen 
      setData={props.setData}
      setShowSuccess={props.setShowSuccess??0}
      setShowModal={props.setShowModal}
      eventName={props.eventName} 
      start={start} 
      eventLocation={EventLocation}
      setTicketingDone={props.setTicketingDone}
      setLowestPrice={props.setLowestPrice}
      eventID={props.eventID}
      edit={edit}
      ></TicketingAnlegen>
  </Modal>)
}

export default function TicketingAnlegen({edit=false,...props}) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();  
  const [variants, setVariants] = useState([]);
  const [paymentAccountStatus, setPaymentAccountStatus] = useState('inactive');
  const { organizerId } = useContext(UserContext);
  const [hasStripeAccount,setHasStripeAccount] = useState(false);

  useEffect(() => {
    getDoc(doc(collection(firestore,"organizers"),organizerId)).then((organizer) => {
        setHasStripeAccount(organizer.data().payment_account.length !== 0)
    })
  },[organizerId])
  const { id } = useParams()

  const emptyVariant = (index) => ({
    id: `ticket-${index}`,
    max_tickets_per_purchase: 10,
  });

  const [event, setEvent] = useState(null);
  useEffect(() => { (props.eventID??id) && getDoc(doc(collection(firestore,"events"),props.eventID??id)).then((snapshot) => setEvent(snapshot)) }, [id, props.eventID])

  const [loadingOnboarding, setLoadingOnboarding] = useState(false);
  const activatePaymentProvider = () => {
    setLoadingOnboarding(true);

    const url = new URL(window.location.href);
    url.searchParams.append('ticketing_data', JSON.stringify(variants));

    const onboard = httpsCallable(functions, "payments-onboard");
    onboard({
      paymentProvider: 'stripe',
      returnUrl: url.toString(),
      refreshUrl: url.toString(),
    })
      .then((res) => window.location.replace(res.data.onboardingUrl))
      .catch((error) => console.log(error.code));
  };

  // Fetch Payment Account Status
  useEffect(() => { onSnapshot(doc(collection(firestore, 'organizers'), organizerId), doc => setPaymentAccountStatus(doc.data().payment_account_status??'inactive')) }, [organizerId]);

  useEffect(() => {
    if(searchParams.has('ticketing_data')) {
      setVariants(JSON.parse(searchParams.get('ticketing_data')));
    } else if(edit){
      getDocs(collection(firestore,"events", props.eventID,"tickets")).then((snap) => {
        const docs = snap.docs;
        docs.sort((a,b) => (a.data().order??0) - (b.data().order??0));
        setVariants ( docs.map(e => ({
          ...e.data(),
          id: e.id,
          price: (e.data().price / 100).toFixed(2).replace('.', ','),
          quantity: e.data().total_tickets,
          existing: true,
          sale_start: e.data().sale_start instanceof Timestamp ? e.data().sale_start.toDate() : e.data().sale_start,
          sale_end: e.data().sale_end instanceof Timestamp ? e.data().sale_end.toDate() : e.data().sale_end,
          admission_start: e.data().admission_start instanceof Timestamp ? e.data().admission_start.toDate() : e.data().admission_start,
          admission_end: e.data().admission_end instanceof Timestamp ? e.data().admission_end.toDate() : e.data().admission_end,
        })))
      })
    } else {
      setVariants([ emptyVariant(0) ]);
    }
  },[edit, props.eventID, searchParams])

  const addVariant = () => {
    setVariants([...variants, emptyVariant(variants.length)]);
  };

  function removeVariant(id){
    let tmp = variants;
    tmp.splice(id, 1);
    setVariants(Array.from(tmp));
  }

  const inputsValid = () => {
    if(paymentAccountStatus === 'inactive') return false; //TODO: allow free tickets in future w/o payment account
    if(variants.length <= 0) return false;
    if(variants.some(e => !parseFloat(e.price?.replace(',', '.')))) return false;
    if(variants.some(e => !e.quantity)) return false;
    if(variants.some(e => !e.name)) return false;
    if(!hasStripeAccount) return false;
    return true;
  }
  let lowestPrice;

  // const noStripeAcccountToast = () => toast.warning("Du hast noch kein Stripe Konto hinzugefügt!",{theme:"dark"})

  // Submit function
  const submit = () => {
    const confirmed = window.confirm("Bist du dir sicher, dass du Ticketing mit den Angaben speichern willst?");
    if(!confirmed)
      return;
    for (let index = 0; index < variants.length; index++) {
      let variantPrice = parseFloat(variants[index].price?.replace(',', '.')) * 100;

      if(index === 0 || variantPrice < lowestPrice){
        lowestPrice = variantPrice;
      }

      if(variants[index].existing)
        updateDoc(doc(collection(firestore,"events"),props.eventID??id,"tickets",variants[index].id),{
          total_tickets: parseInt(variants[index].quantity),
          sale_start: variants[index].sale_start??null,
          sale_end: variants[index].sale_end??null,
          max_tickets_per_purchase: variants[index].max_tickets_per_purchase??null,
          order: index,
        }).then(() => {console.log("success")})
      else
        setDoc(
          doc(collection(doc(collection(firestore, "events"), props.eventID??id), "tickets"), variants[index].id),
          {
            name: variants[index].name,
            available_tickets: parseInt(variants[index].quantity),
            total_tickets: parseInt(variants[index].quantity),
            price: variantPrice,
            seller: organizerId,
            sale_start: variants[index].sale_start??null,
            sale_end: variants[index].sale_end??null,
            admission_start: variants[index].admission_start??null,
            admission_end: variants[index].admission_end??null,
            max_tickets_per_purchase: variants[index].max_tickets_per_purchase??null,
            hidden: variants[index].hidden??false,
            order: index,
          }
        )
      
    }
    updateDoc(doc(collection(firestore,"events"),props.eventID??id), {
      ticket: variants.every(e => e.hidden) ? 'hidden' : 'standard',
      has_invoice:false
    });

    props.setLowestPrice && props.setLowestPrice(lowestPrice);
    props.setTicketingDone && props.setTicketingDone(true);
    if(props.setShowModal){
      props.setShowModal(false);
      if(props.setShowSuccess !== 0) props.setShowSuccess(true);
    }
    else
      navigate(`/dashboard/events/event/${props.eventID??id}`);
  }
  // Submit function end

  const { hasPro } = useContext(OrganizerContext);

  const grossToNetPrice = (price) => {
    if (price===0) return 0;
    const fixed = price > 15 ? hasPro ? .40 : .50 : .99;
    const percentage = price > 15 ? hasPro ? .04 : .05 : 0;
    return price * (1 - percentage) - fixed;
  };


  const commissionfee = (price) => {
    if (price===0) return 0;
    const fixed = price > 15 ? hasPro ? .40 : .50 : .99;
    const percentage = price > 15 ? hasPro ? .04 : .05 : 0;
    return ((percentage * price) + fixed);
  };

  useEffect(() => {
    let isValidTicketCategories = variants.every(variant => variant.name && variant.price && variant.quantity);
    let isValidPaymentMethods = ['active', 'bank_account_required'].includes(paymentAccountStatus);
      
      if (isValidTicketCategories) {
        change_color('ticket-categories-icon', 'ticket-categories-icon-nav');
      } else {
        rechange_color('ticket-categories-icon', 'ticket-categories-icon-nav');
      }
      
      if (isValidPaymentMethods) {
        change_color('payment-methods-icon', 'payment-methods-icon-nav');
      } else {
        rechange_color('payment-methods-icon', 'payment-methods-icon-nav');
      }
    
  }, [variants, paymentAccountStatus]);
  
  const hasNoStripeAccountToast = () => toast.info(
    "Es fehlen uns noch ein paar Angaben von dir.\nKlicke hier um diese zu vervollständigen",{
      onClick: activatePaymentProvider,
      bodyClassName:"pointer",
    })

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isFullWidth = windowWidth < 768;

  const [ticketingOverviewOpen, setTicketingOverviewOpen] = useState(false);

  const [organizer, setOrganizer] = useState(null);
  useEffect(() => {
    getDoc(doc(collection(firestore, 'organizers'), organizerId))
          .then(doc => setOrganizer(doc));
  }, [organizerId]);

  const [paymentsDoc, setPaymentsDoc] = useState(null);
  useEffect(() => {
      onSnapshot(doc(collection(doc(collection(firestore, 'organizers'), organizerId), 'private'), 'payments'), setPaymentsDoc)
  }, [organizerId]);

  useEffect(() => {
    if(paymentsDoc?.data()?.bank_account?.id) {
      change_color('bankaccount-icon', 'bankaccount-icon-nav');
    } else {
      rechange_color('bankaccount-icon', 'bankaccount-icon-nav');
    }
  }, [paymentsDoc]);

  return (
      <div className="ticketing-content w-100 h-100">
        <div className="ticketing-header">
          <div className="header-title-col">
            <div className="header-title">{edit ? "Ticketing bearbeiten" : "Ticketing anlegen"}</div>
            <div className="header-subtitle">
              Bei einem Ticketpreis bis einschließlich 15€ ist die Kommission 0,99€.<br />
              Ab einem Ticketpreis von 15€ ist die Kommission 0,50€ + 5% für Basic und 0,40€ + 4% für Premium Kunden.
            </div>
            <div className="cancelMobile" onClick={() =>{if(props.setShowModal) props.setShowModal(false); else navigate(`/dashboard/events/event/${props.eventID??id}`);}}>{cancelIcon}</div>
          </div>
          <div className="header-right" style={isFullWidth ? {display: "none"} : {display: "flex"}}>
            <div className="header-btns">

              <button className="abbruch-btn" onClick={() =>{if(props.setShowModal) props.setShowModal(false); else navigate(`/dashboard/events/event/${props.eventID??id}`);}}>
                Abbrechen
              </button>

              <button className={'bestätigen-btn' + (inputsValid() ? '' : ' disabled')} onClick={() => {
                if(!hasStripeAccount){
                  hasNoStripeAccountToast()
                }
                else{
                  if(!inputsValid()) return;
                  submit()
                }
              }}>
                {edit ? "Änderungen Speichern" : "Bestätigen"}
              </button>

            </div>
            <div className="header-disclaimer">Durch das Klicken auf <span>{edit ? "Änderungen Speichern" : "Bestätigen"}</span> stimmst du den&nbsp;
              <a href={"https://www.elgio.de/terms-of-service-dashboard"} target="_blank" rel="noopener noreferrer" id="AgbLink" disabled> AGBs</a>
              &nbsp;von ELGIO zu.
            </div>
          </div>
          <div className="ticketoverview-mobile">
            <div className="justify_space_between row">
              <div className="ticket-overview-title">Deine Ticketübersicht</div>
              <div className="ticket-overview-title" onClick={() => setTicketingOverviewOpen(e => !e)}>{ticketingOverviewOpen ? chevronDownIcon : chevronLeftIcon}</div>
            </div>
            {ticketingOverviewOpen && <>
              <div className="ticket-overview-subheader" style={{alignItems:"center",maxHeight:"70px"}}>
                  <FirebaseStorageImage reference={(event?.data().images.length??0) > 0 && event.data().images[0]} className="eventImageMini" />
                <div className="subheader-text">
                  <div className="next-event-name-ticketOverview">{event?.data().name??'-'}</div>
                  <div style={{color:"var(--blue)", fontSize: "var(--fontsize-regular)"}}>{event?.data().start ? event?.data().start.toDate().toLocaleDateString('de-DE')+ ' ab ' + event?.data().start.toDate().toLocaleTimeString('de-DE') : null}</div>
                  <div className="tickets-overview-subtitle" style={{maxWidth:"200px"}}><OrganizerName id={event?.data().venue} alt={event?.data().address??'-'} /></div>
                </div>
              </div>
              <div className="line"></div>
              <div className="variantsMapOverview">
                {variants.map((variant, i) => (
                  <div className="ContentÜbersicht" id={"O" + i}>
                    <div className="ticketoverview-header">
                      <div className="ticketoverview-ticket-title">
                        {variant.name || "Unbenannt"}
                      </div>
                      <div className="white" style={{display: "none"}}>{infoIcon}</div>
                    </div>
                    <div className="ticketoverview-content">
                      <div className="ticketoverview-content-left">
                        <div className="ticketoverview-text-raw-box">
                          <div className="ticketoverview-text-raw">Ticketanzahl</div>
                          <div className="ticketoverview-text-raw">Preis pro Ticket</div>
                          <div className="ticketoverview-text-raw">Kommission pro Ticket</div>
                          <div className="ticketoverview-text-raw" style={{color: "var(--green)",fontWeight: "600"}}>Möglicher Umsatz * </div>
                        </div>
                      </div>
                      <div className="ticketoverview-content-right">
                        <div className="ticketoverview-text-raw-box">
                          <div className="ticketoverview-text-raw">
                            {variant.quantity || "0"}
                          </div>
                          <div className="ticketoverview-text-raw">
                            {(parseFloat(variant.price?.replace(',', '.'))||0).toFixed(2).replace('.', ',') + "€"}
                          </div>
                          <div className="ticketoverview-text-raw">
                            {(commissionfee((parseFloat(variant.price?.replace(',', '.'))||0))).toFixed(2).replace('.',',') + "€"}
                          </div>
                          <div className="ticketoverview-text-raw" style={{ color: "var(--green)", fontWeight: "600"}} id={variant}>
                            {((variant.quantity??0) * grossToNetPrice((parseFloat(variant.price?.replace(',', '.'))||0)))
                              .toFixed(2)
                              .replace('.', ',')
                              .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}€
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="line"></div>
              <div className="paymentmethods-box">
                <div className="methodsbox">
                  <div className="paymentmethodquare">
                    <img src={Paypalpic} className="picturestyle" alt="Paypal Payment Card"/>
                  </div>
                  <div className="paymentmethodquare">
                    <img src={Mastercardpic} className="picturestyle" alt="Mastercard Payment Card"/>
                  </div>
                  <div className="paymentmethodquare">
                    <img src={Klarnapic} className="picturestyle" alt="Klarna Payment Card"/>
                  </div>
                  <div className="paymentmethodquare">
                    <img src={Applepic} className="picturestyle" alt="ApplePay Payment Card"/>
                  </div>
                  <div className="paymentmethodquare">
                    <img src={Googlepic} className="picturestyle" alt="GooglePay Payment Card"/>
                  </div>
                </div>
                <div className="methodsboxsubtitle">
                  + 9 weitere Bezahlmethoden
                </div>
              </div>
              <div className="line"></div>
              <div className="erklaerungstext-box">
                <div className="erklaerungstext">
                  In der Kommission sind alle Kosten inklusive die der Zahlungsanbieter inbegriffen. Es fallen keine weitere Kosten an.
                </div>
              </div>
              <div className="line"></div>
            </>}
            <div className="SummeBoxÜbersicht">
              <div className="umsatz-box">
                <div className="possible-umsatz-text" id="umsatz-title">Insg. möglicher Umsatz</div>
                <div className="possible-umsatz-text" id="umsatz-val"> {variants.reduce((prev, curr) => prev + (curr.quantity??0) * grossToNetPrice((parseFloat(curr.price?.replace(',', '.'))||0)), 0)
                  .toFixed(2)
                  .replace('.', ',')
                  .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}€</div>
              </div>
              <div className="possible-umsatz-subtext">
                * Nach Abzug der Kommission
              </div>
            </div>
          </div> 
        </div>
        <div className="main-content h-80">

          {/* sidebar */}
          <div className="SideBarErstellen el-hidden-small">
          <div>
            <div className="erforderlichSidebar">Erforderliche Angaben</div>
            <div className="itemSideBar">
              <div className="circleSideBar" id="ticket-categories-icon-nav">
                {variantsIcon}
              </div>
              <div className="iconBeschreibung">Ticketkategorien</div>
            </div>
            <div className="itemSideBar">
              <div className="circleSideBar" id="payment-methods-icon-nav">
                {paymentsIcon}
              </div>
              <div className="iconBeschreibung">Zahlungsarten</div>
            </div>
            <div className="itemSideBar">
              <div className="circleSideBar" id="bankaccount-icon-nav">
                {bankIcon}
              </div>
              <div className="iconBeschreibung">Auszahlungskonto</div>
            </div>
          </div>
          </div>

          {/* input fields  */}
          <div className="input-boxen w-90">
            <div className="InputBox">
              <div className="InputBoxHeader">
                <div className="InputCircle" id="ticket-categories-icon"> {variantsIcon} </div>
                <div className="InputBoxTitle">
                  <div className="InputName">Ticketkategorien</div>
                  <div className="InputDetails">Bitte erstelle alle Ticketkategorien, die du anbieten willst</div>
                </div>
              </div>
              <div className="tickets-box-content">
                {
                  variants.map((variant, index) => <Ticketvariantbox
                    variant={variant}
                    index={index}
                    removeVariant={removeVariant}
                    setVariants={setVariants}
                    variants={variants}
                    eventID={props.eventID}
                  />)
                }
                <button className="add-ticket" onClick={addVariant}>
                  <div id="add-ticket-icon">{plusIcon}</div>
                  <div className="add-ticket-button-text">Weitere Ticketart</div>
                </button>
              </div>
            </div>
            <div className="InputBox">
              <div className="InputBoxHeader">
                <div className="InputCircle" id="payment-methods-icon"> {paymentsIcon} </div>
                <div className="InputBoxTitle">
                  <div className="InputName"> Zahlungsarten </div>
                  <div className="InputDetails"> Hier siehst du welche Zahlungsarten für deine Kunden bereitstehen </div>
                </div>
              </div>
              <div className="row-payment-method" style={{display:"flex",flexDirection:"row"}}>
                <div className="left-row-item">
                  <div className="payment-name">Alle Zahlungsarten</div>
                  <div className="small t-secondary">Paypal, Kreditkarten, Klarna, Apple Pay, Google Pay uvw.</div>
                </div>
                <div className="paymentButtonRight">
                  <div
                    className={`${['active', 'bank_account_required'].includes(paymentAccountStatus) ? '' : 'not-'}activated-btn`}
                    style={{ float: "right", cursor: ['active', 'bank_account_required'].includes(paymentAccountStatus) ? 'default' : 'pointer' }}
                    onClick={ ['active', 'bank_account_required'].includes(paymentAccountStatus) ? null : activatePaymentProvider }
                  >
                    {loadingOnboarding ? 'Bitte warten...'
                      : ['active', 'bank_account_required'].includes(paymentAccountStatus) ? 'Aktiv'
                      : paymentAccountStatus === 'update_required' ? 'Angaben vervollständigen'
                      : 'Aktivieren'
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="InputBox">
              <div className="InputBoxHeader">
                <div className="InputCircle" id="bankaccount-icon"> {bankIcon} </div>
                <div className="InputBoxTitle">
                  <div className="InputName"> Auszahlungskonto </div>
                  <div className="InputDetails"> Auf dieses Konto überweisen wir deine Umsätze </div>
                </div>
              </div>
              {organizer?.data().payment_account ? <BankInput /> : <div>
                Bitte aktiviere erst die Zahlungsoptionen bevor du dein Konto hinterlegst.
              </div>}
            </div>
            {/* <div className="additionalInformation" style={{textAlign: "center"}}>Zugangsdaten zur Scanapp, digitale Gäste- & Freundesliste anlegen und weitere Funktionen kannst du erst nach dem Abschluss des Ticketings hinzufügen.</div> */}
          </div>

          {/* deine ticketübersicht */}
          <div className="ticket-overview" style={isFullWidth ? {display: "none"} : {display: "flex"}}>
            <div className="ticket-overview-header">
              <div className="ticket-overview-title">Deine Ticketübersicht</div>
            </div>
            <div className="ticket-overview-subheader" style={{alignItems:"center",maxHeight:"70px"}}>
              <FirebaseStorageImage reference={(event?.data().images.length??0) > 0 && event.data().images[0]} className="eventImageMini" />
              <div className="subheader-text">
                <div className="next-event-name-ticketOverview">{event?.data().name??'-'}</div>
                <div style={{color:"var(--blue)", fontSize: "var(--fontsize-regular)"}}>{event?.data().start ? event?.data().start.toDate().toLocaleDateString('de-DE')+ ' ab ' + event?.data().start.toDate().toLocaleTimeString('de-DE') : null}</div>
                <div className="tickets-overview-subtitle" style={{maxWidth:"200px"}}><OrganizerName id={event?.data().venue} alt={event?.data().address??'-'} /></div>
              </div>
            </div>
            <div className="line"></div>
            <div className="variantsMapOverview">
              {variants.map((variant, i) => (
                <div className="ContentÜbersicht" id={"O" + i}>
                  <div className="ticketoverview-header">
                    <div className="ticketoverview-ticket-title">
                      {variant.name || "Unbenannt"}
                    </div>
                    <div className="white" style={{display: "none"}}>{infoIcon}</div>
                  </div>
                  <div className="ticketoverview-content">
                    <div className="ticketoverview-content-left">
                      <div className="ticketoverview-text-raw-box">
                        <div className="ticketoverview-text-raw">Ticketanzahl</div>
                        <div className="ticketoverview-text-raw">Preis pro Ticket</div>
                        <div className="ticketoverview-text-raw">Kommission pro Ticket</div>
                        <div className="ticketoverview-text-raw" style={{color: "var(--green)",fontWeight: "600"}}>Möglicher Umsatz * </div>
                      </div>
                    </div>
                    <div className="ticketoverview-content-right">
                      <div className="ticketoverview-text-raw-box">
                        <div className="ticketoverview-text-raw">
                          {variant.quantity || "0"}
                        </div>
                        <div className="ticketoverview-text-raw">
                          {(parseFloat(variant.price?.replace(',', '.'))||0).toFixed(2).replace('.', ',') + "€"}
                        </div>
                        <div className="ticketoverview-text-raw">
                          {(commissionfee((parseFloat(variant.price?.replace(',', '.'))||0))).toFixed(2).replace('.',',') + "€"}
                        </div>
                        <div className="ticketoverview-text-raw" style={{ color: "var(--green)", fontWeight: "600"}} id={variant}>
                          {((variant.quantity??0) * grossToNetPrice((parseFloat(variant.price?.replace(',', '.'))||0)))
                            .toFixed(2)
                            .replace('.', ',')
                            .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}€
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="line"></div>
            <div className="paymentmethods-box">
              <div className="methodsbox">
                <div className="paymentmethodquare">
                  <img src={Paypalpic} className="picturestyle" alt="Paypal Payment Card"/>
                </div>
                <div className="paymentmethodquare">
                  <img src={Mastercardpic} className="picturestyle" alt="Mastercard Payment Card"/>
                </div>
                <div className="paymentmethodquare">
                  <img src={Klarnapic} className="picturestyle" alt="Klarna Payment Card"/>
                </div>
                <div className="paymentmethodquare">
                  <img src={Applepic} className="picturestyle" alt="ApplePay Payment Card"/>
                </div>
                <div className="paymentmethodquare">
                  <img src={Googlepic} className="picturestyle" alt="GooglePay Payment Card"/>
                </div>
              </div>
              <div className="methodsboxsubtitle">
                + 9 weitere Bezahlmethoden
              </div>
            </div>
            <div className="line"></div>
            <div className="erklaerungstext-box">
              <div className="erklaerungstext">
                In der Kommission sind alle Kosten inklusive die der Zahlungsanbieter inbegriffen. Es fallen keine weitere Kosten an.
              </div>
            </div>
            <div className="line"></div>
            <div className="SummeBoxÜbersicht" style={{marginBottom: "10px"}}>
              <div className="umsatz-box">
                <div className="possible-umsatz-text" id="umsatz-title">Insg. möglicher Umsatz</div>
                <div className="possible-umsatz-text" id="umsatz-val"> {variants.reduce((prev, curr) => prev + (curr.quantity??0) * grossToNetPrice((parseFloat(curr.price?.replace(',', '.'))||0)), 0)
                  .toFixed(2)
                  .replace('.', ',')
                  .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}€</div>
              </div>
              <div className="possible-umsatz-subtext">
                * Nach Abzug der Kommission
              </div>
            </div>
          </div>
        </div>
        <div className="bottomMobile" style={isFullWidth ? {display: "flex"} : {display: "none"}}>
          <div className="header-right">
            <div className="header-btns">
              <button className={'bestätigen-btn' + (inputsValid() ? '' : ' disabled')} onClick={() => {
                if(!hasStripeAccount){
                  hasNoStripeAccountToast()
                }
                else{
                  if(!inputsValid()) return;
                  submit()
                }
              }}>
                {edit ? "Änderungen Speichern" : "Bestätigen"}
              </button>
            </div>
            <div className="header-disclaimer">Durch das Klicken auf <span>{edit ? "Änderungen Speichern" : "Bestätigen"}</span> stimmst du den&nbsp;
              <a href={"https://www.elgio.de/terms-of-service-dashboard"} target="_blank" rel="noopener noreferrer" id="AgbLink" disabled> AGBs</a>
              &nbsp;von ELGIO zu.
            </div>
          </div> 
        </div>
      </div>
  );
}



function Ticketvariantbox ({eventID, variant, index, removeVariant, setVariants, variants}){
  const { id } = useParams()
  //show function zusatzfunktionen
  const [shown, setShown] = useState(false);

  const stripAndSetState = (value, index, key, regex) => {
    const numericValue = value.replace(regex, ''); // Strip non-numeric characters
    setVariants(prevVariants => {
      const updatedVariants = [...prevVariants];
      updatedVariants[index] = { ...updatedVariants[index], [key]: numericValue };
      return updatedVariants;
    });
  };

  const handleInputChange = (e, index, key) => {
    let regex = /\D/g;
    if(key === 'price') regex = /[^0-9,.]/g;
    stripAndSetState(e.target.value, index, key, regex);
  };
  return (
    <div className="tickets-box-ticket" key={variant}>
      <div className="spreader">
        <div className="tickets-box-ticket-title">
          Ticketart {index + 1}:
            {variant.name 
              ? <span style={{fontWeight: "600", color: "var(--textMain)"}}> {variant.name}</span>
              : <span style={{fontStyle: "italic", fontWeight: "600"}}> Unbenannt</span>
            }
        </div>
        {!variant.existing && <div className="delete-button" onClick={() => {removeVariant(index)}}>
          <div style={{color: "var(--textMain)"}}>{cancelIcon}</div>
        </div>}
      </div>
      <div style={{marginBottom: "20px"}}>
        <div style={{display: "flex", flexDirection: "column"}}>
          <div className="input-title" style={{ marginBottom: "5px"}}>Name der Ticketart{" "}</div>
          <input
              type="text"
              id="name-inp-ticketing"
              className="tickets-box-inp"
              placeholder="z.B. Early Bird"
              value={variant.name??""}
              disabled={variant.existing}
              style={variant.existing ? {cursor:"default",color:"var(--lightgrey)"} : {} }
              key={index}
              onChange={e => {
                setVariants(variants.map((element, i) => index === i ? {...element, name: e.target.value} : element))
              }} 
          />
        </div>
      </div>
      <div style={{display: "flex", flexDirection: "row", gap: "20px"}}>
        <div style={{display: "flex", flexDirection: "column", width: "calc(50% - 10px)"}}>
          <div className="input-title" style={{ position: "unset", marginBottom: "5px"}}>Ticketpreis{" "}</div>
          <div className="flex-gap-10">
            <input
              min={0}
              max={1000}
              disabled={variant.existing}
              style={variant.existing ? {cursor:"default",color:"var(--lightgrey)"} : {} }
              className="tickets-box-inp"
              placeholder="zb. 10€"
              value={variant.price}
              id="ticket-preis-inp"
              onChange={(e) => handleInputChange(e, index, 'price')}
              onBlur={(e) => handleInputChange(e, index, 'price')}
              />
          </div>
        </div>
        <div style={{display: "flex", flexDirection: "column", width: "calc(50% - 10px)"}}>
          <div className="input-title" style={{ position: "unset", marginBottom: "5px"}}>Ticketanzahl {variant.existing && <>(Min. {variant.total_tickets - variant.available_tickets})</>}</div>
            <input
              min={parseInt(variant.quantity)}
              className="tickets-box-inp"
              placeholder="z.B. 200"
              value={variant.quantity}
              onBlur={e =>  {
                if(variant.existing && parseInt(e.target.value) < variant.total_tickets - variant.available_tickets){
                  setVariants(variants.map((element, i) => index === i ? {...element, quantity: (variant.total_tickets - variant.available_tickets).toString()} : element))
                }
              }}
              onKeyDown={e => {
                  if((e.key === "Tab" || e.key === "Enter") && variant.existing && parseInt(e.target.value) < variant.total_tickets - variant.available_tickets){
                    setVariants(variants.map((element, i) => index === i ? {...element, quantity: (variant.total_tickets - variant.available_tickets).toString()} : element))
                  }
                }
              }
              onChange={(e) => handleInputChange(e, index, 'quantity')}
            />
        </div>
      </div>
      {/* <div className="tickets-box-ticket-possible-sales">
        <div className="tickets-box-ticket-possible-sales-text">
          <div>
            Möglicher Umsatz: 
            <em id="em-sales"> {((variant.quantity ?? 0) * grossToNetPrice((parseFloat(variant.price?.replace(',', '.'))) || 0))
              .toFixed(2)
              .replace('.', ',')
              .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}€</em>
          </div>
        </div>
        <div className="service-fee">nach Abzug der Kommission</div>
      </div> */}
      <div className="zusatzRow" onClick={()=> setShown(e=>!e)}>
        <div className= "zusatzfunktionen">Zusatzfunktionen</div>
        <div style={{color: "var(--textSecondary)"}}>
          {shown ? chevronDownIcon : chevronLeftIcon}
        </div>
      </div>
      {shown && <div className= "ZusatzfunktionenBox col-flex" style={{gap: "10px", marginTop: "10px"}}>
        <AdditionalOptionBox
          icon={finishIcon}
          title="Ticketart verfügbar bis"
          options={[
            {
              id: 'sold_out',
              name: 'ausverkauft',
            },
            {
              id: 'sold_out_or_custom',
              name: 'ausverkauft oder spätestens',
              input: 'date',
              selectedDefault: variant.sale_end,
            },
          ]}
          value={variant.sale_end}
          onChange={(e) => setVariants(variants.map((element, i) => index === i ? {...element, sale_end: e} : element))}
          variants={variants}
          variant={variant}
        />
        <AdditionalOptionBox
          icon={playIcon}
          title="Ticketart verfügbar ab"
          options={[
            {
              id: 'now',
              name: 'jetzt',
            },
            {
              id: 'previous_category_sold_out',
              name: 'andere Kategorie ausverkauft:',
              input: 'variant',
              selectedDefault: variant.sale_start && typeof variant.sale_start !== Date && typeof variant.sale_start !== Timestamp,
            },
            {
              id: 'custom_date',
              name: 'bestimmtes Datum',
              input: 'date',
              selectedDefault: variant.sale_start,
            },
          ]}
          value={variant.sale_start}
          onChange={(e) => setVariants(variants.map((element, i) => index === i ? {...element, sale_start: e} : element))}
          variants={variants}
          variant={variant}
        />
        <AdditionalOptionBox
          icon={clockIcon}
          title="Eintrittszeit mit Ticketart"
          options={[
            {
              id: 'anytime',
              name: 'jederzeit',
            },
            {
              id: 'until',
              name: 'von Anfang der Veranstaltung bis',
              input: 'date',
              selectedDefault: variant.admission_end,
            },
            //TODO
            // {
            //   id: 'custom',
            //   name: 'spezieller Zeitraum',
            //   input: 'date',
            // },
          ]}
          value={variant.admission_end}
          onChange={(e) => setVariants(variants.map((element, i) => index === i ? {...element, admission_end: e} : element))}
          disallowEdit={variant.existing}
          variants={variants}
          variant={variant}
        />
        <AdditionalOptionBox
          icon={numberIcon}
          title="Maximale Anzahl pro Kauf"
          options={[
            {
              id: 'unlimited',
              name: 'unbegrenzt',
            },
            {
              id: 'custom',
              name: 'max. Anzahl pro Kauf',
              input: 'number',
              selectedDefault: variant.max_tickets_per_purchase,
            },
          ]}
          value={variant.max_tickets_per_purchase}
          defaultValue={10}
          onChange={(e) => setVariants(variants.map((element, i) => index === i ? {...element, max_tickets_per_purchase: e} : element))}
          variants={variants}
          variant={variant}
        />
        <AdditionalOptionBox
          icon={closedEyeIcon}
          title="Ticketart verstecken"
          options={[
            {
              id: 'public',
              name: 'sichtbar für alle',
            },
            {
              id: 'private',
              name: 'nur mit Link einsehbar',
              input: 'link',
              selectedDefault: variant.hidden,
            },
          ]}
          value={variant.hidden ? `https://t.elgio.de/${eventID??id}/checkout?show_variants=${variant.id}` : null}
          onChange={(e) => setVariants(variants.map((element, i) => index === i ? {...element, hidden: e} : element))}
          disallowEdit={variant.existing}
          variants={variants}
          variant={variant}
        />
      </div>} 
    </div>
  )
}

function AdditionalOptionBox({ icon, title, options, value, onChange, variant, variants, disallowEdit, defaultValue }) {
  const [shown, setShown] = useState(false);
  const [currentOption, setCurrentOption] = useState(options.find(e => e.selectedDefault)??options[0]);

  const copyLink = () => {
    toast.info("Link wurde kopiert", { theme: "dark" });
    window.navigator.clipboard.writeText(value);
  };

  return (
    <div className= "flexTileTicketing">
      <div className= "specialBoxHeader" onClick={()=> setShown(!shown)}>
        <div className= "specialBoxHeaderLeft">
          <div className= "specialCircle" style={value && value !== defaultValue ? { backgroundColor: 'var(--blue)', color: "var(--buttonCoMain)" } : {}}>{icon}</div>
          <div className= "col-flex">
            <div className= "tileHeader">{title}</div>
            <div className= "tileSubText">
              Derzeitige Einstellung:&nbsp;
              <span className="white">
              {currentOption.name}&nbsp;
              {value !== true && (
                value instanceof Date ? (value.toLocaleDateString("de-DE") + ' ' + value.toLocaleTimeString("de-DE"))
                : typeof value === 'string' && value.includes('https://') 
                ? <span 
                    onClick={copyLink}
                    style={{fontWeight: "600"}}
                  >(Link kopieren)</span>
                : variants?.find(e => e.id === value)?.name??value?.toString()
                )}
                </span>
            </div>
          </div>
        </div>
        {!disallowEdit && <div className="white fontSize-22px pointer">{shown ? chevronDownIcon : chevronLeftIcon}</div>}
      </div>
      {shown && <div className="specialContent">
        {options.map(option => <AdditionalOptionBoxOption
          {...option}
          title={title}
          selected={currentOption.id === option.id}
          onSelect={() => setCurrentOption(option)}
          value={value}
          onChange={onChange}
          variants={variants}
          variant={variant}
        />)}
      </div>}
    </div>
  )
}

function AdditionalOptionBoxOption({ title, id, name, input, selected, onSelect, value, onChange, variant, variants }) {
  return <div className="selectionRow" style={{marginBottom: "10px"}}>
    <input type="radio" id={`${variant.id} ${title} ${id}`} name={`${variant.id} ${title}`} checked={selected} onChange={e => {
      if(!e.target.checked) return;
      onSelect();
      onChange(input === 'link' ? true : null);
    }} className="divRadio"></input>
    <div className="selectionColumn">
      <label for={`${variant.id} ${title} ${id}`} className={selected && input ? 'labelText' : 'simpleText'}>{name}</label>
      {selected && (() => {
        switch(input) {
          case 'date': return <div className="flex-gap-10">
            <DatePicker
              selected={value}
              onChange={onChange}
              showTimeSelect
              dateFormat="dd.MM.yyyy HH:mm"
              className="TextInputSpecial"
              style={{ flex: 1 }}
              minDate={new Date()}
              placeholderText="Datum und Uhrzeit"
              locale="de"
            />
          </div>;
          case 'variant': return <select className="TextInputSpecialSelect" value={value} onChange={e => onChange(e.target.value)}>
            <option value={null} style={{color: 'var(--lightgrey)'}}>Wähle die Kategorie aus</option>
            {variants.filter(e => e.id !== variant.id).map(variant => <option value={variant.id}>{variant.name}</option>)}
          </select>;
          case 'number': return <input
            type="number"
            style={{backgroundColor:"var(--grey)"}}
            className="TextInputSpecial"
            id="TicketLink"
            placeholder="z.B. 10"
            value={value}
            onChange={e => onChange(e.target.value)}
          ></input>;
          case 'link': return <div className="row-flex" style={{position: 'relative'}}>
            <input
              type={"url"}
              style={{backgroundColor:"var(--grey)", width: "100%"}}
              className="TextInputSpecial"
              id="TicketLink"
              value={value}
            />
            <div className="copyBox" onClick={() => window.navigator.clipboard.writeText(value)}>
              <div className="copyicon">{copyIcon}</div>
            </div>
          </div>;
          default: return <></>;
        }
      })()}
    </div>
  </div>;
}