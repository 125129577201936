import React, { useEffect, useRef, useState } from "react";
import "../../index.scss";
import "./ArtistInput.css";
import { index } from "../../algolia";
import { genreIcon, playIcon, stopIcon } from "../../icons";
import FirebaseStorageImage from '../functions/FirebaseStorageImage';


export default function ArtistInput({ onChange, onSelect, ...rest }) {
    const [value, setValue] = useState('');
    const [suggestionsVisible, setSuggestionsVisible] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [artist, setArtist] = useState(null);
    const [currentAudio, setCurrentAudio] = useState(null);
    const audioRef = useRef(null);

    let artistName = null;

    useEffect(() => { onChange && onChange(artist) }, [artist, onChange]);

    useEffect(() => {
        if (suggestionsVisible && value.length > 2) {
            index.search(value, { filters: 'recordType:"artist"', hitsPerPage: 5 }).then(({ hits }) => {
                setSuggestions(hits.filter(e => e.name.replace(/[^A-Z0-9]+/ig, '').toLowerCase().startsWith(value.replace(/[^A-Z0-9]+/ig, '').toLowerCase())));
            });
        } else {
            setSuggestions([]);
        }
    }, [suggestionsVisible, value]);

    const onInputChange = (element) => {
        setValue(element.target.value);
        if(value === element.target.value) return;
        setArtist(null);
    };

    const onSuggestionClick = (e) => {
        onSelect && onSelect(e.objectID.split('-')[1]);
        setValue(e.name);
        setArtist(e.objectID.split('-')[1]);
        artistName = e.name;
        setSuggestionsVisible(false);
    };

    const onBlur = async () => {
        if (value.length === 0 || artistName) return;
        setSuggestionsVisible(false);
    };

    const addAsNew = async() => {
        onSelect && onSelect('$' + value);
        setArtist('$' + value);
        artistName = value;
        setSuggestionsVisible(false);
    };

    const toggleAudio = (audio) => {
        if(currentAudio === audio) {
            setCurrentAudio(null);
            audioRef.current.pause();
        } else {
            setCurrentAudio(audio);
            audioRef.current.src = audio;
            audioRef.current.play();
        }
    };

    return(
        <>
            <input {...rest} style={{zIndex: 2}} value={value} onChange={(e) => onInputChange(e)} onBlur={() => setTimeout(onBlur, 250)} onFocus={() => setSuggestionsVisible(true)}  onKeyDown={e => e.key === 'Enter' && onBlur()} />
            <div style={{position: 'relative'}}>
                {value && suggestionsVisible && (
                    <div className="suggenstionInputBox">
                        {suggestions.map((e) => (
                            <ArtistSuggestion 
                                key={e.objectID}
                                name={e.name}
                                toggleAudio={toggleAudio}
                                currentAudio={currentAudio}
                                top_track_url={e.top_track_url}
                                image={e.images?.length > 0 ? e.images[0] : null}
                                onClick={() => onSuggestionClick(e)}
                            />
                        ))}
                        {(suggestions.length === 0 || value.replace(/[^A-Z0-9]+/ig, '').toLowerCase() !== suggestions[0].name.replace(/[^A-Z0-9]+/ig, '').toLowerCase()) && (
                            <div style={{marginTop: "5px"}} onClick={addAsNew}>
                                "<span style={{fontWeight: 600}}>{value}</span>" als neuen Künstler hinzufügen
                            </div>
                        )}
                </div>)}
            </div>
            <audio ref={audioRef} src="" onEnded={() => setCurrentAudio(null)} />
        </>
    );
}

function ArtistSuggestion({ name, onClick, image, top_track_url, toggleAudio, currentAudio }) {
    return(
        <div className="suggestionRow">
            <div className="suggestionRowLeft" onClick={onClick}>
                <div>{ image ? <FirebaseStorageImage reference={image} className="artistSuggestionImage" alt="artistIMG"/> : <div className="artistPlaceholderImage">{genreIcon}</div>}</div>
                <div>{ name }</div>
            </div>
            {top_track_url && <div
                className="playCircleArtist"
                onClick={e => {
                    e.stopPropagation();
                    toggleAudio(top_track_url);
                }}
                >
                {top_track_url === currentAudio ? stopIcon : playIcon }
            </div>}
        </div>
    );
}