import React, { useEffect, useState } from "react";
import { index } from "../../algolia";
import "../../index.scss";

export default function OrganizerInput({ onChange, onSelect,handleCustomizationChange, ...rest }) {
    const [value, setValue] = useState('');
    const [suggestionsVisible, setSuggestionsVisible] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [organizer, setOrganizer] = useState(null);

    let organizerName = null;

    useEffect(() => { onChange && onChange(organizer) }, [organizer, onChange]);

    useEffect(() => {
        if (suggestionsVisible && value.length > 2) {
            index.search(value, { filters: 'recordType:"organizer"', hitsPerPage: 5 }).then(({ hits }) => {
                setSuggestions(hits.filter(e => e.name.replace(/[^A-Z0-9]+/ig, '').toLowerCase().startsWith(value.replace(/[^A-Z0-9]+/ig, '').toLowerCase())));
            });
        } else {
            setSuggestions([]);
        }
    }, [suggestionsVisible, value]);

    const onInputChange = (element) => {
        setValue(element.target.value);
        if(value === element.target.value) return;
        setOrganizer(null);
        if(handleCustomizationChange) handleCustomizationChange("organizer", [element.target.value]);
    };

    const onSuggestionClick = (e) => {
        onSelect && onSelect(e.objectID.split('-')[1]);
        setValue(e.name);
        setOrganizer(e.objectID.split('-')[1]);
        organizerName = e.name;
        setSuggestionsVisible(false);
    };

    const onBlur = async () => {
        if (value.length === 0 || organizerName) return;
        setSuggestionsVisible(false);
    };

    const addAsNew = async() => {
        onSelect && onSelect('$' + value);
        setOrganizer('$' + value);
        organizerName = value;
        setSuggestionsVisible(false);
    };

    return <>
        <input {...rest} style={{zIndex: 2}} value={value} onChange={(e) => onInputChange(e)} onFocus={() => setSuggestionsVisible(true)} onBlur={() => setTimeout(onBlur, 250)} onKeyDown={e => e.key === 'Enter' && onBlur()} />
        <div style={{position: 'relative'}}>
            {value && suggestionsVisible && <div className="suggenstionInputBox">
                {suggestions.map((e) => 
                    <VenueSuggestion key={e.objectID} name={e.name} onClick={() => onSuggestionClick(e)}/>
                )}
                {(suggestions.length === 0 || value.replace(/[^A-Z0-9]+/ig, '').toLowerCase() !== suggestions[0].name.replace(/[^A-Z0-9]+/ig, '').toLowerCase()) && (
                    <div style={{ marginTop: "5px" }} onClick={addAsNew}>
                        "<span style={{fontWeight: 600}}>{value}</span>" als neuen Veranstalter hinzufügen
                    </div>
                )}
            </div>}
        </div>
    </>;
}

function VenueSuggestion({ name, onClick }) {
    return <div onClick={onClick}>
        { name }
    </div>;
}