import React, { useEffect, useState } from 'react'
import "./statistics.css";
import { useNavigate } from 'react-router-dom'
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale, //x axis
  LinearScale, //y axis
  PointElement,
  Tooltip,
  Title,
  Filler,
  ArcElement,
  BarElement
} from 'chart.js';
import { useContext } from 'react'
import { StatsContext } from '../../providers/StatsProvider'
import "../../index.scss";
import { 
  chevronLeftIcon,
  chevronRightIcon,
} from '../../icons';
import PieChart from '../widgets/statistics_widgets/pieChart';
import FurtherInfo from '../widgets/tooltip';
import BarChart from '../widgets/statistics_widgets/barChart';
import LineGraph from '../widgets/statistics_widgets/lineGraph';
import LineChart from '../widgets/statistics_widgets/lineChart';


export default function Statistics(){
  const [statisticsState,setStatisticState] = useState(2)
  // eslint-disable-next-line no-unused-vars
  const [activeStatisticsFirstRow,setActiveStatisticsFirstRow] = useState({})
  // eslint-disable-next-line no-unused-vars
  const [activeStatisticsSecondRow,setActiveStatisticsSecondRow] = useState({})
  // eslint-disable-next-line no-unused-vars
  const [activeStatisticsThirdRow,setActiveStatisticsThirdRow] = useState({})
  // eslint-disable-next-line no-unused-vars
  const [dataToday,setDataToday] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [dataWeek,setDataWeek] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [dataMonth,setDataMonth] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [dataYesterday,setDataYesterday] = useState([])

  useEffect(() => {
      if(statisticsState === 0){
        setTimeframe('today');
        setActiveStatisticsFirstRow(dataToday[0])
        setActiveStatisticsSecondRow(dataToday[1])
        setActiveStatisticsThirdRow(dataToday[2])
      }
      else if(statisticsState === 1){
        setTimeframe('yesterday')
        setActiveStatisticsFirstRow(dataYesterday[0])
        setActiveStatisticsSecondRow(dataYesterday[1])
        setActiveStatisticsThirdRow(dataYesterday[2])
      }
      else if(statisticsState === 2){
        setTimeframe('week');        
        setActiveStatisticsFirstRow(dataWeek[0])
        setActiveStatisticsSecondRow(dataWeek[1])
        setActiveStatisticsThirdRow(dataWeek[2])
      }
      else{
        setTimeframe('last_30_days');
        setActiveStatisticsFirstRow(dataMonth[0])
        setActiveStatisticsSecondRow(dataMonth[1])
        setActiveStatisticsThirdRow(dataMonth[2])
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[statisticsState])

    
  const {
    getProfileViews,
    getEventViews,
    getEventShares,
    getEventBookmarks,
    getCustomerStatuses,
    getCustomerAges,
    getCustomerGenders,
    getCustomerClubs,
    getCustomerGenres,
    getCustomerOrigin,
  } = useContext(StatsContext);

  //Use setTimeframe to set timeframe for all statistics
  const [timeframe, setTimeframe] = useState('today');

  const [profileViews, profileViewsSum, profileViewsLabels] = getProfileViews({ timeframe });
  const [eventViews, eventViewsSum, eventViewsLabels] = getEventViews({ timeframe });
  const [eventShares, eventSharesSum, eventSharesLabels] = getEventShares({ timeframe });
  const [eventBookmarks, eventBookmarksSum, eventBookmarksLabels] = getEventBookmarks({ timeframe });
  const [customerStatuses, customerStatusesLabels] = getCustomerStatuses({ timeframe });
  const [customerGenders,customerGendersLabels] = getCustomerGenders( {timeframe}); 
  const [customerAges, customerAgesLabels] = getCustomerAges({ timeframe });
  const [customerGenres, customerGenresLabels] = getCustomerGenres({ timeframe });
  const [customerOrigin, customerOriginLabels] = getCustomerOrigin({ timeframe });
  const [customerClubs, customerClubsLabels] = getCustomerClubs({ timeframe });
    

  ChartJS.register(
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Tooltip,
    Title,
    ArcElement,
    BarElement,
    Filler)


  const navigate = useNavigate()

  return(
    <div className="content">
      <div id="breadcrumb-title-text" onClick={() => navigate(-1) }>Statistiken</div>
      <div className="PageHeaderBox">
        <div>
          <div className="PageHeaderBoxTitle">Statistiken</div>
          <div className="PageHeaderBoxSubtitle">Hier lernst du über dein Profil, deine Kunden und ihre Präferenzen</div>
        </div> 
        <div className="DatePickerContainer">
          <div className="pointer"
          onClick={() => {
            if(statisticsState === 0)
              {setStatisticState(3)}
            else{
              setStatisticState(statisticsState - 1 )}
          }}>{chevronLeftIcon}</div>
          <div className="DatePicker">{statisticsState === 0 ? "Heute" : statisticsState === 1 ? "Gestern" : statisticsState === 2 ? "Letzte 7 Tage" :  "Letzte 30 Tage"}</div> 
          <div className="pointer"
            onClick={() => {
              if(statisticsState === 3)
                {setStatisticState(0)}
              else{
                setStatisticState(statisticsState + 1 )}
          }}>{chevronRightIcon}</div>
        </div>
      </div>
        {/* First Row of Statistics */}
        <div className='RowStatsBox'>
          <div className="StatsBoxFirstRowContainer">
            <div className="StatisticItemBox">
              <div className='StatisticItemBoxHeader'>
                <div className="StatisticHeader">Profilaufrufe</div>
                <div className="StatisticNumber">{profileViewsSum}</div>
              </div>
              <LineGraph data={profileViews} labels={profileViewsLabels} colors={["#0d6efd", "#0d6efd", "#0d6efd"]}/>
            </div>
            <div className="StatisticItemBox">
              <div className='StatisticItemBoxHeader'>
                <div className="StatisticHeader">Eventaufrufe</div>
                <div className="StatisticNumber">{eventViewsSum}</div>
              </div>
              <LineGraph data={eventViews} labels={eventViewsLabels} colors={["#4ab022", "#4ab022", "#4ab022"]}/> 
            </div>
          </div>
          <div className="StatsBoxFirstRowContainer">
            <div className="StatisticItemBox">
              <div className='StatisticItemBoxHeader'>
                <div className="StatisticHeader">Teilungen</div>
                <div className="StatisticNumber">{eventSharesSum}</div>
              </div>
              <LineGraph data={eventShares} labels={eventSharesLabels} colors={["#8b44fd", "#8b44fd", "#8b44fd"]}/>
            </div>
            <div className="StatisticItemBox">
              <div className='StatisticItemBoxHeader'>
                <div className="StatisticHeader">Merkungen</div>
                <div className="StatisticNumber">{eventBookmarksSum}</div>
              </div>
              <LineGraph data={eventBookmarks} labels={eventBookmarksLabels} colors={["#fdfd44", "#fdfd44", "#fdfd44"]}/>
            </div>
          </div>
        </div>

        {/* Second Row of Statistics */}
        <div className="RowStatsBox">
          <div className="StatisticItemBoxBig">
            <div className="row-flex justify_space_between align_center" style={{width:"100%"}}>
              <div className= "BigStatisticHeader">Kundenstamm</div>
              <FurtherInfo
                explanation="Hier siehst du prozentual wie viele der Ticketkäufer wiederkehrende Kunden (Bestandskunden) oder erstmalige Kunden (Neukunden) sind."
              />          
            </div>
            <PieChart data={customerStatuses} labels={customerStatusesLabels} colors={['#faab17', '#4ab022']} />
          </div>
          <div className="StatisticItemBoxBig">
            <div className="row-flex justify_space_between align_center" style={{width:"100%"}}>
              <div className= "BigStatisticHeader">Alter</div>
              <FurtherInfo
                explanation="Hier siehst du die Verteilung deiner Kunden in Altersgruppen. Als Kunde zählen wir hier alle, die mit deinen Events interagiert (zb. gemerkt, geteilt, gekauft) haben."
              />
            </div>
            <BarChart data={customerAges} labels={customerAgesLabels} colors={['#fdfd44']}/>
          </div>
          <div className="StatisticItemBoxBig">
            <div className="row-flex justify_space_between align_center" style={{width:"100%"}}>
              <div className="BigStatisticHeader">Geschlecht</div>
              <FurtherInfo
                explanation="Hier siehst du die Verteilung deiner Kunden in Geschlechtern. Als Kunde zählen wir hier alle, die mit deinen Events interagiert (zb. gemerkt, geteilt, gekauft) haben."
              />
            </div>
            <PieChart data={customerGenders} labels={customerGendersLabels} colors={['#0d6efd', '#f20000', '#8b44fd']}/>
          </div>
        </div>
         
        {/* Third Row of Statistics */}
        <div className="RowStatsBox">
          <div className="StatisticItemBoxBig">
            <div className="row-flex justify_space_between align_center" style={{width:"100%"}}>
              <div className= "BigStatisticHeader">Beliebte Musikgenre</div>
                <FurtherInfo
                  explanation="Hier siehst du in welchen Musikgenre deine Kunden oft Events suchen."
                />
            </div>
            <div className='col-flex justify_center' style={{width: "100%", height: "100%"}}>
              <LineChart data={customerGenres} labels={customerGenresLabels} colors={['#f20000']}/>
            </div>
          </div>

          <div className="StatisticItemBoxBig">
            <div className="row-flex justify_space_between align_center" style={{width:"100%"}}>
              <div className= "BigStatisticHeader">Herkunft</div>
              <FurtherInfo
                explanation="Hier siehst du aus welchen größeren Ballungsräumen deine Kunden kommen."
              />
            </div>
            <div className='col-flex justify_center' style={{width: "100%", height: "100%"}}>
              <LineChart data={customerOrigin} labels={customerOriginLabels} colors={['#faab17']}/>
            </div>
          </div>

          <div className="StatisticItemBoxBig">
            <div className="row-flex justify_space_between align_center" style={{width:"100%"}}>
              <div className= "BigStatisticHeader">Alternativer Club</div>
              <FurtherInfo
                explanation="Hier siehst du in welche anderen Clubs deine Gäste noch gerne gehen."
              />
            </div>
            <div className='col-flex justify_center' style={{width: "100%", height: "100%"}}>
              <LineChart data={customerClubs} labels={customerClubsLabels} colors={['#8b44fd']}/>
            </div>
          </div>
        </div>
    </div>
  )
}