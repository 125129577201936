import { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import "./statistics_widgets.css";


export default function PieChart({data, labels, colors, percentage=true}) {
    const [sum, setSum] = useState(0);
    const [computedData, setComputedData] = useState(data);

    const options = {
        radius:"90%",
        plugins: {
            tooltip: {
                callbacks: {
                    label: (item) => `${item.dataset.label}: ${item.formattedValue}${percentage ? '%' : ''}`,
                },
            },
        },
    }
    
    const real = {
        labels,
        datasets: [{
            label: 'Anzahl',
            data: computedData,
            backgroundColor: colors,
            hoverOffset: 2,
            borderWidth: 0,
        }],
    }

    const placeholder = {
        labels,
        datasets: [{
            label: 'Anzahl',
            data: ["1","2","3"],
            backgroundColor: ["#1c1c1c","#262626","#2c2c2c"],
            hoverOffset: 2,
            borderWidth: 0,
        }],
    }
    
    useEffect(() => { setSum(data.reduce((prev, curr) => prev + curr, 0)) }, [data]);
    useEffect(() => { sum && setComputedData(percentage ? data.map(e => e * 100 / sum) : data) }, [data, sum, percentage]);

    return sum === 0 
        ?
        <div className="statsPlaceholderBox">
            <div className="statsPlaceholderLine">Für diesen Zeitraum liegen noch keine richtigen Daten vor</div>
            <div className="statsPieBox">
                <div className="statsPieContainer">
                    <Pie data={placeholder} options={options}></Pie>
                </div>
                <div className="statsPieLabelBox">
                    <div className="statsPieLabel">
                        <div className='statsPieLabelNumber' style={{color: "#141414"}}>123</div>
                        <div className='statsPieLabelDescription' style={{color: "#141414"}}>Kategorie 1</div>
                    </div>
                    <div className="statsPieLabel">
                        <div className='statsPieLabelNumber' style={{color: "#141414"}}>456</div>
                        <div className='statsPieLabelDescription' style={{color: "#141414"}}>Kategorie 2</div>
                    </div>
                </div>
            </div>
        </div>
        :
        <div className="statsPieBox">
            <div className="statsPieContainer">
                <Pie data={real} options={options}></Pie>
            </div>
            <div className="statsPieLabelBox">
                {
                    labels.map((label, index) => <div className="statsPieLabel">
                        <div className='statsPieLabelNumber' style={{color: colors[index]}}>{data[index]}</div>
                        <div className='statsPieLabelDescription' style={{color: colors[index]}}>{label}</div>
                    </div>)
                }
            </div>
        </div>
    ;
}