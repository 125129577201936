/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./login.css";
import { UserContext } from "../../../providers/UserProvider";
import { getAuth, sendPasswordResetEmail, signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../../firebase";
import { Loader } from "../../../loader";
import Header from "../../widgets/header";
import "../../../index.scss";
import {  closedEyeIcon, eyeIcon } from "../../../icons";
import LoaderDivWithOverlay, { hideLoader, showLoader } from "../../widgets/loaderdiv";

// Declare the Login component using arrow function syntax
const Login = () => {
  const navigate = useNavigate();

  // Declare state variables using destructuring assignment
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Initial");


  // Access user context using useContext hook
  const { user } = useContext(UserContext);

  // Redirect to dashboard if user is already logged in
  useEffect(() => { user && navigate("/dashboard") }, [user]);


// Handle login form submission
const handleLogin = async () => {
  if(email.length > 0 && password.length > 0){
    document.getElementById("error-message").style.visibility="hidden"
    loginLoading();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      setTimeout(() => navigate("/dashboard"), 250);
    } catch (error) {
      document.getElementById("error-message").style.visibility="visible";
      loginUnloading();
      switch (error.code) {
        case "auth/user-not-found":
          setErrorMessage("Dieser Nutzer ist nicht vorhanden");
          break;
        case "auth/network-request-failed":
          setErrorMessage("Du bist offline");
          break;
        case "auth/invalid-email":
          setErrorMessage("Bitte gib eine richtige Email Adresse ein");
          break;
        case "auth/wrong-password":
          setErrorMessage("Das Passwort stimmt nicht");
          break;
        default:
          setErrorMessage(error.message || "An unexpected error occurred");
      }
    }
  } else {
    email.length === 0 && password.length === 0 ? 
      setErrorMessage("Du musst eine Email und ein Passwort angeben!")
    : 
    email.length === 0 ? 
      setErrorMessage("Du musst eine Email angeben")
    :
      setErrorMessage("Du musst ein Passwort angeben")
    document.getElementById("error-message").style.visibility="visible"
  }
};

  // Handle password visibility toggle
  const handlePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  function loginLoading(){
    document.getElementsByClassName("loaderDiv")[0].style.visibility="visible";
    document.getElementById("login-content").style.visibility="hidden";
  }

  function loginUnloading(){
    document.getElementsByClassName("loaderDiv")[0].style.visibility="hidden";
    document.getElementById("login-content").style.visibility="visible";
  }
  
  return (
    <>
      <div className="bg-img-2 bg-img-100" id="login-content">
        <Header></Header>      
        <div className="col-flex flex-gap-2rem justify_center align_center">
          <div className="flex col-flex">
            <div className="titleSignup">Willkommen zurück</div>
            <div className="SubheaderSignup">Bitte gib deine Veranstalter-Logindaten ein</div>
          </div>
          <div className="InputsBox">
            <div className="InputsFields">
              <input
                autoComplete="off"
                className="inputSignup Input"
                type="email"
                id="emailFast"
                name="Email"
                placeholder="Email"
                value={email}
                onKeyPress={(e) => {
                  if(e.key === 'Enter'){
                    document.getElementById('loginBtn')?.click()
                  }
                }}
                onChange={(e) => {
                  if(document.getElementById("error-message").style.visibility === "visible"){
                    document.getElementById("error-message").style.visibility="hidden"
                  }
                  setEmail(e.target.value);}}
              />
              <div className="w-100 passwInput Input" id="password-Input" style={{position:"relative"}}>
              <input
                autoComplete="new-password"
                type={isPasswordVisible ? "text" : "password"}
                id="passwordFast"
                className="inputSignup"
                name="Password"
                placeholder="Passwort"
                value={password}
                onKeyPress={(e) => {
                  if(e.key === 'Enter'){
                    document.getElementById('loginBtn')?.click()
                  }
                }}
                onChange={(e) => {
                  if(document.getElementById("error-message").style.visibility === "visible"){
                    document.getElementById("error-message").style.visibility="hidden"
                  }
                  setPassword(e.target.value)            
                }}
              ></input>
              <div onClick={() => handlePasswordVisibility()} id={"passwIconRegis"}>{!isPasswordVisible ? eyeIcon : closedEyeIcon}</div>
            </div>
            <div className="error-message" id="error-message">{errorMessage}</div>
            <Link to="/login/reset-password" id="passwVergessen">Passwort vergessen?</Link>
            <button id="loginBtn" onClick={handleLogin}> Let's Go </button>
          </div>
        </div>
      </div>
      <div className="login-link-text-register">
        <div>Noch kein Profil?</div>
        <Link to={"/register"} id="regislink" className="linkBottomRegister">Registieren</Link>
      </div>
      </div>
      <Loader></Loader>
    </>
  );
}




export default Login;
