import React, { useEffect } from "react";
import "./account_erstellen.css";
import { Inputs } from "./account_erstellen_inputs";
import { useState } from "react";
import { useContext } from "react";
import { UserContext } from "../../../providers/UserProvider";
import { firestore } from "../../../firebase";
import "../../../index.scss";
import SideBar from "./account_erstellen_sidebar";
import logo from "../../../graphics/newIcon_white.png";
import { addDoc, collection, deleteDoc, doc, getDocs, setDoc, updateDoc } from "firebase/firestore";

function Erstellen() {
  const { user } = useContext(UserContext);
  const { organizerId } = useContext(UserContext);
  const [data, setData] = useState({});
  const [accountTyp, setAccountTyp] = useState("");
  const [areas, setAreas] = useState();

  const submit = async () => {
    try {
      if (!data.residents) {
        data.residents = [];
      }
  
      await Promise.all(
        data.residents.map(async (e) => {
          if (e.startsWith('$')) {
            const docRef = await addDoc(collection(firestore, 'artists'), {
              name: e.substring(1),
              agent: user.uid,
              visibility: 'public',
            });
            const index = data.residents.indexOf(e);
            if (index !== -1) {
              data.residents[index] = docRef.id;
            }
          }
        })
      );
  
      const documentRef = doc(firestore, "organizers", organizerId);
      await updateDoc(documentRef, {
        ...data,
        autoDiscover: true,
        payment_accounts: {},
        share_count: 0,
        subscription_count: 0,
        ticket_sales: 0,
        view_count: 0,
        visibility: "public",
        agent: user.uid,
      });
  
      const areasRef = collection(documentRef, 'areas');
      const querySnapshot = await getDocs(areasRef);
      await Promise.all(querySnapshot.docs.map((doc) => deleteDoc(doc.ref)));
  
      if (areas && Array.isArray(areas)) {
        await Promise.all(
          areas.map(async (e, i) => {
            await setDoc(doc(documentRef, 'areas', String(i)), {
              ...e.data(),
              agent: user.uid,
            }, { merge: true });
          })
        );
      }
  
      window.location.href = '/dashboard';
    } catch (error) {
      console.error('Error in submit function:', error);
    }
  };
  
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isFullWidth = windowWidth < 768;
  
  return (
    <div className="bg-img-100">
      <div className="row-flex" style={{alignSelf: "flex-end"}}>
            <img alt="elgio_logo" onClick={() => {window.location.replace("https://elgio.de")}} src={logo} className="LogoSignup"></img>
        </div>
      <div style={{justifyContent: "center", display: "flex", marginTop: "50px"}}>
        <div className="createProfilPage">
          <div className="createProfilHeader">
            <div className="erstellen">Veranstalterprofil erstellen</div>
            <div id="details">Mach so viele Angaben wie es geht</div>
          </div>
          <div style={{display: 'flex', gap: 50}}>
            <div style={{display: isFullWidth ? "none" : "flex"}}>
              <SideBar accountTyp={accountTyp}/>
            </div>
            <Inputs includeFooter submit={submit} onDataChange={setData} setAccountTyp={setAccountTyp} accountTyp={accountTyp} setAreas={setAreas}></Inputs>
            <div className="VorschauContainer" style={{ alignSelf: 'stretch', height: "60vh"}}>
              <div id="vorschauheader">
                  Vorschau in der App  
                <div className= "Beschreibungsuntertitel"> So sehen deine Gäste dein Profil in der App </div>
              </div>
              <div className="ComingSoon">Coming Soon</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Erstellen;
