import React, { useEffect, useState } from "react";
import { collection, doc, getDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import "../../index.scss";

export default function VenueAddress({id, alt, ...rest }) {
  const [address, setAddress] = useState("Loading...");

  useEffect(
    () =>
      {id
        ? getDoc(doc(collection(firestore, "organizers"), id)).then((doc) =>
            setAddress(doc.data()?.address ?? alt ?? "")
          )
        : setAddress(alt ?? "")},
    [id, alt]
  );

  return <span {...rest}>{address}</span>;
}
