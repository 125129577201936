/* eslint-disable react/jsx-pascal-case */
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../../firebase"
import { Link } from "react-router-dom"
import FirebaseStorageImage from "../functions/FirebaseStorageImage"
import OrganizerName from "./OrganizerName"
import "./eventbox.css"
import "../../index.scss";
import ProgressBar from "@ramonak/react-progress-bar";
import { useState } from "react";
import { useEffect } from "react";
import 'react-toastify/dist/ReactToastify.css';
import ContextMenu__Event from "./contextmenu_events";

export default function EventBox({noContextMenu=false,onClick=() => {},pendingReview=false,canceled=false,startDateRaw=new Date(), id, showStats=false,visibility="public", ...props }) {
    const [ticketanzahl,setTicketanzahl] = useState(0)
    const [ticketKaeufe,setTicketKaeufe] = useState(0)
    const [percentage,setPercentage] = useState(0)


    useEffect(() => {
        let percentage = (ticketKaeufe * 100) / ticketanzahl
        setPercentage(
            Math.round(percentage)
        )
        },[ticketKaeufe,ticketanzahl])

    // this effect is for the stats
    useEffect(() => {
        getDocs(collection(firestore,"events",id,"tickets"))
            .then((ticketTypesEventSnap) => {
            const anzahl = ticketTypesEventSnap.docs.reduce((prev, curr) => prev + curr.data()?.total_tickets??0, 0)
            const kaeufe = anzahl - ticketTypesEventSnap.docs.reduce((prev, curr) => prev + curr.data()?.available_tickets??0, 0)
            setTicketanzahl(anzahl)
            setTicketKaeufe(kaeufe);
            })
    },[id])


    return (
        <div className="event-box">
            {(() => {
                if(!noContextMenu){
                    return(    
                        <div className="dropdown-button">
                            <ContextMenu__Event setReloadDrafts={props.setReloadDrafts} setReloadEvents={props.setReloadEvents} id={id} visibility={visibility} startDateRaw={startDateRaw} canceled={canceled} pendingReview={pendingReview}></ContextMenu__Event>
                        </div>
                    )
                }
            })
            ()}
            <Link className="link" to={props.shouldntRedirect ? "#" : props.ticketing ? "/dashboard/events/ticketing/" + id : props.linkLocation ? props.linkLocation + id: "/dashboard/events/event/" + id } onClick={() => {if(props.shouldntRedirect) onClick(props.eventData,id)}}>
                <FirebaseStorageImage className="banner" reference={props.banner}/>
                <div className="bottom">
                        <div className="title">
                            {props.titel ?? "Loading..."}
                        </div>
                    <div className="row-flex justify_space_between flex-gap-10px flex-wrap" style={{minWidth: 0}}>
                        <div className="col-flex" style={{justifyContent: "flex-end"}}>
                            <div className="date text-color__blue medium">
                                {props.date ?? "Kein Datum"}
                            </div>
                            <div className="location medium">
                                <OrganizerName id={props.club} alt={props.address || 'Keine Angabe'} />
                            </div>
                        </div>
                        <div className="row-flex flex-gap-1rem align_center">
                            {showStats && <div className="ticketbestandbox">
                                <div className="ticketbestand-small" style={{ display: props.noTicketing ? 'none' : 'flex' }}>Ticketbestand</div>
                                <ProgressBar
                                    completed={props.noTicketing ? 100 :percentage}
                                    baseBgColor={props.noTicketing ? "none" : "var(--lightgrey)"}
                                    bgColor={props.noTicketing ? "var(--grey)" : "var(--blue)"}
                                    height="30px"
                                    maxCompleted={100}
                                    borderRadius="15px"
                                    labelSize="10px"
                                    animateOnRender={props.noTicketing ? false : true}
                                    labelAlignment="center"
                                    customLabelStyles={{fontWeight:"400", color: "var(--textSecondary)"}}
                                    customLabel={props.noTicketing ? "Kein Ticketing aktiviert" : ""}
                                    isLabelVisible={props.noTicketing ? true : false}
                                ></ProgressBar>
                            </div>}
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
}