import React, { useEffect, useState } from "react";
import { collection, doc, getDoc } from "firebase/firestore";
import { geocode } from "nominatim-browser";
import { firestore } from "../../firebase";
import { index } from "../../algolia";
import "../../index.scss";
import "./ArtistInput.css";
import FirebaseStorageImage from "../functions/FirebaseStorageImage";
import { adresseIcon } from "../../icons";

export default function LocationInput({ handleCustomInput,address, city, venue, setAddress, setCity, setVenue, ...rest }) {
    const [value, setValue] = useState('');
    const [suggestionsVisible, setSuggestionsVisible] = useState(false);
    const [venueSuggestions, setVenueSuggestions] = useState([]);

    useEffect(() => {(async () => {
        if(value) return;
        if(venue) {
            const document = await getDoc(doc(collection(firestore, 'organizers'), venue));
            setValue(document.data().name + " - " +  document.data().address);
            setAddress(document.data().address);
            setCity(document.data().city);
        } else if(address)
            setValue(address)
    })()}, [value, setValue, venue, address, setAddress, setCity]);

    //const [address, setAddress] = useState(rest.address);
    //const [city, setCity] = useState(rest.city);
    //const [venue, setVenue] = useState(rest.venue);
    var venueName = null;
    //useEffect(() => onAddressChange(address), [address, onAddressChange]);
    //useEffect(() => onCityChange(city), [city, onCityChange]);
    //useEffect(() => onVenueChange(venue), [venue, onVenueChange]);

    useEffect(() => { suggestionsVisible && value.length > 2 ? index.search(value, { filters: 'recordType:"organizer" AND type:"venue"' }).then(({ hits }) => setVenueSuggestions(hits)) : setVenueSuggestions([]) }, [suggestionsVisible, value]);

    const onInputChange = (element) => {
        setValue(element.target.value);
        if(address === element.target.value || venueName === element.target.value) return;
        setAddress(null);
        setCity(null);
        setVenue(null);
        venueName = null;
    };

    const onVenueSuggestionClick = (venue) => {
        setValue(venue.name + " - " + venue.address );
        setAddress(venue.address);
        setCity(venue.city);
        setVenue(venue.objectID.split('-')[1]);
        venueName = venue.name;
        // venueAddress = venue.address
        setSuggestionsVisible(false);
    };

    const onBlur = async () => {
        setSuggestionsVisible(false);
        if(venueName === null) {
            const results = await geocode({
                q: value,
                addressdetails: true,
            });
            for(const result of results) {
                const address = result.address;
                if(address.road && address.postcode && (address.city || address.village) && address.country_code === 'de') {
                    let houseNumber = address.house_number;
                    if(!houseNumber) {
                        const germanAddressRegex = /\b(\d+)\s*([a-zA-Z]|\b)/; //ChatGPT did this, idk when it is working and when not
                        const houseNumberMatch = value.match(germanAddressRegex);
                        if (houseNumberMatch) {
                            houseNumber = houseNumberMatch[1];
                        } else {
                            continue;
                        }
                    }
                    setValue(`${address.road} ${houseNumber}, ${address.postcode}, ${address.city??address.village}`);
                    setAddress(`${address.road} ${houseNumber}, ${address.postcode}, ${address.city??address.village}`);
                    setCity((address.city??address.village).toLowerCase());
                    break;
                }
            }
        }
    };

    return <>
        <input {...rest} value={value} onChange={(e) => onInputChange(e)} onFocus={() => setSuggestionsVisible(true)} onBlur={() => setTimeout(onBlur, 250)} onKeyDown={(e) => e.key === 'Enter' && e.target.blur()} />
        <div style={{position: 'relative'}}>
            {venueSuggestions.length > 0 && <div className="suggenstionInputBox">
                {venueSuggestions.map((e) => 
                    <VenueSuggestion 
                        address={e.address}
                        name={e.name}
                        onClick={() => onVenueSuggestionClick(e)}
                        image={e.images?.length > 0 ? e.images[0] : null}
                    />
                )}
            </div>}
        </div>
    </>;
}

function VenueSuggestion({address, name, onClick, image }) {
    return (
        <div onClick={onClick}>
            <div className="suggestionRowVenue" onClick={onClick}>
                <div>{ image ? <FirebaseStorageImage reference={image} className="artistSuggestionImage" alt="venueIMG"/> : <div className="artistPlaceholderImage">{adresseIcon}</div>}</div>
                <div>
                    <span style={{ fontWeight: "600" }}>{name}</span> - {address}
                </div>
            </div>
        </div>
    );
}