import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useState } from "react";
import "./payment_sheet.css";
import { useEffect } from "react";

export default function PaymentSheet({ clientSecret, billingDetails, price, priceMonthly, onSuccess, submitButtonText }) {
    const stripe = useStripe();
    const elements = useElements();

    const [loading, setLoading] = useState(true);
    useEffect(() => { setLoading(!clientSecret) }, [clientSecret]);

    const _completePayment = async (event) => {
        event.preventDefault();

        if(!stripe || !elements) return;
        
        setLoading(true);

        const { error: submitError } = await elements.submit();

        if(submitError) {
            alert('Es gab einen Fehler mit deiner Zahlung. Bitte versuche es erneut.');
            setLoading(false);
            return;
        }

        const { error: confirmError } = await (!price ? stripe.confirmSetup : stripe.confirmPayment)({
            elements,
            clientSecret: clientSecret,
            redirect: 'if_required',
            confirmParams: {
                payment_method_data: {
                    billing_details: billingDetails,
                },
            },
        });

        if(confirmError) {
            alert('Es gab einen Fehler mit deiner Zahlung. Bitte versuche es erneut.');
            setLoading(false);
            return;
        } else {
            onSuccess();
        }
    };

    return <form className="paysheet-form" onSubmit={_completePayment}>
        <div className="paysheet-payment-element">
            <PaymentElement options={{
                defaultValues: { billingDetails },
                fields: { billingDetails: { email: 'never' } },
                terms: { card: 'never' },
            }} />
            <div className="paysheet-paymentterms">
                {price === 0
                    ? priceMonthly === 0 ? <></> : <>
                        Der erste Monat ist <span style={{color: "var(--textMain)"}}>kostenlos</span>.&nbsp;
                        Danach berechnen wir <span style={{color: "var(--textMain)"}}>{(priceMonthly / 100).toFixed(2).replace('.', ',')}€</span> pro Monat.
                    </> : <>
                        Wir belasten deine Karte jeden Monat mit <span style={{color: "var(--textMain)"}}>{(priceMonthly / 100).toFixed(2).replace('.', ',')}€</span>.
                    </>
                }
                <br />
                {priceMonthly !== 0 && 'Das Abonnement kann jederzeit gekündigt werden.'}
            </div>
            <button className="paysheet-submit" disabled={loading}>{submitButtonText}</button>
            <div className="paysheet-conditions">
                Durch das Klicken auf "{submitButtonText}" stimmst du den&nbsp;
                <a href={"https://www.elgio.de/terms-of-service-dashboard"} target="_blank" rel="noopener noreferrer" id="AgbLink" disabled>AGBs</a>&nbsp;
                von ELGIO zu.
            </div>
        </div>
    </form>;
}