import { signOut } from 'firebase/auth'
import React from 'react'
import { auth } from '../../firebase'
import { redirect } from 'react-router-dom'

function Logout() {
    signOut(auth)
    redirect("/login")
    return(
        <></>
    )
}

export default Logout