import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import "./statistics_widgets.css";


export default function BarChart({data, labels, colors, percentage=true}) {
    const [sum, setSum] = useState(0);
    const [computedData, setComputedData] = useState(data);

    const options = {
        aspectRatio: 1.8,
        scales: {
            y: {beginAtZero: true},
            x: { border: { color: '#4b4b4b', width: "2"}, beginAtZero: true},
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: (item) => `${item.dataset.label}: ${item.formattedValue}${percentage ? '%' : ''}`,
                },
            },
        },
    }

    // const optionsPlaceholder = {
    //     aspectRatio: 1.8,
    //     scales: {
    //         y: {beginAtZero: true},
    //         x: { border: { color: '#2c2c2c', width: "2"}, beginAtZero: true},
    //     }
    // }

    const real = {
        labels,
        datasets: [{
          label: 'Anzahl',
          data: computedData,
          backgroundColor: colors,
          borderRadius: 3,
          barThickness: 15,
        }],
    }

    const placeholder = {
        labels,
        datasets: [{
          label: 'Anzahl',
          data: ["1", "2", "3", "4", "3", "6", "2"],
          backgroundColor: "#2c2c2c",
          borderRadius: 3,
          barThickness: 15,
        }],
    }

    useEffect(() => { setSum(data.reduce((prev, curr) => prev + curr, 0)) }, [data]);
    useEffect(() => { sum && setComputedData(percentage ? data.map(e => e * 100 / sum) : data) }, [data, sum, percentage]);

    return sum === 0 
        ?
        <div className="statsPlaceholderBox">
            <div className="statsPlaceholderLine">Für diesen Zeitraum liegen noch keine richtigen Daten vor</div>
            <Bar data={placeholder} options={options}></Bar>  
        </div>
        :
        <div className="row-flex justify_center align_center" style={{height: "100%", width: "100%"}}>
            <Bar data={real} options={options}></Bar>        
        </div>
    ;
}