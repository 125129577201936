import React, { useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { OrganizerContext } from '../../providers/OrganizerProvider';
import { UserContext } from '../../providers/UserProvider'

const PrivateRoute = ({ component: Component, children, ignoreProfileNotYetCreated, ...rest }) => {
  const { user } = useContext(UserContext);
  const { hasCreatedProfile } = useContext(OrganizerContext);

  const isLoggedIn = user != null;

  return isLoggedIn ? hasCreatedProfile || ignoreProfileNotYetCreated ? children ? children : <Outlet /> : <Navigate to="/register/create-profile" /> : <Navigate to="/login" />;
}

export default PrivateRoute