import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import "./marketing.css";
import PostBox from "../widgets/postbox.jsx";
import "./ticketing/benefits.css";
import { useNavigate } from 'react-router-dom'
import "../../index.scss";
import Modal from "react-modal";
import { facebookIcon, instagramIcon, plusIcon, deleteIcon, chevronLeftIcon, chevronDownIcon, cancelIcon } from '../../icons';
import { collection, deleteDoc, doc, getDoc, getDocs, onSnapshot, orderBy, query, setDoc, updateDoc, where } from 'firebase/firestore';
import { firestore, storage } from '../../firebase';
import { UserContext } from '../../providers/UserProvider';
import DatePicker from "react-datepicker";
import { styleModal } from '../global_config/modal_style';
import html2canvas from 'html2canvas';
import { getDownloadURL, ref, uploadBytes, uploadString } from '@firebase/storage';
import { uuidv4 } from '@firebase/util';
import { OrganizerContext } from '../../providers/OrganizerProvider';
import ProBenefits from './ticketing/probenefits';
import more from "../../graphics/More.png";
import like from "../../graphics/Like.png";
import comment from "../../graphics/Comment.png";
import share from "../../graphics/Share.png";
import save from "../../graphics/Bookmark.png";
import HandlebarsIframe from "../pages/special/handlebarsIframe"
import { DateTime } from 'luxon';
import { connectToFacebook, connectToInstagram } from '../../facebook';
import FirebaseStorageImage from '../functions/FirebaseStorageImage';
const { canvasRGBA } = require('stackblur-canvas');

export default function Marketing() {
    const {hasPro} = useContext(OrganizerContext);

    return (
        <>
            {hasPro ? <MarketingContent /> : <ProBenefits />}
        </>
    )
  }


function MarketingContent(){
    const navigate = useNavigate()
    const {organizerId} = useContext(UserContext)

    // Bool for Upcoming toggle and Upcoming Post Array   
    const [upcoming,setUpcoming] = useState(true);
    const [upcomingPosts,setUpcomingPosts] = useState([])
    // Past post Array
    const [pastPosts,setPastPosts] = useState([])

    // Show Create Post Modal
    const [show,setShow] = useState(false)

    // Show Enlarged Post Modal
    const [modalPic,setModalPic] = useState(null)

    // Data for Edit
    const [dataEdit,setDataEdit] = useState(null)
    const [idEdit,setIdEdit] = useState()

    const deletePost = async (id) => {
        if(window.confirm("Bist du dir sicher, dass du diesen Post löschen willst?")){
            await deleteDoc(doc(collection(firestore,"social_media_posts"),id))
        }
    }
      
      
    // Fetch Upcoming + Past Posts onFirstRender && everytime a new is created
    useEffect(() => {
        // Upcoming Posts
        onSnapshot(
            query(
                collection(firestore,"social_media_posts"),
                where("organizer","==",organizerId),
                orderBy('post_at', 'asc')
            ), (posts) => {
                setUpcomingPosts(posts.docs.filter(e => ['scheduled', 'posting'].includes(e.data()?.status)))
                setPastPosts(posts.docs.filter(e => !['scheduled', 'posting'].includes(e.data()?.status)).reverse().slice(0, 50))
            })
    },[organizerId])
    
    return(
        <div className="content">
            <div id="breadcrumb-title-text" onClick={() => navigate(-1) }>Marketing</div>
            <div className="PageHeaderBox">
                <div>
                    <div className="PageHeaderBoxTitle">Marketing</div>
                    <div className="PageHeaderBoxSubtitle">Generiere und poste automatisch Eventposts, Wochen- und Monatsübersichten</div>
                </div>
                <div id="newEventButton" onClick={() => {setDataEdit(null); setShow(true)}} >
                    {plusIcon}
                    Neuer Post
                </div> 
            </div>
            <div className='EventBoxColumn'>
                <div className="title-row">
                    <div className={upcoming ? "beschreibungtitelActive" : "beschreibungtitelNotActive"} onClick={() => {setUpcoming(true)}}>Anstehende Posts</div>
                    <div className="beschreibungtitel">|</div>
                    <div className={upcoming ? "beschreibungtitelNotActive" : "beschreibungtitelActive"} onClick={() => {setUpcoming(false)}}>Vergangene Posts</div>
                </div>
                <div className="postBox">
                        {
                            (upcoming ? upcomingPosts : pastPosts).length !== 0 ? (upcoming ? upcomingPosts : pastPosts).map( post => {
                                return(
                                    <PostBox
                                        post_at_raw={post.data()?.post_at?.toDate()}
                                        post_at={post.data()?.post_at?.toDate().toLocaleDateString("de-DE",{weekday: 'short', day: '2-digit', month: '2-digit', year: 'numeric'}) + " um " + post.data().post_at.toDate().toLocaleTimeString("de-DE",{hour:"2-digit",minute:"2-digit"})}    
                                        banner={post.data()?.banner}
                                        status={post.data()?.status}
                                        wholeDoc={post.data()}
                                        setDataEdit={setDataEdit}
                                        deletePost={deletePost}
                                        setShow={setShow}
                                        setModalPic={setModalPic}
                                        setIdEdit={setIdEdit}
                                        id={post.id}
                                        repeat={post.data()?.repeat}
                                        instagram_story={post.data().instagram_story}
                                        instagram_post={post.data().instagram_post}
                                        facebook_story={post.data().facebook_story}
                                        facebook_post={post.data().facebook_post}
                                        type={post.data().type}
                                        caption={post.data().caption}
                                    ></PostBox>
                                )
                            }) : 
                            <div className='white' style={{textAlign:"center",fontSize:"var(--fontsize-big)",display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%"}}>Du hast noch keine {upcoming ? "anstehenden" : "vergangene"} Posts !</div>
                        }
                        <PostModal idEdit={idEdit} show={show} setShow={setShow} dataEdit={dataEdit}></PostModal>
                        <PictureModal pic={modalPic} setPic={setModalPic} ></PictureModal>
                </div>
            </div>
        </div>
    )
}


export function PostModal(props){
    const {organizerId, user} = useContext(UserContext)

    const [uploading, setUploading] = useState(false);

    const [iFrameRef, setIFrameRef] = useState(null);

    const [userIcon, setUserIcon] = useState(null);

    // Instagram 
    const [shown, setShown] = useState(false);
    
    // Facebook
    const [shown2, setShown2] = useState(false);

    // Extra Desing
    const [shown3, setShown3] = useState(false);
    
    // Modal Select Arrays, Indexes etc.
    const [postType,setPostType] = useState('event')
    const [events,setEvents] = useState([])
    const [weeks,setWeeks] = useState([])
    const [weekStarts,setWeekStarts] = useState([])
    const [months,setMonths] = useState([])
    const [monthStarts,setMonthStarts] = useState([])
    const [organizerName,setOrganizerName] = useState("")
    const [organizerAddress,setOrganizerAddress] = useState("")
    const [isVenue,setIsVenue] = useState(false)
    // eslint-disable-next-line no-unused-vars
    const [filteredEvents,setFilteredEvents] = useState([])
    const [filteredEventsIDs,setFilteredEventsIDs] = useState([])
    const [organizerCity,setOrganizerCity] = useState("")
    const [facebookPageId, setFacebookPageId] = useState(null);
    const [instagramAccountId, setInstagramAccountId] = useState(null);
    const [instagramAccountUsername, setInstagramAccountUsername] = useState(null);
    // Bool for Modal show

    // Data inside Modal
    const [postDate,setPostDate] = useState(new Date())
    // const [banner,setBanner] = useState("")
    const [selectedEvent,setSelectedEvent] = useState(null)
    const [caption,setCaption] = useState("")
    const [repeat, setRepeat] = useState(null);
    
    // For specific insta post
    const [instagramPost,setInstagramPost] = useState(false)
    const [instagramStory,setInstagramStory] = useState(false)
    
    // For specific fb post
    const [facebookStory,setFacebookStory] = useState(false)
    const [facebookPost,setFacebookPost] = useState(false)

    const [bgColor,setBgColor] = useState("#0D6EFD")
    // const [bgImage,setBgImage] = useState(null)
    const [bgImageName,setBgImageName] = useState("")
    const [bgImageRaw,setBgImageRaw] = useState("")
    const [bgImageRawAdjusted,setBgImageRawAdjusted] = useState("")
    const [dateRange,setDateRange] = useState("")
    const [rangeStart,setRangeStart] = useState(new Date())
    const [monat,setMonat] = useState("")
    const [selectedTemplate,setSelectedTemplate] = useState("")
    const [templates,setTemplates] = useState([])
    const [selectedTemplateHtml,setSelectedTemplateHtml] = useState("")

    // Font Selection for Design
    const fontOptions = ['Helvetica', 'Verdana', 'Courier New', 'Vensfolk', 'Manolo Mono', 'HelloRamon', 'Spicy Shrimp', 'Gord Qucik'];
    const [selectedFont, setSelectedFont] = useState(fontOptions[0]);
    const [selectedTextColor, setSelectedTextColor] = useState('black');

    // imgSrc for weekPost
    const [imgSrc,setImgSrc] = useState("")
    
    // imgSrc for monthPost
    const [imgSrc2,setImgSrc2] = useState("")


    const [eventName,setEventName] = useState("")
    
    const valid = postDate && 

        ( 
            // Event Post
            (selectedEvent || eventName)
            || 
            // Week Post
            (dateRange)
            || 
            // Month Post
            (monat)
        )
         && caption?.length > 0 && (instagramPost || instagramStory || facebookPost || facebookStory)
    // Current Posts

    const handleFontChange = async (e) => {
        setSelectedFont(e.target.value);
      };
     

    
        useEffect(() => {
            if(!props.dataEdit) return;
            setPostDate(props.dataEdit.post_at?.toDate()??new Date());
            setRepeat(props.dataEdit.repeat??null);
            setCaption(props.dataEdit.caption);
            // setBanner(props.dataEdit.banner)
            if(props.dataEdit.dateRange) setDateRange(props.dataEdit.dateRange)
            setPostType(props.dataEdit.type)
            switch(props.dataEdit.type) {
                case "event": 
                    setSelectedEvent(props.dataEdit.event)
                    break
                case "overview_week":
                    setRangeStart(props.dataEdit.range_start?.toDate())
                    setDateRange(weeks[weekStarts.findIndex(e => Math.abs(e.getTime() - props.dataEdit.range_start?.toDate().getTime()) < 8640000)])
                    break
                case "overview_month":
                    setRangeStart(props.dataEdit.range_start?.toDate())
                    setMonat(months[monthStarts.findIndex(e => Math.abs(e.getTime() - props.dataEdit.range_start?.toDate().getTime()) < 8640000)])
                    break
                default:
                    break
            }
            setSelectedTextColor(props.dataEdit.text_color)
            setSelectedFont(props.dataEdit.font_family)
            setBgColor(props.dataEdit.background_color)
            if(props.dataEdit.background_image) {
                const imageRef = ref(storage, props.dataEdit.background_image)
                setBgImageName(imageRef.name)
                getDownloadURL(imageRef)
                    .then(url => fetch(url))
                    .then(res => res.blob())
                    .then(blob => {
                        let reader = new FileReader();
                        reader.onload = () => setBgImageName(this.result);
                        reader.readAsDataURL(blob);
                    });
            }
            if(props.dataEdit.instagram_post || props.dataEdit.instagram_story) setShown(true)
            if(props.dataEdit.facebook_post || props.dataEdit.facebook_story) setShown2(true)
            setSelectedTemplate(props.dataEdit.template)
            if(props.dataEdit.instagram_post){
                setTimeout(() => {setInstagramPost(props.dataEdit.instagram_post)},5)
            } 
            if(props.dataEdit.instagram_story){
                setTimeout(() => {setInstagramStory(props.dataEdit.instagram_story)},5)
            } 
            if(props.dataEdit.facebook_post){
                setTimeout(() => {setFacebookPost(props.dataEdit.facebook_post)},5)
            }
            if(props.dataEdit.facebook_story){
                setTimeout(() => {setFacebookStory(props.dataEdit.facebook_story)},5)
            }
            if(props.dataEdit.event){
                getDoc(doc(collection(firestore, "events"), props.dataEdit.event)).then((eventDoc) => {if(eventDoc.exists())setEventName(eventDoc.data().name)})
            }
            setShown3(true)

        }, [props.dataEdit, weeks, months, weekStarts, monthStarts]); // Add dataEdit as a dependency

        useEffect(() => {
             // Public/private Events from Organizer
        getDocs(
            query(
                collection(firestore,"events"),
                where("organizer","array-contains",organizerId),
                where("visibility","in",["public", "private"]),
                where("start",">",new Date()),
            )
            ).then((e) => {
                setEvents(e.docs)
            })
            erstelleWochenArray()
            verbleibendeMonate()
        getDoc(doc(collection(firestore,"organizers"),organizerId))
        .then((e) => {
            setOrganizerName(e.data()?.name);
            setIsVenue(e.data()?.type === "venue")
            setOrganizerAddress(e.data()?.address)
            setOrganizerCity(e.data()?.city)
        })
        },[organizerId])

    useEffect(() => {(async () => {
        if(!bgImageRaw) return;

        const iframe = iFrameRef?.current;

        if(!iframe) return;

        //preparation
        const prepCanvas = await html2canvas(iframe.contentDocument.body, { backgroundColor: null });
        const prepCtx = prepCanvas.getContext('2d');
        const prepImageData = prepCtx.getImageData(0, 0, prepCanvas.width, prepCanvas.height);
        const prepData = prepImageData.data;
        if(selectedTextColor !== 'white') {
            for(let i = 0; i < prepData.length; i += 4) {
                if(prepData[i] < 30 && prepData[i + 1] < 30 && prepData[i + 2] < 30) {
                    prepData[i] = prepData[i + 1] = prepData[i + 2] = prepData[i + 3] = 255;
                } else {
                    prepData[i] = prepData[i + 1] = prepData[i + 2] = prepData[i + 3] = 0;
                }
            }
        } else {
            for(let i = 0; i < prepData.length; i += 4) {
                if(prepData[i] > 225 && prepData[i + 1] > 225 && prepData[i + 2] > 225) {
                    prepData[i] = prepData[i + 1] = prepData[i + 2] = 0;
                    prepData[i + 3] = 255;
                } else {
                    prepData[i] = prepData[i + 1] = prepData[i + 2] = 255;
                    prepData[i + 3] = 0;
                }
            }
        }
        prepCtx.putImageData(prepImageData, 0, 0);
        canvasRGBA(prepCanvas, 0, 0, prepCanvas.width, prepCanvas.height, 5);

        const canvas = document.createElement('canvas');
        canvas.width = prepCanvas.width;
        canvas.height = prepCanvas.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(prepCanvas, 0, 0);
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data;
        if(selectedTextColor !== 'white') {
            for(let i = 0; i < data.length; i += 4) {
                data[i] = data[i + 1] = data[i + 2] = 255;
                if(data[i] > 5 && data[i + 1] > 5 && data[i + 2] > 5) {
                    data[i + 3] = 75;
                } else {
                    data[i + 3] = 0;
                }
            }
        } else {
            for(let i = 0; i < data.length; i += 4) {
                data[i] = data[i + 1] = data[i + 2] = 0;
                if(data[i] < 250 && data[i + 1] < 250 && data[i + 2] < 250) {
                    data[i + 3] = 100;
                } else {
                    data[i + 3] = 0;
                }
            }
        }
        ctx.putImageData(imageData, 0, 0);
        canvasRGBA(canvas, 0, 0, canvas.width, canvas.height, 10);

        //apply to image
        const img = new Image();
        img.onload = () => {
            const imageCanvas = document.createElement('canvas');
            const imageCtx = imageCanvas.getContext('2d');
            imageCanvas.width = canvas.width;
            imageCanvas.height = canvas.height;
            const imgRatio = img.width / img.height;
            const canvasRatio = imageCanvas.width / imageCanvas.height;

            let sx, sy, sWidth, sHeight;

            if (imgRatio >= canvasRatio) {
                // Image is wider than the canvas
                sWidth = img.height * canvasRatio;
                sHeight = img.height;
                sx = (img.width - sWidth) / 2;
                sy = 0;
            } else {
                // Image is taller than the canvas
                sWidth = img.width;
                sHeight = img.width / canvasRatio;
                sx = 0;
                sy = (img.height - sHeight) / 2;
            }

            // Draw the image onto the canvas with appropriate scaling and positioning
            imageCtx.drawImage(img, sx, sy, sWidth, sHeight, 0, 0, imageCanvas.width, imageCanvas.height);

            imageCtx.globalCompositeOperation = 'source-atop';
            imageCtx.drawImage(canvas, 0, 0);
            setBgImageRawAdjusted(imageCanvas.toDataURL());
        };
        img.src = bgImageRaw;
    })()}, [bgImageRaw, selectedTextColor, selectedTemplate, selectedEvent, dateRange, monat, iFrameRef]);

    const saveBanner = async () => {
        const iframe = iFrameRef?.current;

        if(!iframe) return;

        const canvas = await html2canvas(iframe.contentDocument.body, { useCORS: true });
        const blob = await new Promise(r => canvas.toBlob(blob => r(new Blob([blob], { type: 'image/png' }))));
        const uuid = uuidv4();
        const file = new File([blob], uuid + ".png", blob);
        const reference = ref(storage, "/post_images/" + file.name);
        await uploadBytes(reference, file);

        return "post_images/" + file.name;
    };

    //  Submit a Post && reset all Values
    const submit = async () => {
        if(((facebookStory || facebookPost) && typeof(facebookPageId) === "undefined"  )){
            const {facebookPageId: id} = await connectToFacebook();
            setFacebookPageId(id);
            submit()
        }
        else if((instagramPost || instagramStory) && typeof(instagramAccountId) === "undefined"){
            const {instagramAccountId: id,facebookPageId: fbid} = await connectToInstagram(facebookPageId);
            setInstagramAccountId(id);
            setFacebookPageId(fbid);
            submit()
        }
        else{
        setUploading(true);

        let bgImage;
        let banner;
        if(bgImageRaw) {
            bgImage = `organizers/${organizerId}/social_media_posts/${uuidv4()}/${bgImageName}`;
            const reference = ref(storage, bgImage);
            //save time by dual uploading
            banner = (await Promise.all([
                saveBanner(),
                uploadString(reference, bgImageRaw, 'data_url', { contentType: 'image/png' }),
            ]))[0];
        } else {
            bgImage = null;
            banner = await saveBanner();
        }

        if(postType === "event"){
            await setDoc(doc(collection(firestore,"social_media_posts")),
            {
                created_timestamp: new Date(),
                type: postType,
                template: selectedTemplate,
                event: selectedEvent ? selectedEvent : null,
                post_at: postDate,
                repeat,
                caption: caption,
                background_color: bgColor,
                background_image: bgImage,
                font_family: selectedFont,
                text_color: selectedTextColor,
                banner: banner,
                organizer: organizerId,
                instagram_story: instagramStory,
                instagram_post: instagramPost,
                facebook_story: facebookStory,
                facebook_post: facebookPost,
                status: 'scheduled',
             }
             )
            } else {
                setDoc(doc(collection(firestore,"social_media_posts")),
                         {
                            created_timestamp: new Date(),
                            type: postType,
                            template: selectedTemplate,
                            post_at: postDate,
                            repeat,
                            events: filteredEventsIDs,
                            range_start: new Date(rangeStart.getTime() - 1000 * 60 * rangeStart.getTimezoneOffset()),
                            caption: caption,
                            background_color: bgColor,
                            background_image: bgImage,
                            font_family: selectedFont,
                            text_color: selectedTextColor,
                            banner: banner,
                            organizer: organizerId,
                            instagram_story: instagramStory,
                            instagram_post: instagramPost,
                            facebook_story: facebookStory,
                            facebook_post: facebookPost,
                            status: 'scheduled',
                        })  
            }
            props.setShow(false)
            cleanStates()
        }
    }
    



    const updatePost = async () => {
        await updateDoc(doc(collection(firestore,"social_media_posts"),props.idEdit),
        {
            post_at: postDate,
            repeat,
            caption: caption,
            instagram_story: instagramStory,
            instagram_post: instagramPost,
            facebook_story: facebookStory,
            facebook_post: facebookPost,
        }
        ).then(() => {
            cleanStates()
            props.setShow(false)
        })
        .catch((e) => {console.log(e);})
    }




    // Cleanup all neccessary States on Modal close 
    const cleanStates = () => {
        setUploading(false);
        setPostType("event")
        setSelectedTemplate("standard")
        setPostDate(new Date())
        setSelectedEvent(null)
        setMonat("")
        setDateRange("")
        setBgColor("#0D6EFD")
        setBgImageName("")
        setBgImageRaw("")
        setBgImageRawAdjusted("")
        setSelectedFont(fontOptions[0])
        setSelectedTextColor('black')
        setImgSrc("")
        setImgSrc2("")
        setCaption("")
        setInstagramPost(false)
        setInstagramStory(false)
        setFacebookPost(false)
        setShown(false)
        setShown2(false)
        setShown3(false)
        setFacebookStory(false)
        setRepeat(null)
    }

    // get the the caption of the selected event
    const getInfos = async (e) => {
        if(e){
            setSelectedEvent(e)
            await getDoc(doc(collection(firestore,"events"),e)).then((event) => {
                setCaption(event.data().description) //TODO: display infos such as event name, event date and time, price, etc
                })
            }
            else{
                setSelectedEvent(null);
                return
                
        }
    }


    function EventSelect({selectedEvent}){
        return(
            <select name="" id="events" className='selectMarketing' value={selectedEvent} onChange={(e) => getInfos(e.target.value)}>
                <option key="null" value="null">Wähle das Event</option>
                {events.map((event,index) => {
                    return(
                     <option key={event.id} value={event.id} >{event.data().name}</option>
                    )
                 })}
            </select>
        )
    }

    function WeekSelect({dateRange}){
        return(
            <select name="" id="" value={dateRange} onChange={(e) => {
                setDateRange(e.currentTarget.value);
                setRangeStart(weekStarts[weeks.indexOf(e.currentTarget.value)]);
                filterEventsByDateRange(e.currentTarget.value)
                const [von,bis] = e.currentTarget.value.split("-")
                setCaption("Das Wochenprogramm vom " + von + " bis " + bis + " im " + organizerName + ".\nKomm vorbei und tanz mit uns!\n\nAlle Events und dazugehörige Infos gibt es auf ELGIO." )
                }} className='selectMarketing'>

                <option value={null}>Wähle die Woche</option>
                {weeks.map((week,index) => {
                    return(
                        <option key={week} value={week}>{week}</option>
                    )
                })} 
            </select>
        )
    }

    function MonthSelect({monat}){
        return(
            <select name="" id="" className='selectMarketing' value={monat} onChange={(e) => {
                setMonat(e.target.value);
                setRangeStart(monthStarts[months.indexOf(e.currentTarget.value)]);
                filterEventsByMonth(e.target.value)
                if(e.target.value)setCaption("Unser Programm im " + e.target.value + " im " + organizerName + ".\nKomm vorbei und tanz mit uns!\n\nAlle Events und dazugehörige Infos gibt es auf ELGIO")
                else setCaption("")
                }}>
                <option value={null}>Wähle den Monat</option>
                {months.map((monat,index) => {
                    return(
                    <option key={monat} value={monat}>{monat}</option>
                    )
                })}
            </select>
        )
    }
 

    const [handlebarsData,setHandlebarsData] = useState({})
    
    useEffect(() => {
        if(!postType) return;
        getDocs(
            collection(firestore,"social_media_post_templates",postType,"templates"))
            .then((doc) => {
                setTemplates(doc.docs)
                setSelectedTemplate('standard');
            });
    }, [postType]);

    useEffect(() => {
        if(postType && selectedTemplate){
            getDoc(doc(collection(firestore,"social_media_post_templates"),postType,"templates",selectedTemplate))
            .then((e) => {
                setSelectedTemplateHtml(e.data()?.handlebars.instagram_post)
            })
        }
    }, [postType, selectedTemplate]);

    useEffect(() => { (async () => {
        if(!postType) return;

        //Generate the data
        let data = {
            backgroundColor: bgColor,
            backgroundImage: bgImageRawAdjusted,
            fontFamily: selectedFont,
            textColor: selectedTextColor,
            organizer: {
                name: organizerName,
                isVenue,
                address1: organizerAddress?.split(",",1)[0],
                address2: organizerAddress?.split(",",1)[1],
                city: organizerCity,
            },
        };

        const generateEventData = async (event) => {
            const eventDate = DateTime.fromJSDate(event.data().start.toDate(), { zone: 'Europe/Berlin' });
            const genres = await Promise.all(event.data().genre?.map(e => getDoc(doc(collection(firestore, 'genres'), e)).then(e => e.data()?.de))??[]);
            const lineup = await Promise.all(event.data().lineup?.map(async e => {
                const artistDoc = await getDoc(doc(collection(firestore, 'artists'), e));
                return {
                    name: artistDoc.data().name,
                    image: artistDoc.data().images?.length > 0 ? await getDownloadURL(ref(storage, artistDoc.data().images[0])) : null,
                };
            })??[]);

            return {
                name: event.data().name,
                image: event.data().images?.length > 0 ? await getDownloadURL(ref(storage, event.data().images[0])) : null,
                time: `${String(eventDate.hour).padStart(2, '0')}:${String(eventDate.minute).padStart(2, '0')}`,
                genres,
                lineup,
                specials: event.data().specials,
            };
        };

        if(postType === 'event') {
            if(!selectedEvent) return;

            const eventDoc = await getDoc(doc(collection(firestore, 'events'), selectedEvent));

            data = {
                ...data,
                event: await generateEventData(eventDoc),
            };

        } else if(postType === 'overview_week') {
            if(!rangeStart) return;

            const rangeEnd = new Date(rangeStart.getTime() + 7 * 86400000);

            const snap = await getDocs(
                query(
                    collection(firestore, 'events'),
                    where('start', '>', rangeStart),
                    where('start', '<', rangeEnd),
                    where('organizer', 'array-contains', organizerId),
                    where('visibility', 'in', ['private', 'public']),
                ),
            );
            const events = snap.docs.filter(e => e.data()?.visibility === 'public' || e.data().pending_review);
            
            const rangeStartDate = DateTime.fromJSDate(rangeStart, { zone: 'Europe/Berlin' });
            const rangeEndDate = DateTime.fromJSDate(rangeEnd, { zone: 'Europe/Berlin' });
            const days = [];
            const weekdays = ['SON', 'MON', 'DIE', 'MIT', 'DON', 'FRE', 'SAM'];
            for(const event of events) {
                const eventDate = DateTime.fromJSDate(event.data().start.toDate(), { zone: 'Europe/Berlin' });
                const weekday = weekdays[eventDate.weekday % 7];
                const date = `${eventDate.day}.${eventDate.month}.`;
                if(days[days.length - 1]?.date === date) {
                    days[days.length - 1] = { weekday, date, events: [ ...(days[days.length - 1].events??[]), await generateEventData(event) ] };
                } else {
                    days.push({ weekday, date, events: [ await generateEventData(event) ] });
                }
            }

            data = {
                ...data,
                days,
                week: `${rangeStartDate.day}.${rangeStartDate.month}. - ${rangeEndDate.day}.${rangeEndDate.month}.`,
            };
        } else if(postType === 'overview_month') {
            if(!rangeStart) return;

            const rangeStartDate = DateTime.fromJSDate(rangeStart, { zone: 'Europe/Berlin' });
            const rangeEnd = new Date(rangeStartDate.year + (rangeStartDate.month === 12 ? 1 : 0), rangeStartDate.month % 12);

            const snap = await getDocs(
                query(
                    collection(firestore, 'events'),
                    where('start', '>', rangeStart),
                    where('start', '<', rangeEnd),
                    where('organizer', 'array-contains', organizerId),
                    where('visibility', 'in', ['private', 'public']),
                ),
            );
            const events = snap.docs.filter(e => e.data()?.visibility === 'public' || e.data()?.pending_review);
            
            const days = [];
            const weekdays = ['SON', 'MON', 'DIE', 'MIT', 'DON', 'FRE', 'SAM'];
            for(const event of events) {
                const eventDate = DateTime.fromJSDate(event.data().start.toDate(), { zone: 'Europe/Berlin' });
                const weekday = weekdays[eventDate.weekday % 7];
                const date = `${eventDate.day}.${eventDate.month}.`;
                if(days[days.length - 1]?.date === date) {
                    days[days.length - 1] = {weekday, date, events: [ ...(days[days.length - 1].events??[]), await generateEventData(event) ] };
                } else {
                    days.push({ weekday, date, events: [ await generateEventData(event) ] });
                }
            }
            
            const months = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];
            data = {
                ...data,
                days,
                month: months[rangeStartDate.month - 1],
            };
        }

        setHandlebarsData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    })() }, [postType, selectedEvent, rangeStart, bgColor, bgImageRawAdjusted, selectedFont, selectedTextColor]);
    
    useEffect(() => {
        if(imgSrc.length !== 0){
            document.getElementById("weekPostImg").src=imgSrc
            document.getElementById("weekPostImg").style.visibility="visible"
        } 
    },[imgSrc])
    useEffect(() => {
        if(imgSrc2.length !== 0){
            document.getElementById("monthPostImg").src=imgSrc2
            document.getElementById("monthPostImg").style.visibility="visible"
        } 
    },[imgSrc2])

    function erstelleWochenArray() {
        var wochenArray = [];
        var wochenStartArray = [];
        var heute = new Date();
      
        // Ermittle den Starttag der aktuellen Woche (Montag)
        var startDerWoche = new Date(heute.getFullYear(), heute.getMonth(), heute.getDate() - heute.getDay() + (heute.getDay() === 0 ? -6 : 1));
      
        // Aktuelles Jahr und Monat ermitteln
        var aktuellerMonat = startDerWoche.getMonth();
      
        // Schleife über die verbleibenden Wochen des aktuellen Jahres und dem nächsten Jahr
        for (var i = 0; i < 52; i++) {
          var startDatum = startDerWoche.getDate().toString().padStart(2, '0') + '.' + (aktuellerMonat + 1).toString().padStart(2, '0');
          var endDatum = new Date(startDerWoche.getFullYear(), startDerWoche.getMonth(), startDerWoche.getDate() + 6);
          endDatum = endDatum.getDate().toString().padStart(2, '0') + '.' + (endDatum.getMonth() + 1).toString().padStart(2, '0');
      
          var wochenString = startDatum + '-' + endDatum;
          wochenArray.push(wochenString);
          wochenStartArray.push(new Date(startDerWoche.getTime()));
      
          // Nächste Woche
          startDerWoche.setDate(startDerWoche.getDate() + 7);
      
          // Prüfe, ob wir in einen neuen Monat oder ins nächste Jahr gehen müssen
          if (startDerWoche.getMonth() > aktuellerMonat || (startDerWoche.getMonth() === 0 && aktuellerMonat === 11)) {
            aktuellerMonat = startDerWoche.getMonth();
      
            // Überprüfe, ob das aktuelle Jahr zu Ende ist
            if (aktuellerMonat === 0 && startDerWoche.getMonth() === 11) {
              break; // Breche die Schleife ab, wenn das Jahr zu Ende ist
            }
          }
        }
        setWeeks(wochenArray)
        setWeekStarts(wochenStartArray)
    }

    function verbleibendeMonate() {
        // Array mit allen Monaten des Jahres
        const alleMonate = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];
      
        // Aktueller Monat
        const heute = new Date();
        const aktuellerMonatIndex = heute.getMonth();
      
        // Verbleibende Monate des Jahres ab dem aktuellen Monat
        const verbleibendeMonate = alleMonate.slice(aktuellerMonatIndex);
      
        setMonths(verbleibendeMonate)

        setMonthStarts(Array(12 - aktuellerMonatIndex).fill().map((_, i) => new Date(
            heute.getFullYear(),
            aktuellerMonatIndex + i,
        )));
      }
      

    async function filterEventsByDateRange(dateRange)  {
        const [startDatum, endDatum] = dateRange.split('-');
        const year = new Date().getFullYear()

        // Zerlege den String in Tag und Monat
        const [startTag, startMonat] = startDatum.split('.');
        const [endTag, endMonat] = endDatum.split('.');

        const start = new Date(year,parseInt(startMonat, 10) - 1, parseInt(startTag,10));
        const end = new Date(year,parseInt(endMonat, 10) - 1, parseInt(endTag,10));
        
        const gefilterteEvents = events.filter(event => {
          const eventStartDatum = event.data().start.toDate()
          return eventStartDatum >= start && eventStartDatum <= end;
        });

        // Ignore that it says 'await has no effect' it somehow does 
        await setFilteredEvents(gefilterteEvents)
        for (let index = 0; index < gefilterteEvents.length; index++) {
            const id = gefilterteEvents[index].id
            setFilteredEventsIDs((prev) => [...prev,id])
        }
    }

    async function filterEventsByMonth(selectedMonthName) {
        const heute = new Date();
        const aktuellerMonat = heute.getMonth();
      
      
        const verbleibendeMonate = Array.from({ length: 12 }, (_, index) => (index + aktuellerMonat) % 12);
      
        const selectedMonthIndex = months.indexOf(selectedMonthName);
        if (selectedMonthIndex === -1) {
          console.error("Ungültiger Monatsname");
          return [];
        }
      
        const gefilterteEvents = events.filter(event => {
          const eventStartDatum = event.data().start.toDate();
          const eventMonat = eventStartDatum.getMonth();
      
          const monthIndexInArray = (verbleibendeMonate.indexOf(eventMonat) + verbleibendeMonate.length - aktuellerMonat) % 12;
      
          return monthIndexInArray === selectedMonthIndex && eventStartDatum.getFullYear() === heute.getFullYear();
        });
      
            await setFilteredEvents(gefilterteEvents)
      }


    const changeOnSelect = (e) => {
        setSelectedEvent(null)
        setDateRange(null)
        setImgSrc("")
        setImgSrc2("")
        setCaption("")
        setPostType(e)
    }

    useEffect(() => { getDoc(doc(collection(firestore, 'users'), user.uid)).then(doc => {
      setInstagramAccountUsername(doc.data().instagram_account_username);
      setFacebookPageId(doc.data().facebook_page_id);
      setInstagramAccountId(doc.data().instagram_account_id);
    }) }, [user]);

    //placeholder preview to fit ratio
    const resize = (el) => el.style.width = `${el.clientHeight * 0.8}px`;
    const placeholderRef = useCallback(node => {
        if(!node?.style) return;
        resize(node);
        window.addEventListener('resize', () => node && resize(node));
    }, []);

    const bgImageInputRef = useRef();

    useEffect(() => {
        getDoc(doc(collection(firestore, "organizers"), organizerId))
          .then((snapshot) => {
            setUserIcon(snapshot.data().icon);
          })
          .catch((error) => console.log(error));
      }, [organizerId]);

    return(
        <Modal
            isOpen={props.show}
            unselectable="true"
            autoFocus={false}
            className="Modal_Style"
            shouldFocusAfterRender={false}
            shouldCloseOnOverlayClick={true}
            onRequestClose={()=>{props.setShow(false);cleanStates()}}
            style={styleModal}>
                <div className="HeaderRowBenefits">
                    <div className="HeaderText">
                        <div className="benefitHeader">{props.dataEdit ? "Post bearbeiten" :"Neuen Post erstellen"}</div>
                        <div className="benefitSubheading">Wähle eine Postart aus und passe ihn deinen Wünschen an</div>
                    </div>
                    <button id="newEventButton" style={{marginLeft: 'auto'}} disabled={!valid} onClick={() => {if(valid){props.dataEdit ? updatePost() : submit()}else{alert("Du musst erst alle Felder ausfüllen und mindestens eine Plattform auswählen!")}}}>
                        {uploading ? "Wird erstellt..." : props.dataEdit ? "Aktualisieren" : "Erstellen"}
                    </button>
                    <div className="postCancelButton" onClick={() => {props.setShow(false);cleanStates()}}>Abbruch</div>
                </div>
                <div className="PostCreationAll">
                    <div className="PostCreationLeft">
                        <div className="TitleText">Vorschau</div>
                        <div className="PlaceholderInstaBox">
                            <div className="InstagramBoxTopBar">
                                <div className="InstagramTopContent">
                                    <div className="InstagramTopContentLeft">
                                        <FirebaseStorageImage reference={userIcon} className="InstagramTopContentIcon" alt="" />
                                        <div className="InstagramTopContentName">{instagramAccountUsername??organizerName}</div>
                                    </div>
                                    <div className="InstagramContentIcons">
                                        <img src={more} alt=""/>
                                    </div>
                                </div>
                            </div>
                            <div ref={placeholderRef} className="InstagramBoxGrafik">
                                {   
                                    selectedEvent || dateRange || monat
                                        ? <HandlebarsIframe data={handlebarsData} templateHTML={selectedTemplateHtml} onIframeRefChange={setIFrameRef} autoWidth></HandlebarsIframe>
                                        : <div className="placeholderText">Bitte wähle deinen Posttyp und ein Event oder einen Zeitraum, um eine erste Vorschau zu sehen</div>
                                }
                            </div>
                            <div className="InstagramBoxBottomBar">
                                <div className="InstagramBoxBottomContent">
                                    <div className="InstagramBoxBottomContentLeft">
                                        <div className="InstagramContentIcons">
                                            <img src={like} alt=""/>
                                        </div>
                                        <div className="InstagramContentIcons">
                                            <img src={comment} alt=""/>
                                        </div>
                                        <div className="InstagramContentIcons">
                                            <img src={share} alt=""/>
                                        </div>
                                    </div>
                                    <div className="InstagramContentIcons">
                                        <img src={save} alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="PostCreationRight">
                        <div className="TitleText">Angaben</div>
                        <div className="PostAngabenBoxMain">
                            <div className="PostAngabenBox">
                                <div className="Angabe">
                                    Posttyp:
                                    <select name="posttyp" value={postType} id="" className='selectMarketing' onChange={(e) => {changeOnSelect(e.currentTarget.value)}}>
                                            <option  id='0' value="event">Eventpost</option>
                                            <option  value="overview_week">Wochenübersicht</option>
                                            <option value="overview_month">Monatsübersicht</option>
                                    </select>
                                </div>
                                <div className="Angabe">
                                    {postType === 'event' ? "Event:" : postType === 'overview_week' ? "Woche:" : "Monat:"}
                                    {postType === 'event' ? <EventSelect selectedEvent={selectedEvent} />
                                        : postType === 'overview_week' ? <WeekSelect dateRange={dateRange} />
                                        : <MonthSelect monat={monat} />}
                                </div>
                            </div>
                            <div className="PostAngabenBox">
                                <div className="Angabe">
                                    Template:
                                    <select name="template" id="" className='selectMarketing' value={selectedTemplate} onChange={(e) => {setSelectedTemplate(e.currentTarget.value)}}>
                                        {templates.map((template,index) => {
                                            return(
                                                <option key={template.id} value={template.id} id={index.toString()}>{template.data()?.name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className="moreDesign" onClick={()=> setShown3(e=>!e)}>Weitere Design-Anpassungen {shown3 ? chevronDownIcon : chevronLeftIcon}</div>
                                {shown3 && <div className='DesignAdditionalBox'>
                                    <div className="Angabe">
                                        Hintergrundfarbe:
                                        <input type="color" value={bgColor} className = "colorBox" onChange={(e) => {setBgColor(e.target.value)}}/>    
                                    </div>
                                    <div className="Angabe">
                                        Hintergrundbild:
                                        <input
                                            ref={bgImageInputRef}
                                            type="file"
                                            className="imgInp"
                                            accept="image/png, image/jpeg, image/jpg, image/webp"
                                            onChange={(evt) => {
                                                if(evt.target.files?.length === 0) return;

                                                const file = evt.target.files[0];

                                                const reader = new FileReader();
                                                reader.onload = () => {
                                                    const img = new Image();
                                                    img.onload = () => {
                                                        const canvas = document.createElement('canvas');
                                                        const ctx = canvas.getContext('2d');

                                                        canvas.width = img.width;
                                                        canvas.height = img.height;
                                                        ctx.drawImage(img, 0, 0);

                                                        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

                                                        ctx.putImageData(imageData, 0, 0);

                                                        let brightnessSum = 0;
                                                        const data = imageData.data;
                                                        for (let i = 0; i < data.length; i += 4) {
                                                            const r = data[i];
                                                            const g = data[i + 1];
                                                            const b = data[i + 2];
                                                            // Using the formula (0.299*R + 0.587*G + 0.114*B) to calculate brightness
                                                            brightnessSum += 0.299 * r + 0.587 * g + 0.114 * b;
                                                        }
                                                        setSelectedTextColor(brightnessSum / (data.length / 4) < 128 ? 'white' : 'black');
                                                        setBgImageRaw(canvas.toDataURL());
                                                        setBgImageName(file.name);
                                                    };
                                                    img.src = reader.result;
                                                };
                                                reader.readAsDataURL(file);
                                                
                                                evt.target.value = null;
                                            }}
                                        />
                                        {bgImageRawAdjusted ? 
                                        <div className='placeholderImgBox'>
                                            <div className='imgName'>{bgImageName}</div>
                                            <div className="deleteBgImgBox" onClick={() => {setSelectedTextColor('black'); setBgImageName(""); setBgImageRaw(""); setBgImageRawAdjusted("")}}>
                                                {deleteIcon}
                                            </div>
                                        </div>
                                        : bgImageRaw ? <div className="loadingImgBox" style={{width: "50%"}}>Wird geladen...</div>
                                        : <button className= "uploadBtnMarketing" onClick={(e) => {bgImageInputRef.current?.click()}}>
                                            Bild auswählen
                                        </button>}
                                    </div>
                                    <div className="Angabe">
                                        Font:
                                        <select name="font" id="fontSelector" className='selectMarketingMini' value={selectedFont} onChange={handleFontChange}>
                                            {fontOptions.map((font) => {
                                                return(
                                                    <option key={font} value={font}>{font}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>}
                            </div>
                            <div className="PostAngabenBox">
                                <div className="Angabe">
                                    Posting-Datum:
                                    <div className="row-flex flex-gap-1rem" style={{justifyContent:"space-between",width:"70%"}} >

                                    <DatePicker
                                        selected={postDate}
                                        onChange={setPostDate}
                                        dateFormat="dd.MM.yyyy"
                                        style={{ flex: 1 }}
                                        className="datePicker"
                                        minDate={new Date()}
                                        placeholderText="Datum"
                                        locale="de"
                                    />
                                    <DatePicker
                                        selected={postDate}
                                        onChange={setPostDate}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="Uhrzeit"
                                        dateFormat="HH:mm"
                                        className="datePicker"
                                        enableTabLoop={false}
                                        style={{ flex: 1}}
                                        placeholderText="Uhrzeit"
                                        locale="de"
                                    />
                                    </div>
                                </div>
                                <div className="Angabe">
                                    Wiederholen:
                                    <select name="" id="repeat" className='selectMarketing' value={repeat} onChange={(e) => setRepeat(e.target.value||null)}>
                                        <option value="">Nur einmalig</option>
                                        <option value="daily">Täglich</option>
                                        <option value="weekly">Wöchentlich</option>
                                        <option value="monthly">Monatlich</option>
                                    </select>
                                </div>
                            </div>
                            <div className="PostAngabenBox">
                                <div className="Angabe" style={{alignItems: "flex-start"}}>
                                    Caption:
                                    <textarea
                                        draggable="false"
                                        id="postCaption"
                                        value={caption}
                                        onChange={(e) => {setCaption(e.target.value)}}
                                        placeholder='z.B. Diesen Samstag gibt es die letzte Party des Jahres von @koelnisttechno im @reinekefuchscologne mit @kosmo_dj und vielen weiteren. Tickets findet ihr in der Bio'
                                    ></textarea>
                                </div>
                            </div>
                            <div className="PostAngabenBox">
                                <div className="Angabe" style={{alignItems: "flex-start"}}>
                                    Plattform:
                                    <div className="plattformBox">
                                        <div className="plattformBoxSmall">
                                            <div className="plattformBoxSmallTop">
                                                {instagramIcon}
                                                <div className="leftSideBox">
                                                    Instagram
                                                    <div className="typeBox">
                                                        <div className="previewPostTypesBox" onClick={()=> setShown(!shown)}>
                                                            <div className="typePost">Post + Story</div>
                                                            <div>
                                                                {shown ? chevronDownIcon : chevronLeftIcon}
                                                            </div>
                                                        </div>
                                                        {shown && <div className="postModificationBox">
                                                            <div className="specificPost">
                                                                Post
                                                                <label className="switchSmall">
                                                                    <input
                                                                    type="checkbox"
                                                                    className="check"
                                                                    checked={instagramPost}
                                                                    onChange={e => {setInstagramPost(e.target.checked)}}
                                                                    />
                                                                    <span className="sliderSmall round"></span>
                                                                </label>
                                                            </div>
                                                            <div className="specificPost">
                                                                Story
                                                                <label className="switchSmall">
                                                                    <input
                                                                    type="checkbox"
                                                                    className="check"
                                                                    checked={instagramStory}
                                                                    onChange={e => {setInstagramStory(e.target.checked)}}
                                                                    />
                                                                    <span className="sliderSmall round"></span>
                                                                </label>
                                                            </div>
                                                        </div>}
                                                    </div>
                                                </div>
                                                <label className="switch" style={{marginLeft: 'auto'}}>
                                                    <input
                                                    type="checkbox"
                                                    className="check"
                                                    checked={instagramPost && instagramStory}
                                                    onClick={e => {
                                                        if(e.target.checked){
                                                            setInstagramPost(true)
                                                            setInstagramStory(true)
                                                        }
                                                        else{
                                                            setInstagramPost(false)
                                                            setInstagramStory(false)                                                     
                                                        }
                                                    }}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="plattformBoxSmall">
                                            <div className="plattformBoxSmallTop">
                                                {facebookIcon}
                                                <div className="leftSideBox">
                                                    Facebook
                                                    <div className="typeBox">
                                                        <div className="previewPostTypesBox" onClick={()=> setShown2(e=>!e)}>
                                                            <div className="typePost">Post + Story</div>
                                                            <div>
                                                                {shown2 ? chevronDownIcon : chevronLeftIcon}
                                                            </div>
                                                        </div>
                                                        {shown2 && <div className="postModificationBox">
                                                            <div className="specificPost">
                                                                Post
                                                                <label className="switchSmall">
                                                                    <input
                                                                    type="checkbox"
                                                                    className="check"
                                                                    checked={facebookPost}
                                                                    onChange={e => {setFacebookPost(e.target.checked)}}
                                                                    />
                                                                    <span className="sliderSmall round"></span>
                                                                </label>
                                                            </div>
                                                            <div className="specificPost">
                                                                Story
                                                                <label className="switchSmall">
                                                                    <input
                                                                    type="checkbox"
                                                                    className="check"
                                                                    checked={facebookStory}
                                                                    onChange={e => {setFacebookStory(e.target.checked)}}
                                                                    />
                                                                    <span className="sliderSmall round"></span>
                                                                </label>
                                                            </div>
                                                        </div>}
                                                    </div>
                                                </div>
                                                <label className="switch" style={{marginLeft: 'auto'}}>
                                                    <input
                                                    type="checkbox"
                                                    className="check"
                                                    checked={facebookPost && facebookStory}
                                                    onClick={e => {
                                                        if(e.target.checked){
                                                            setFacebookPost(true)
                                                            setFacebookStory(true)
                                                        }
                                                        else{
                                                            setFacebookPost(false)
                                                            setFacebookStory(false)                                                     
                                                        }
                                                    }}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
        </Modal>
    )
}

export function PictureModal({pic, setPic}){
    
    return(
        <Modal
            isOpen={pic}
            unselectable="true"
            autoFocus={false}
            className="Modal_Style"
            shouldFocusAfterRender={false}
            shouldCloseOnOverlayClick={true}
            onRequestClose={()=>{setPic(null)}}
            style={{
                content: {
                    backgroundColor: "var(--bg3)",
                    width: "fit-content",
                    height: "fit-content",
                    padding: "25px 35px",
                    opacity: "100%",
                    borderRadius: "5px",
                    position: "relative",
                    border: "none",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                },
                overlay: {
                    zIndex: "1",
                    backgroundColor: "rgba(10, 10, 10, 0.8)",
                    opacity: "100%",
                }
            }}
        >
            <div style={{alignItems: "flex-end", display: "flex", flexDirection: "column", gap: "10px", color: "var(--textMain)"}}>
                <div className='row-flex align_center justify_space_between' style={{width: "100%"}}>
                    <div style={{fontSize: "var(--fontsize-big)"}}>Dein Post:</div>
                    <div style={{fontSize: "var(--fontsize-massive)", cursor: "pointer"}} onClick={()=>{setPic(null)}}>{cancelIcon}</div>
                </div>
                <FirebaseStorageImage reference={pic} resolution={700} className="enlargedPic"></FirebaseStorageImage>
            </div>
        </Modal>
    )

}