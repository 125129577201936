import React, { useEffect } from "react";
import "./success.css"
import 'animate.css/animate.css'
import bgpicture from "../../../graphics/success_downscaled.jpg";
import Modal from "react-modal";
import "../../../index.scss";
import Lottie from "lottie-react";
import ticketAnimation from "../../lotiie-animations/TicketAnimation.json"
import success1 from "../../lotiie-animations/Success1.json"
import success2 from "../../lotiie-animations/Success2.json"

export default function Success(props) {
    const el = Modal.setAppElement("#portal");


    useEffect(() => {
        if(!props.showSuccess) return
        setTimeout(() => {document.querySelector("#animationModal").style.display="none"},4000)
    } ,[props.showSuccess])


    return (
    
        <Modal
            isOpen={props.showSuccess}
            appElement={el}
            unselectable="true"
            autoFocus={false}
            className="modalContentSmall"
            overlayClassName="modalOverlayNormal"
            shouldFocusAfterRender={false}
            shouldCloseOnOverlayClick={true}
            onRequestClose={()=>{props.setShowSuccess(false)}}
            style={{
            content: {
                backgroundImage: `url(${bgpicture})`,
                backgroundSize: "cover",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
            }}}
        >
            <div id="animationModal" style={{width:"90vw",margin:"auto",top:"0px",bottom:"0px",position:"absolute"}} className="col-flex justify_center align_center">
                <Lottie autoplay={true}  animationData={ticketAnimation} loop={false} style={{width:"400px"}}></Lottie>
                <Lottie autoplay={true} animationData={success1} loop={false} style={{width:"100%",position:"absolute",left:"0%",bottom:"-20%"}} ></Lottie>
                <Lottie autoplay={true} animationData={success2} loop={false} style={{width:"100%",position:"absolute",left:"0%",bottom:"-20%"}} ></Lottie>
            </div>
            <div className="gradient col-flex align_center" style={{justifyContent:"end"}}>
                <div className="successHeader">Ticketing erfolgreich angelegt</div>
                <div className="subheading">Nachdem das Event live geht erhälst du von uns eine Email, in der du noch einmal die Auftragsbestätigung findest</div>
                <div className="WeiterButton" onClick={() => {props.setShowSuccess(false)}}>Weiter</div>
            </div>
        </Modal>
    )
}