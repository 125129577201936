// src/removeNaNs.js
function removeNaNs() {
    const observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === 'childList' || mutation.type === 'characterData') {
          removeNaNsFromNode(mutation.target);
        }
      });
    });
  
    const config = { childList: true, subtree: true, characterData: true };
  
    observer.observe(document.body, config);
  
    function removeNaNsFromNode(node) {
        if (node.nodeType === Node.TEXT_NODE) {
          const parentElement = node.parentElement;
          if (node.nodeValue.includes('NaN')) {
            if (parentElement && parentElement.classList.contains('priceForNaN')) {
              node.nodeValue = node.nodeValue.replace(/NaN/g, '0,00');
            } else {
              node.nodeValue = node.nodeValue.replace(/NaN/g, '0');
            }
          } else if (parentElement && parentElement.classList.contains('priceForNaN')) {
            // If the value is numeric and parent has class 'priceForNaN'
            if (!isNaN(parseFloat(node.nodeValue))) {
              if (parseFloat(node.nodeValue) === 0 && !node.nodeValue.includes(',')) {
                // If value is 0 and not already formatted
                node.nodeValue = '0,00';
              } else if (!node.nodeValue.includes('€')) {
                // If not already formatted with Euro symbol
                node.nodeValue = `${node.nodeValue}€`;
              }
            }
          }
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          // Iterate over child nodes recursively
          node.childNodes.forEach(removeNaNsFromNode);
        }
      }
      
  
    // Initial cleanup
    removeNaNsFromNode(document.body);
  }
  
  export default removeNaNs;
  