import { httpsCallable } from "firebase/functions";
import { functions } from "./firebase";

export function initFacebookSdk() {
    return new Promise(resolve => {
      window.fbAsyncInit = function () {
          window.FB.init({
              appId: '631150195513483',
              cookie: true,
              xfbml: true,
              version: 'v16.0'
          });
  
          resolve();
      };
  
      // load facebook sdk script
      (function (d, s, id) {
          var js, fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) { resolve(); return; }
          js = d.createElement(s); js.id = id;
          js.src = "https://connect.facebook.net/en_US/sdk.js";
          fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));    
    });
}

export function connectToFacebook(scope) {
    return new Promise(async (resolve, reject) => {
        await initFacebookSdk();
        window.FB.login((response) => {
            const accessToken = response.authResponse.accessToken;
            window.FB.api(
                `/me/accounts`,
                'get',
                { fields: 'name' },
                (response) => {
                    console.log(response)
                    if(response.data.length === 0)
                        return window.FB.logout((_) => alert('Du hast uns keinen Zugriff auf eine Facebook Page gegeben oder es sind keine Facebook Pages mit deinem Profil verbunden. Bitte wiederhole den Vorgang erneut.'));
                    if(response.data.length !== 1)
                        return window.FB.logout((_) => alert('Du hast uns Zugriff auf mehr als eine Facebook Page gegeben. Bitte gib uns bei Facebook nur Zugriff auf eine Page und wiederhole den Vorgang erneut'));
                    const connectFacebookPage = httpsCallable(functions, "organizers-connectFacebookPage");
                    connectFacebookPage({
                        pageId: response.data[0].id,
                        token: accessToken,
                    })
                    .then((res) => {
                        resolve({ facebookPageId: response.data[0].id, facebookPageName: response.data[0].name });
                    })
                    .catch((error) => {
                        alert('Es ist ein Fehler aufgetreten: ' + error.message);
                        reject();
                    });
                },
            );
          }, {scope: scope??'pages_show_list,pages_manage_posts,business_management'});
    });
}

export async function connectToInstagram(facebookPageId) {
    return new Promise(async (resolve, reject) => {
        if(!facebookPageId) {
            alert('Du hast noch keine Facebook Page verbunden. Bitte beachte, dass du nur ein Instagram-Konto verbinden kannst, das mit einer Facebook Page verbunden ist.');
        }
        const facebookData = await connectToFacebook('pages_show_list,pages_manage_posts,business_management,instagram_basic,instagram_content_publish');
        const connectInstagramAccount = httpsCallable(functions, "organizers-connectInstagramAccount");
        connectInstagramAccount()
        .then((res) => {
            if(!res.data.id) {
                alert('Verlinkung mit Instagram-Konto nicht möglich. Eventuell ist mit deinem Facebook-Konto kein Instagram-Konto verbunden');
                reject();
            }
            resolve({ instagramAccountId: res.data.id, instagramAccountUsername: res.data.username, ...facebookData });
        })
        .catch((error) => {
            alert('Es ist ein Fehler aufgetreten: ' + error.message);
            reject();
        });
    });
}