import { collection, doc, getDoc } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { firestore, functions } from "../../firebase";
import { UserContext } from "../../providers/UserProvider";
import { httpsCallable } from "firebase/functions";

export default function BankInput() {
    const { organizerId } = useContext(UserContext) 

    const [paymentsDoc, setPaymentsDoc] = useState(null);
    const [iban, setIban] = useState('');
    const [editBankAccount, setEditBankAccount] = useState(false);
    const [accountHolder, setAccountHolder] = useState('');
    const [loadingBankAccountEdit, setLoadingBankAccountEdit] = useState(false);

    useEffect(() => {
        getDoc(doc(collection(doc(collection(firestore, 'organizers'), organizerId), 'private'), 'payments'))
            .then(setPaymentsDoc)
    }, [organizerId]);

    useEffect(() => {
        if (paymentsDoc && editBankAccount === null) {
            setEditBankAccount(!paymentsDoc.data()?.bank_account?.id);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentsDoc]);
  
    const saveAccount = async () => {
        setLoadingBankAccountEdit(true);
        const updateBankAccount = httpsCallable(functions, "payments-updateBankAccount");
        await updateBankAccount({
            accountNumber: iban,
            accountHolder,
        });
        setEditBankAccount(false);
        setLoadingBankAccountEdit(false);
    };
  
    return(
        <>
            {editBankAccount ? (
                <div className="flexBoxBank">
                <div className="flexBoxBankMini">
                    <div className="t-white"> IBAN </div>
                    <input
                        className="TextInputModal"
                        value={iban}
                        onChange={(e) => setIban(e.target.value.toLocaleUpperCase())}
                        placeholder="DE00 0000 0000 0000 0000 00"
                    />
                </div>
                <div className="flexBoxBankMini">
                    <div className="t-white"> Kontohalter </div>
                    <input
                        className="TextInputModal"
                        value={accountHolder}
                        onChange={(e) => setAccountHolder(e.target.value)}
                        placeholder="Max Mustermann"
                    />
                </div>
                <div className="flexBoxButtons">
                    <div className="submitChangeBtn" disabled={loadingBankAccountEdit} onClick={saveAccount}>
                        {loadingBankAccountEdit ? 'Bitte warten...' : 'Konto speichern'}
                    </div>
                    {paymentsDoc?.data()?.bank_account?.id && (
                        <div className="cancelPlan" style={{ width: "120px", height: "17px" }} onClick={() => setEditBankAccount(false)}>
                            Abbrechen
                        </div>
                    )}
                </div>
                </div>
            ) : (
                <div className="bankRow">
                <div className="rowItemLeft medium t-white"> {paymentsDoc?.data()?.bank_account?.bank} </div>
                <div className="bankRowRight rowItemRight">
                    {paymentsDoc?.data()?.bank_account?.last4 && (
                    <div className="planDetails">
                        {paymentsDoc?.data()?.bank_account?.account_holder} · DE** **** **** **** **{paymentsDoc?.data()?.bank_account?.last4?.substring(0, 2)} {paymentsDoc?.data()?.bank_account?.last4?.substring(2, 4)}
                    </div>
                    )}
                    <div className="cancelPlan" onClick={() => setEditBankAccount(true)}>Konto ändern</div>
                </div>
                </div>
            )}
        </>
    );
}