import React from "react";
import './loader.css'

export function Loader() {
  return (
    <div className="loaderDiv">    
    <div style={{position: "relative"}}>
      <span className="loader" id="loader"></span>
    </div>
        <div className="text-color__main" >Loading...</div>
    </div>
  );
}

export function LoaderFetch() {
  return (
    <div className="loaderDivFetch" style={{position:"relative",marginTop:"20%",marginLeft:"43%"}}>    
      <div
        style={{
          position:"relative",
          zIndex: "1",
          color: "white",
        }}
      >
        <span className="loader" id="loader"></span>
      </div>
    </div>
  );
}

export function LoaderOffline(){
  return(
    <>    
      <div
        style={{
          position: "absolute",
          left: "48.5%",
          top: "40%",
          zIndex: "1",
          transform: "translate(-50%,-50%)",
          color: "white",
        }}
      >
        <span className="loaderOffline" id="loader"></span>
      </div>
      <div 
        style={{
          position:"absolute",
          left:"50%",
          top:"50%",
          transform: "translate(-50%,-50%)",
          color: "var(--textMain)",
          textAlign:"center"
        }}
      >
        Du bist offline! <br></br> Check, dass du eine stabile Internetverbindung hast, und lade dann die Seite neu.
      </div>
    </>
  );
}