import "../../../index.scss";

export function change_color(divid,divid2){
    const element = document.getElementById(divid);
    const element2 = document.getElementById(divid2);
    if(element)
        element.style.backgroundColor='var(--green)';
    if(element2)
        element2.style.backgroundColor='var(--green)';
}


export function change_Text_color(textId,textId2,index){
    document.getElementsByClassName(textId)[index].style.color="var(--green)";
    document.getElementsByClassName(textId2)[index].style.color="var(--green)";
}

export function rechange_Text_color(textId,textId2,index){
    document.getElementsByClassName(textId)[index].style.color="white";
    document.getElementsByClassName(textId2)[index].style.color="white";
}


export function change_color_icon_Nav(icon,icon2,index){
    let element = document.getElementsByClassName(icon)[index];
    let element2 = document.getElementsByClassName(icon2)[index];
    element.style.backgroundColor="var(--green)"
    element2.style.backgroundColor="var(--green)"    
}

export function rechange_color_icon_Nav(icon,icon2,index){
    let element = document.getElementsByClassName(icon)[index];
    let element2 = document.getElementsByClassName(icon2)[index];
    element.style.backgroundColor="var(--bg5)";
    element2.style.backgroundColor="var(--bg5)";
}

export function rechange_color(divid,divid2){
    const element = document.getElementById(divid);
    const element2 = document.getElementById(divid2);
    if(element)
        element.style.backgroundColor='var(--bg5)';
    if(element2)
        element2.style.backgroundColor='var(--bg5)';
}

export function delete_logo(){
    document.getElementById('logoImg').style.display="none";
    document.getElementsByClassName('LogoUploadBtn')[0].style.display="flex";
    // document.getElementsByClassName('LogoUploadBtn')[0].style.top="-50%";

}

export function delete_image(){
    document.getElementById("output_image").style.visibility='hidden';
    document.getElementById('UploadBtnErstellen').style.visibility='visible';
    rechange_color('titelbild-icon','titelbild-icon-nav');
    /*document.getElementsByClassName('UploadBtn')[0].style.visibility='visible';
    document.getElementById('BildBorderAccount').style.zIndex=0;
    document.getElementById('titelbild-icon').style.backgroundColor='var(--lightgrey)';
    document.getElementById('titelbild-icon-nav').style.backgroundColor='var(--lightgrey)';
    document.getElementsByClassName("output_image_style")[1].style.visibility="visible"; */
    
}
export function delete_firebase_Image(){
    let state = false;
    document.getElementsByClassName('output_image_style')[1].style.visibility="hidden";
    state = true;
    return state;
}

export function delete_image_event(){
    document.getElementById("output_image").style.visibility='hidden';
    document.getElementById('UploadBtn').style.visibility='visible';
    document.getElementById('BildBorderAccount').style.zIndex=0;
    rechange_color('TitelbildIconNav','TitelbildIconInput2');
    
}

export var image;
export function preview_image(event,i) {
    var reader = new FileReader();
    reader.onload = function(){
        var output = document.getElementById('output_image');
        // var outputVorschau = document.getElementById('output_image_Vorschau');
        output.src = reader.result;
        // outputVorschau.src = reader.result;

    }
    reader.readAsDataURL(event.target.files[0]);
    // document.getElementsByClassName("output_image_style")[1].style.visibility="hidden"; 
    document.getElementById("output_image").style.visibility='visible';
    document.getElementsByClassName('UploadBtn')[0].style.visibility='hidden';
    // document.getElementById('UploadBtn').style.visibility='hidden';
    document.getElementById('BildBorderAccount').style.zIndex=1;
    change_color('titelbild-icon','titelbild-icon-nav');
}

export function preview_image_erstellen(event,i) {
    var reader = new FileReader();
    reader.onload = function(){
        var output = document.getElementById('output_image');
        // var outputVorschau = document.getElementById('output_image_Vorschau');
        output.src = reader.result;
        // outputVorschau.src = reader.result;
    }
    
    reader.readAsDataURL(event.target.files[0]);
    document.getElementById("output_image").style.visibility='visible';
    // document.getElementsByClassName("output_image_style")[0].style.visibility="hidden"; 
    // document.getElementById("output_image").style.visibility='visible';
    // document.getElementsByClassName('UploadBtnErstellen')[0].style.visibility='hidden';
    document.getElementById('UploadBtnErstellen').style.visibility='hidden';
    // document.getElementById('BildBorderAccount').style.zIndex=1;
    change_color('titelbild-icon','titelbild-icon-nav');
}
export function preview_logo(event,i) {
    var reader = new FileReader();
    reader.onload = function(){
        var output = document.getElementById('logoImg');
        output.src = reader.result;

    }
    reader.readAsDataURL(event.target.files[0]);
    document.getElementById('logoImg').style.display="block";
    document.getElementsByClassName('LogoUploadBtn')[0].style.display="none";
    change_color('logo-icon','logo-icon-nav')
    
}
export function preview_image_event(event,i) {
    var reader = new FileReader();
    reader.onload = function(){
        var output = document.getElementById('output_image');
        output.src = reader.result;

    }
    reader.readAsDataURL(event.target.files[0]);
    document.getElementById("output_image").style.visibility='visible';
    document.getElementById('UploadBtn').style.visibility='hidden';
    document.getElementById('BildBorderAccount').style.zIndex=1;
    change_color('TitelbildIconNav','TitelbildIconInput2');
}


// eslint-disable-next-line import/no-anonymous-default-export
export default {change_color,rechange_color,preview_image,delete_image,preview_logo,delete_logo,delete_image_event};
